import React, { Component } from 'react';
import ExpertBookingPreview from '../components/ExpertBookingPreview';

export default class ExpertBookingPreviewPage extends Component {
  render() {
    return (
      <ExpertBookingPreview/>
    );
  }
}
