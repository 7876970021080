import axios from 'axios'
import jwtDecode from 'jwt-decode';
import { Config } from '../../../core/_request';


export const SubscriptionPacks = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/pack`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const updatePhoneNumber = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/Auth/UpdateUserMobileNo_web`, body, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const AddAppointmentUserService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/appointment/Add`, body, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const AddUserPackageService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/user_package/add`, body, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const UserDetailsService = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/Auth/GetUserbyId`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const getExpertbyId_WebService = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/Auth/getExpertbyId_Web?UserId=${id}`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const UseCouponCodeService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/transaction/UseCouponCode`, body, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const AddTransactionService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/transaction/add`, body, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}
export const UpdateTransactionService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/transaction/UpdateTransaction`, body, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const SubscriptionComparison = () => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/comparison`, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const CancelSubscription = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/cancel`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const CancelSubscriptionReason = () => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/cancel-reasons`, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const subscription_subscribe_odoo = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/subscribe`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const GetMySubscription = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/my-pack`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const SubscriptionFaq = () => {
    return axios.get(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/subscription/faqs`, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const DeductFromSubscription = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/deduct/usage`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
}

export const SubscriptionPaymentFeedback = (body) => {
    return axios.post(`${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/feedback`, body, Config()).then(resp => resp.data)
        .then(resp => {
            // if (resp.data)
            //     resp.data = jwtDecode(resp.data);
            return resp;
        });
} 