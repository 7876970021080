import React from 'react'
import { Form } from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <div>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container'>
                    <div className="py-5">
                        <h6 className="text-white font-w600 fs-4">Privacy Policy</h6>
                    </div>
                    <div className='bg-white px-5 privacy_Policy' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <h6 className='fs-5 pt-5'>TERMS OF USE</h6>
                        {/* <p className='py-3'>

                            1. General

                            1. We at United For Her Private Limited, and its technical processors (“Company”, “We”, “Our” or “Us”) are committed to protecting your privacy and grant importance to the confidentiality of Your data over internet. This privacy policy (“Privacy Policy”) read with the Terms of Use available at our website www.unitedwecare.com (“Website”) and/or the mobile application – “UNITEDWECARE” (“Application”) applies to all services offered to persons using or visiting (“Users”, “You” or “Your”) the Website and Application.

                            2. By visiting or using our Website, Application, domain name and any other linked pages, features, content, or any other services we offer from time to time by in connection therewith, or by using the services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent to our collection, use and disclosure of Personal Information (as defined below) in accordance with this Privacy Policy. If you are not agreeable to this, please do not use this Website, Application or services provided by Us.

                            3. We collect, use, share, disclose and protect Your personal data provided while using the services available on the Website/Application. The data collected is used only to enhance the operation of the Website/Application, allowing You to use all the listed features and allowing Us to respond to Your requests/queries in efficient manner. The Personal Information is used strictly in line with Our business purposes and to provide You with useful features.

                            4. In this Privacy Policy, “Personal Information” shall mean information through which a User can be identified, directly or indirectly, in particular by reference to User, their name, email address, phone number, username, password, Internet Protocol (“IP”) address (“Personal Information”). Personal Information does not include anonymized information that has been appropriately processed to make it non-identifiable in order to irreversibly prevent its identification.

                            5. We respect the privacy of Our Users and are committed to protect it in all respects. The information about the User is collected by Us as (i) information supplied by Users; (ii) information automatically tracked during User’s navigation on the Website/Application; and/ or (iii) communicated directly to Us via e-mail or telephone or another channel.

                            6. User acknowledges that this Privacy Policy, together with terms and conditions, forms Our agreement with the User in relation to his use of the Website/App. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in our Terms of Use.

                            7. For any service that the User may use, We only collect the data that is strictly necessary (i) for the delivery of the services, (ii) for the performance of a contract for which User is a party, (iii) for which User has given consent for such processing of data, or (iv) for compliance with a legal obligation to which We are subject.

                            8. By using the Website/Application or by otherwise giving us Your Personal Information, You will be deemed to have read, understood and agreed to the practices and policies outlined in this Privacy Policy and agree to be bound by the Privacy Policy. You hereby consent to Our collection, use and sharing, disclosure of Your Personal Information as described in this Privacy Policy. We reserve the right to change, modify, add or delete portions of the terms of this Privacy Policy, at Our sole discretion, at any time.
                            2. Information Collected

                            1. In the course of providing services to You through the Website/Application, We may collect the following information:
                            3. Personal Information such as your name, phone number, username, password, e-mail address and phone number when you register at Website/Application.
                            4. With your permission, we may automatically collect some information about Your hardware devices when you access Website/Application. For example, when You utilize our applications, we may collect your IP address and the type of mobile device You use and Your geographic location. We also may collect information about Your activity on Website/Application, such as information about Your account usage, as well as information about how You interact with the Application (collectively, “User Data”). We may combine this automatically gathered information with other information, including Personal Information we have collected about You.
                            5. If you permit cookies on your browser, we may receive, and store certain types of information known as “cookies” when you access the Website.
                            6. Communications between You and the Company.

                            1. The information collected from You by the Company may constitute ‘personal information’ or ‘sensitive personal data or information’ as defined under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPI Rules”). The SPI Rules define “Sensitive Personal Data or Information” of a person to mean personal information about that person relating to:
                            7. passwords;
                            8. financial information such as bank accounts, credit and debit card details or other payment instrument details;
                            9. physical, physiological and mental health condition;
                            10. sexual orientation;
                            11. medical records and history;
                            12. biometric information;
                            13. information received by body corporate under lawful contract or otherwise;
                            14. visitor details as provided at the time of registration or thereafter; and
                            15. call data records.

                            1. Company will be free to use, collect and disclose information that is freely available in the public domain without Your consent.

                            2. In order to provide services through the Website/Application, We may require the User to provide Us with certain information that personally identifies himself. Personal Information includes the following categories of information:
                            16. Contact data (such as email address, phone number and call recording); and
                            17. Demographic data (such as time zone, postal address and location details); and
                            18. If the User communicates with Us by, for example, e-mail or letter, any information provided in such communication may be collected by Us.
                            19. Financial data (such as account details, e-wallet details, bank account or credit or debit card details or other payment instrument details etc.) that User may have provided Us while using the services provided by Website/Application.

                            1. All such information is stored or used for processing services and under no circumstance are any of these details made available in the public domain. However, We may use this information to share with You additional and/ or upcoming information regarding the services of Website/Application.

                            2. The Website/Application may transmit User Data to the Godaddy servers, to ensure secure storage of. Thereby the User is also subject to the terms of Godaddy server’s policy. The Personal Information is immediately deleted once User deletes the Application or deletes his account exclusively made on the Website, except to the extent it is necessary to store the same under applicable laws. Further, We have implemented commercially reasonable physical, managerial, operational and technical security measures to protect the loss, misuse and alteration and to preserve the security of the Personally Information in Our care.

                            3. Our Application /Website may contain links to third party websites or applications. The User agrees and understands that privacy policies of these websites are not under Our control and the User understands that once he leaves Our servers, use of any information provided by the User shall be governed by the privacy policy of the operator of the site used by them.

                            4. Prior to any direct marketing or commercial operation (for example for sending the newsletters or commercial offers), We will first obtain Users consent, either when creating account on the Website/ Application or later by indicating Your choice in account settings. User will always have the right to withdraw his consent at any time, directly by clicking on the unsubscribe link available on each email received, by modifying his account settings, or by contacting Us at the following email address contactus@unitedwecare.com.

                            5. In accordance with the applicable laws, User has the right to access, to rectify and to object data concerning them. User can access and change the their profile picture, display name and user profile password provided when account is created through the account page or by contacting Us.

                            6. We may disclose any information We deem necessary, in Our sole discretion, to comply with any applicable law, regulation, legal process or governmental request. Such transfer or disclosure shall be for lawful purposes only. Further, such transfer or disclosure of Your sensitive personal information to other third parties shall only be done once it is ensured that the third party receiving such information has implemented the same level of security practices as we have.

                            7. We take Your right to privacy very seriously and other than as specifically stated in this Privacy Policy, will only disclose Your Personal Information in the event it is required to do so by applicable law, rule, regulation, law enforcement agency, governmental official, legal authority or similar requirements or when Company, in its sole discretion, deems it necessary in order to protect its rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or apply the Terms of Use.
                            20. Security

                            1. The security of User Data and Personal Information is important to Us. We follow generally accepted industry standards to protect the User Data and Personal Information submitted to Us, both during transmission and once We receive it. All information gathered on Our Website/Application is securely stored on the Godaddy server. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. Such information may also be converted to physical form from time to time, if deemed necessary. We take all necessary precautions to protect Your Personal Information both online and off-line, and implement reasonable security practices and measures including certain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of Our business.

                            2. Although We make best possible efforts to store Personal Information in a secure operating environment that is not open to the public, User must understand that there is no such thing as complete security, and We do not guarantee that there will be no unintended disclosures of Personal Information. If We become aware that Users Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, We will use reasonable efforts to notify such User of the nature and extent of such disclosure as soon as reasonably possible and as permitted by law.

                            3. Depending on the processing of Personal Information, the data may be subject to a different storage period.

                            4. We do not sell Users Personal Information to any advertisers or any third party. We use Users Personal Data to display relevant advertisements, based on his preferences. We make reports related to the advertisement campaigns, in particular statistics, without any reference or information related to the personal information of visitors or users of the Website/App. User can also block advertising cookies in the dedicated “cookies” page.
                            Change to Privacy Policy

                            1. Company may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way Company treats User’s personally identifiable information, or in the Privacy Policy itself, Company will display a notice on the Website/App or send Users an email, as provided for above, so that You may review the changed terms prior to continuing to use the services. If You object to any of the changes to Our terms, and You no longer wish to avail the services, You may contact contactus@unitedwecare.com to deactivate Your account. Unless stated otherwise, Company’s current Privacy Policy applies to all information that the Company has about You and Your account.

                            2. If a User uses the Website/Application, after a notice of changes published on the Website, such User hereby provides his/her/its consent to the changed terms.
                            This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents/ records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature and is a legally binding document between the User and Us.
                        </p> */}
                        <h6 className='font-inter'>1. Please Read Carefully</h6>
                        <p className='font-inter'>This Online Privacy Policy describes the information that UNITED WE CARE INC (“we”
                            or “Company”) collects about you through our website(s), mobile application, and any
                            other services we provide (collectively, the “Services”), how we use and share that
                            information, and the privacy choices we offer. This policy applies to information we
                            collect when you access or use our website(s) and mobile application (collectively, the
                            “Platform”), when you use our Services, or when you otherwise interact with us.
                        </p>
                        <h6 className='font-inter'>2. Changes to this Online Privacy Policy</h6>
                        <p className='font-inter'>
                            We may change this Online Privacy Policy from time to time. If we make changes, we
                            will notify you by posting the updated policy on our Platform and revising the “Last
                            Updated” date above. We encourage you to review the Online Privacy Policy whenever
                            you use our Services to stay informed about our information practices and about ways
                            you can help protect your privacy.
                        </p>
                        <h6 className='font-inter'>3. Confidentiality of Health Information</h6>
                        <p className='font-inter'>
                            Health information that the Company receives and/or creates about you, personally,
                            relating to your past, present, or future health, treatment, or payment for healthcare
                            services, maybe “protected health information” under the Health Insurance Portability
                            and Accountability Act of 1996 (“HIPAA”) and the Health Information Technology for
                            Economic and Clinical Health Act (“HITECH Act”). Your health information may also be
                            protected by state privacy laws and regulations.
                            The Company understands that health information about you and your health is
                            personal. We support your privacy and ensure that the transmittal and use of your
                            information comply with all laws, except to the extent that you have authorized
                            Company to transmit information to you by other means. In this regard, where
                            applicable, we comply with HIPAA, HITECH, and other relevant state laws and
                            regulations by entering into Business Associate Agreements with the treatment
                            providers for which we provide services to ensure that your protected health information
                            is appropriately safeguarded.
                        </p>
                        <p className='font-inter'>Set forth below is the Company’s Notice of Privacy Practices.</p>
                        <h6 className='font-inter'>4. Use of Services</h6>
                        <p>
                            Your access to and use of our Services is subject to certain terms and conditions, which
                            are set forth in our <a target='_blank'>Terms of Use</a> and <a target='_blank'>Telehealth Authorization and Consent.</a>
                        </p>

                        <h6 className='font-inter'>5. Collection of Information</h6>
                        <h6>5.1. Information You Provide</h6>
                        <p>We collect the information you provide, such as when you email us, sign up through our
                            Platform, or submit information through our Platform. We may collect but are not limited
                            to collecting, the following information: your name, gender, email address, mailing
                            address, phone number, date of birth, payment, and bank information provided.
                        </p>
                        <h6>5.2. Children</h6>
                        <p>
                            The Company does not knowingly collect or maintain personally identifiable information
                            from persons under 18 years of age without verifiable parental consent. If you are
                            under 18 years of age, then please do not use the Services without parental consent. If
                            Company learns that personally identifiable information of persons less than 18 years of
                            age has been collected without verifiable parental consent, then Company will take the
                            appropriate steps to delete this information. To make such a request, please contact us
                            at <a target='_blank' href='helpUS@unitedwecare.com'>helpUS@unitedwecare.com.</a>
                        </p>
                        <h6>5.3. Information We Collect from Your Use of the Services</h6>
                        <p>We collect information about you when you use our Platform, including, but not limited
                            to the following:
                        </p>
                        <ul>
                            <li><strong>Account Information.</strong> When you register with us using the Platform to create an account
                                and become a registered user, you will need to provide us with certain personally identifiable
                                information to complete the registration, including information that can be used to contact or
                                identify you and payment or other billing information in some cases.
                            </li>
                            <li><strong>Device Information.</strong> We may automatically collect certain information about the computer
                                or devices (including mobile devices) you use to access the Services. For example, we may
                                collect and analyze information such as (a) IP addresses, geolocation information (as
                                described in the next section below), unique device identifiers, and other information about
                                your mobile phone or another mobile device(s), browser types, browser language,
                                operating system, the state or country from which you accessed the Services; and (b)
                                information related to the ways in which you interact with the Services, such as: referring and
                                exit pages and URLs, platform type, the number of clicks, domain names, landing pages,
                                pages and content viewed and the order of those pages, the amount of time spent on
                                particular pages, the date and time you used the Services, the frequency of your use of the
                                Services, error logs, and other similar information. As described further below, we may use
                                third-party analytics providers and technologies, including cookies and similar tools, to assist
                                in collecting this information.
                            </li>
                            <li><strong>Location Information.</strong> We may collect different types of information about your location,
                                including general information (e.g., IP address, zip code) and more specific information (e.g.,
                                GPS-based functionality on mobile devices used to access the Services), and may use that
                                information to customize the Services with location-based information, advertising, and
                                features. For example, if your IP address indicates an origin in Los Angeles, California, the
                                Services may be customized with Los Angeles-specific information and advertisements. In
                                order to do this, your location information may be shared with our agents, vendors, or
                                advertisers. If you access the Services through a mobile device and you do not want your
                                device to provide us with location-tracking information, you can disable the GPS or other
                                location-tracking functions on your device, provided your device allows you to do this. See
                                your device manufacturer’s instructions for further details.
                            </li>
                            <li><strong>Cookies and Other Electronic Technologies.</strong>We may use the tools outlined below in order
                                to better understand users. As we adopt additional technologies, we may also gather
                                additional information through other methods.
                            </li>
                            <ul>
                                <li><strong>Cookies:</strong>“Cookies” are small computer files transferred to your computing device
                                    that contain information such as user ID, user preferences, lists of pages visited, and
                                    activities conducted while using the Services. We use Cookies to help us improve or
                                    tailor the Services by tracking your navigation habits, storing your authentication
                                    status so you do not have to re-enter your credentials each time you use the Services,
                                    customizing your experience with the Services, and for analytics and fraud
                                    prevention.
                                    <br />
                                    We may use a type of advertising commonly known as interest-based or online
                                    behavioral advertising. This means that some of our business partners use Cookies to
                                    display Company ads on other websites and services based on information about your
                                    use of the Services and on your interests (as inferred from your online activity). Other
                                    Cookies used by our business partners may collect information when you use the
                                    Services, such as the IP address, mobile device ID, operating system, browser, web
                                    page interactions, the geographic location of your internet service provider, and
                                    demographic information such as sex and age range. These Cookies help the
                                    Company learn more about our users’ demographics and internet behaviors.
                                    <br />
                                    For more information on cookies, visit <a target='_blank' href='http://www.allaboutcookies.org.'>http://www.allaboutcookies.org.</a>
                                </li>
                                <li><strong>Web Beacons:</strong>“Web Beacons” (a.k.a. clear GIFs or pixel tags) are tiny graphic
                                    image files imbedded in a web page or email that may be used to collect anonymous
                                    information about your use of our Services, the websites of selected advertisers, and
                                    the emails, special promotions or newsletters that we send you. The information
                                    collected by Web Beacons allows us to analyze how many people are using the
                                    Services, using the selected advertisers’ websites or opening our emails, and for what
                                    purpose and also allows us to enhance our interest-based advertising.
                                </li>
                                <li><strong>Platform Analytics:</strong>We may use third-party analytics services in connection with
                                    the Platform, including, for example, registering mouse clicks, mouse movements, scrolling activity, and text that you type into the Platform. These analytics services
                                    generally do not collect personal information unless you voluntarily provide it and
                                    generally do not track your browsing habits across sites that do not use their services.
                                    We use the information collected from these services to help make the Platform easier
                                    to use.
                                </li>
                                <li><strong>Mobile Device Identifiers:</strong>Mobile device identifiers are data stored on your mobile
                                    device that may track the mobile device and data and activities occurring on and
                                    through it, as well as the applications installed on it. Mobile device identifiers enable
                                    the collection of personal information (such as media access control, address, and
                                    location) and traffic data. Mobile device identifiers help the Company learn more
                                    about our users’ demographics and internet behaviors.
                                </li>
                            </ul>
                        </ul>
                        <h6>5.4. Information from Third Parties</h6>
                        <p>
                            We may obtain additional information about you from third parties such as marketers,
                            partners, researchers, and others. We may combine information that we collect from
                            you with information about you that we obtain from such third parties and information
                            derived from any other subscription, product, or service we provide.
                        </p>
                        <h6>5.5. Aggregate or De-identified Data</h6>
                        <p>
                            We may aggregate and/or de-identify information collected by the Services or via other
                            means so that the information is not intended to identify you. Our use and disclosure of
                            aggregated and/or de-identified information is not subject to any restrictions under this
                            Online Privacy Policy, and we may disclose it to others without limitation for any
                            purpose, in accordance with applicable laws and regulations.
                        </p>
                        <h6>6. Use of Information</h6>
                        <p>
                            We use the information that we collect for the following purposes:
                        </p>
                        <ul>
                            <li>For the purposes for which you provided the information.</li>
                            <li>To contact you when necessary or requested.</li>
                            <li>To personalize your experience with the Services by informing you of products,
                                programs, events, services, and promotions of the Company, our affiliates, our partners,
                                and/or third parties that we believe may be of interest to you (see the “Opt-In Policy”
                                below).</li>
                            <li>To fulfill your purchase from us, including, to process your payments, communicating
                                with you regarding your purchase, or provide you with related customer service.</li>
                            <li>To send mobile notifications (you may opt out of this service).</li>
                            <li>To provide, maintain, administer, improve, or expand the Services, perform business
                                analyses, or for other internal purposes to support, improve or enhance our business, the
                                Services, and other products and services we offer.</li>
                            <li>To customize and tailor your experience of the Services.</li>
                            <li>To send emails and other communications that display content that we think will interest
                                you and according to your preferences.</li>
                            <li>To send you news and information about our Services.</li>
                            <li>To track and analyze trends and usage in connection with our Services.</li>
                            <li>To better understand who uses the Services and how we can deliver a better user
                                experience.</li>
                            <li>To combine information received from third parties with the information that we have
                                from or about you and use the combined information for any of the purposes described in
                                this Online Privacy Policy.</li>
                            <li>To use statistical information that we collect in any way permitted by law, including from
                                third parties in connection with their commercial and marketing efforts.</li>
                            <li>To prevent, detect, and investigate security breaches, fraud, and other potentially illegal
                                or prohibited activities.</li>
                            <li>To enforce the legal terms that govern your use of the Services.</li>
                            <li>To protect our rights or property.</li>
                            <li>To administer and troubleshoot the Services.</li>
                            <li>For any other purpose disclosed to you in connection with our Services.</li>
                        </ul>
                        <p>We may use third-party service providers to process and store personal information in
                            the United States and other countries.</p>
                        <h6>7. Sharing of Information</h6>
                        <p>We may share personal information about you as follows:</p>
                        <ul>
                            <li>With third parties to provide, maintain, and improve our Services, including service
                                providers who access information about you to perform services on our behalf.</li>
                            <li>With our affiliates and partners so that they may use such information for the purposes
                                described in this Online Privacy Policy.</li>
                            <li>With our affiliates, partners, or other third parties to allow them to contact you regarding
                                products, programs, services, and promotions that we and/or they believe may be of interest
                                to you (See the “Opt-In Policy” below).</li>
                            <li>In connection with, or during the negotiation of, any merger, sale of Company stock or
                                assets, financing, acquisition, divestiture, or dissolution of all or a portion of our business
                                (but only under non-disclosure and confidentiality agreements and protections).</li>
                            <li>If we believe that disclosure is reasonably necessary to comply with any applicable law,
                                regulation, legal process, or governmental request; to enforce applicable user agreements or
                                policies; to protect the security or integrity of our Services; and to protect us, our users or the
                                public from harm or illegal activities.</li>
                            <li>With your consent.</li>
                        </ul>
                        <p>We may also share aggregated, non-personally identifiable information with third
                            parties.</p>

                        <h6>8. Opt-In Policy</h6>
                        <p>
                            When you supply us with personally identifiable information in connection with your use
                            of the Services, you may be asked to indicate whether you are interested in receiving
                            information from us about our product and service offerings and if you would like us to
                            share personally identifiable information about you with our affiliates, partners or other
                            third parties for their marketing purposes. If you do choose to opt-in, you will receive
                            such communications, and/or we will share your information in accordance with your
                            “opt-in” consent.
                            <br />
                            You may, of course, choose not to receive additional marketing information from us or
                            choose not to allow our sharing of your personally identifiable information as follows: at
                            any time, you can follow a link provided in our marketing-related email messages (but
                            excluding e-commerce confirmations and other administrative emails) to opt-out from
                            receiving such communications; or at any time, you can contact us in accordance with
                            the “Contact Us” section below to opt-out from receiving such communications.
                            <br />
                            If you decide to contact us to change your contact preferences to opt out of receiving
                            communications from us, please specify clearly which of the following choices you are
                            opting out of: (a) receiving marketing communications from us; (b) allowing us to share
                            personally identifiable information about you with our affiliates and partners for their
                            marketing purposes; and/or (c) allowing us to share personally identifiable information
                            about you with other third parties for their marketing purposes.
                            <br />
                            We will endeavor to implement your requested change as soon as reasonably
                            practicable after receiving your request. Please be aware that your requested change
                            will not be effective until we implement such a change. Please note that if you choose
                            not to allow our sharing of your personally identifiable information, we are not
                            responsible for removing your personally identifiable information from the databases of
                            third parties with which we have already shared your personally identifiable information
                            as of the date that we implement your request. If you wish to cease receiving marketing-
                            related e-mails from these third parties, please contact them directly or utilize any opt-
                            out mechanisms in their privacy policies or marketing-related e-mails.
                            <br />
                            Please note that if you do opt-out of receiving marketing-related messages from us, we
                            may still send you important administrative messages. You cannot opt-out of receiving
                            these administrative messages. We reserve the right, from time to time, to contact
                            former customers or users of the Services for administrative purposes or in order to
                            comply with applicable laws, rules, or regulations.
                        </p>
                        <h6>9. Social Media and Third Party Platforms</h6>
                        <p>Certain sections or functionalities on our Services may permit you to share information
                            on third-party social media sites or platforms such as Facebook, Instagram, LinkedIn,
                            Twitter, or other similar sites (collectively, “Social Media Sites”). The Company does not
                            own or control such Social Media Sites, and posting your information on Social Media
                            Sites is subject to the third party’s Online Privacy Policy and other legal terms, which
                            may not provide privacy protections with which you agree. The Company is not
                            responsible for any act or omission of any Social Media Platform, nor are we
                            responsible for the consequences of your choosing to share your information on Social
                            Media Sites.
                        </p>
                        <h6>10. Security</h6>
                        <p>We take reasonable measures, including administrative, technical, and physical
                            safeguards, to help protect personal information from loss, theft, misuse, unauthorized
                            access, disclosure, alteration, and destruction. Unfortunately, no data transmission over
                            the Internet can be guaranteed to be 100% secure. As a result, while we strive to
                            protect your personal information, Company cannot ensure or warrant the security of
                            any information you transmit to us or from our online products or services, and you do
                            so at your own risk.
                        </p>
                        <h6>11. Your Privacy Choices</h6>
                        <h6>11.1. How You Can Access and Update Your Information</h6>
                        <p>You may update or correct information about yourself at any time or by emailing us at
                            <a target='_blank' href='helpUS@unitedwecare.com'>helpUS@unitedwecare.com.</a></p>
                        <h6>11.2. Cookies</h6>
                        <p>
                            Most web browsers are set to accept cookies by default. If you prefer, you can usually
                            choose to set your browser to remove or reject cookies; however, our Services may not
                            function properly if you do so.
                        </p>
                        <h6>11.3. Options for Opting out of Cookies and Mobile Device Identifiers</h6>
                        <p>
                            If you are interested in more information about interest-based advertising and how you
                            can generally control cookies from being put on your computer to deliver tailored
                            advertising, you may visit the Network Advertising Initiative’s Consumer Opt-Out link,
                            the Digital Advertising Alliance’s Consumer Opt-Out link or TRUSTe’s Advertising
                            Choices Page to opt-out of receiving tailored advertising from companies that
                            participate in those programs.
                            <br />
                            Please note that even after opting out of interest-based advertising, you may still see
                            Company’s advertisements that are not interest-based (i.e., not targeted toward you).
                            Also, opting out does not mean that Company is no longer using its tracking tools—The
                            Company still may collect information about your use of the Services even after you
                            have opted out of interest-based advertisements and may still serve advertisements to
                            you via the Services based on the information it collects via the Services.
                        </p>
                        <h6>11.4. How Company Responds to Browser “Do Not Track” Signals</h6>
                        <p>
                            We are committed to providing you with meaningful choices about the information
                            collected on our Platform for third-party purposes, and that is why we provide above the
                            Network Advertising Initiative’s “Consumer Opt-Out” Link, Digital Advertising Alliance’s
                            Consumer Opt-Out Link, and TRUSTe’s Advertising Choices page. However, we do not
                            recognize or respond to browser-initiated Do Not Track signals, as the Internet industry
                            is currently still working on Do Not Track standards, implementations, and solutions. For
                            more information about DNT signals, visit <a target='_blank' href='http://allaboutdnt.com'>http://allaboutdnt.com.</a>
                        </p>
                        <h6>11.5. Links to Other Websites</h6>
                        <p>
                            Our Services may contain links to other websites and those websites may not follow the
                            same privacy practices as Company. We are not responsible for the privacy practices of
                            third-party websites. We encourage you to read the privacy policies of such third parties
                            to learn more about their privacy practices.
                        </p>
                        <h6>11.6. No Rights of Third Parties</h6>
                        <p>
                            This Online Privacy Policy does not create rights enforceable by third parties.
                        </p>
                        <h6>11.7. How to Contact Us</h6>
                        <p>
                            Please contact us with any questions or concerns regarding this Online Privacy Policy at <a target='_blank' href='helpUS@unitedwecare.com'>helpUS@unitedwecare.com.</a>
                        </p>
                        <h6>12. Notice of Privacy Practices</h6>
                        <p>
                            The Company is dedicated to maintaining the privacy of your protected health
                            information (“PHI”). PHI is information about you that may be used to identify you (such
                            as your name, social security number, or address), and that relates to (a) your past,
                            present, or future physical or mental health or condition, (b) the provision of healthcare
                            to you, or (c) your past, present, or future payment for the provision of healthcare. In
                            conducting its business, Company may receive and create records containing your PHI.
                            The Company is required by law to maintain the privacy of your PHI and to provide you
                            with notice of its legal duties and privacy practices with respect to your PHI.
                            <br />
                            The Company must abide by the terms of this Notice while it is in effect. This Notice is
                            in effect from the date noted above until Company replaces it. The Company reserves
                            the right to change the terms of this Notice at any time, as long as the changes are in
                            compliance with applicable law. If the Company changes the terms of this Notice, the
                            new terms will apply to all PHI that it maintains, including PHI that was created or
                            received before such changes were made. If the Company changes this Notice, it will
                            post the new Notice on its Platform and will make the new Notice available upon
                            request.
                        </p>
                        <ul><strong>Uses and Disclosures of PHI.</strong> The Company may use and disclose your PHI in
                            the following ways:
                            <li>
                                <ul>
                                    <li><strong>Treatment, Payment, and Healthcare Operations.</strong> The Company is
                                        permitted to use and disclose your PHI for purposes of (a) treatment, (b)
                                        payment, and (c) healthcare operations. For example:</li>
                                    <li><strong>Treatment.</strong>The Company may disclose your PHI to a physician in
                                        connection with the provision of treatment to you.</li>
                                    <li><strong>Payment.</strong>The Company may use and disclose your PHI to your
                                        health insurer or health plan in connection with the processing and
                                        payment of claims and other charges.</li>
                                    <li><strong>Healthcare Operations.</strong>The Company may use and disclose your
                                        PHI in connection with its healthcare operations, such as providing
                                        customer services and conducting quality review assessments. The
                                        Company may engage third parties to provide various services for
                                        the Company. If any such third party must have access to your PHI in
                                        order to perform its services, Company will require that third party to
                                        enter an agreement that binds the third party to the use and disclosure
                                        restrictions outlined in this Notice.</li>
                                </ul>
                            </li>
                            <li><strong>Authorization.</strong> The Company is permitted to use and disclose your PHI
                                upon your written authorization, to the extent such use or disclosure is
                                consistent with your authorization. You may revoke any such authorization at
                                any time.</li>
                            <li><strong>As Required by Law.</strong> The Company may use and disclose your PHI to the
                                extent required by law.
                            </li>
                            <li><strong>Special Circumstances.</strong> The following categories describe unique
                                circumstances in which Company may use or disclose your PHI:
                                <ul>
                                    <li><strong>Public Health Activities.</strong> Company may disclose your PHI to public
                                        health authorities or other governmental authorities for purposes
                                        including preventing and controlling disease, reporting child abuse or
                                        neglect, reporting domestic violence, and reporting to the Food and
                                        Drug Administration regarding the quality, safety, and effectiveness
                                        of a regulated product or activity. Company may, in certain
                                        circumstances disclose PHI to persons who have been exposed to a
                                        communicable disease or may otherwise be at risk of contracting or
                                        spreading a disease or condition.
                                    </li>
                                    <li><strong>Workers’ Compensation.</strong> The Company may disclose your PHI as
                                        authorized by, and to the extent necessary to comply with, workers’
                                        compensation programs and other similar programs relating to work-
                                        related illnesses or injuries.
                                    </li>
                                    <li><strong>Health Oversight Activities.</strong> The Company may disclose your PHI
                                        to a health oversight agency for authorized activities such as audits,
                                        investigations, inspections, licensing, and disciplinary actions relating
                                        to the healthcare system or government benefit programs.
                                    </li>
                                    <li><strong>Judicial and Administrative Proceedings.</strong> The Company may disclose
                                        your PHI, in certain circumstances, as permitted by applicable law, in
                                        response to an order from a court or administrative agency, or in
                                        response to a subpoena or discovery request.
                                    </li>
                                    <li><strong>Law Enforcement.</strong> The Company may, under certain
                                        circumstances, disclose your PHI to a law enforcement official, such
                                        as for purposes of identifying or locating a suspect, fugitive, material
                                        witness, or missing person.
                                    </li>
                                    <li><strong>Decedents.</strong> The Company may, under certain circumstances,
                                        disclose PHI to coroners, medical examiners, and funeral directors for
                                        purposes such as identification, determining the cause of death, and
                                        fulfilling duties relating to decedents.
                                    </li>
                                    <li><strong>Organ Procurement.</strong> The Company may, under certain
                                        circumstances, use or disclose PHI for the purposes of organ donation
                                        and transplantation.
                                    </li>
                                    <li><strong>Research.</strong> The Company may, under certain circumstances, use or
                                        disclose PHI that is necessary for research purposes.
                                    </li>
                                    <li><strong>Specialized Government Functions.</strong> The Company, may in certain
                                        situations, use and disclose PHI of persons who are, or were, in the
                                        Armed Forces for purposes such as ensuring proper execution of a
                                        military mission or determining entitlement to benefits. The
                                        Company may also disclose PHI to federal officials for intelligence
                                        and national security purposes.
                                    </li>
                                </ul>
                                <li>
                                    <strong>12.2 Your Rights Regarding Your PHI.</strong> You have the following rights
                                    regarding the PHI maintained by the Company :
                                </li>
                                <ul>
                                    <li><strong>Confidential Communication.</strong>You have the right to receive confidential
                                        communications of your PHI. You may request that the Company
                                        communicate with you through alternate means or at an alternate location, and
                                        Company will accommodate your reasonable requests. You must submit your
                                        request in writing to Company.
                                    </li>
                                    <li><strong>Restrictions.</strong>You have the right to request restrictions on certain uses and
                                        disclosures of PHI for treatment, payment, or healthcare operations. You also
                                        have the right to request that the Company limits its disclosures of PHI to
                                        only certain individuals involved in your care or the payment of your care.
                                        You must submit your request in writing to Company. The Company is not
                                        required to comply with your request. However, if the Company agrees to
                                        comply with your request, it will be bound by such agreement, except when
                                        otherwise required by law or in the event of an emergency.
                                    </li>
                                    <li><strong>Inspection and Copies.</strong>You have the right to inspect and copy your PHI.
                                        You must submit your request in writing to Company. The Company may
                                        impose a fee for the costs of copying, mailing, labor, and supplies associated
                                        with your request. The Company may deny your request to inspect and/or
                                        copy your PHI in certain limited circumstances. If that occurs, Company will
                                        inform you of the reason for the denial, and you may request a review of the
                                        denial.
                                    </li>
                                    <li><strong>Amendment.</strong>You have a right to request that Company amend your PHI if
                                        you believe it is incorrect or incomplete, and you may request an amendment
                                        for as long as the information is maintained by Company. You must submit
                                        your request in writing to the Company and provide a reason to support the
                                        requested amendment. The Company may, under certain circumstances,
                                        deny your request by sending you a written notice of denial. If Company
                                        denies your request, you will be permitted to submit a statement of
                                        disagreement for inclusion in your records.
                                    </li>
                                    <li><strong>Accounting of Disclosures.</strong>You have a right to receive an accounting of all
                                        disclosures the Company has made of your PHI. However, that right does not
                                        include disclosures made for treatment, payment, or healthcare operations,
                                        disclosures made to you about your treatment, disclosures made pursuant to
                                        authorization, and certain other disclosures. You must submit your request in
                                        writing to the Company and you must specify the time period involved
                                        (which must be for a period of time less than six years from the date of the
                                        disclosure). Your first accounting will be free of charge. However, Company
                                        may charge you for the costs involved in fulfilling any additional request
                                        made within a period of 12 months. The Company will inform you of such
                                        costs in advance, so that you may withdraw or modify your request to save
                                        costs.
                                    </li>
                                    <li><strong>Breach Notification.</strong>You have the right to be notified in the event that the
                                        Company (or a Company Business Associate) discovers a breach of unsecured
                                        PHI.
                                    </li>
                                    <li><strong>Paper Copy.</strong>have the right to obtain a paper copy of this Notice from
                                        the Company at any time upon request. To obtain a paper copy of this notice,
                                        please contact Company by calling 1(800) 513-8153.
                                    </li>
                                    <li><strong>Complaint.</strong>You may complain to Company and to the Secretary of the
                                        Department of Health and Human Services if you believe that your privacy
                                        rights have been violated. To file a complaint with the Company, you must
                                        submit a statement in writing to the Company at
                                        <a target='_blank' href='helpUS@unitedwecare.com'>helpUS@unitedwecare.com.</a> The Company will not retaliate against you
                                        for filing a complaint.
                                    </li>
                                    <li><strong>Further Information.</strong>you would like more information about your privacy
                                        rights, please contact Company by calling 1(800) 513-8153 and ask to speak
                                        to the Privacy and Security Officer. To the extent you are required to send a
                                        written request to the Company to exercise any right described in this Notice,
                                        you must submit your request to <a target='_blank' href='helpUS@unitedwecare.com'>helpUS@unitedwecare.com.</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>


                        <Form.Group className="mb-3 pb-5" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Agree & Continue" />
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PrivacyPolicy