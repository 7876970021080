import { useState } from "react";
import moment from "moment"

const AppointmentTabItem = ({ status, date, time, duration, notes }) => {
  return (
    <div className="Appointment--Item">
      <div className="Appointment--Header">
        <span className={`AppointmentStatus ${status}`}>{status}</span>
        <p>
          {date} | {time} | {duration}
        </p>
      </div>

      {notes && (
        <div className="AppointmentNotes">
          {notes?.map((note, i) => (
            <div key={i}>
              <h6>{note.title}</h6>
              <p>
                {note.description}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppointmentTabItem;
