import { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./MediaCardResponse.css";

const MediaCardResponse = ({
  categoryID,
  id,
  title,
  type,
  description,
  thumbnail,
  largeImage,
  videoURL,
  audioURL,
  whatsappURL,
  video,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [videoSource, setVideoSource] = useState("");

  useEffect(() => {
    if (showModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      window.scrollTo(0, 0);
    }

    // Cleanup function to reset overflowY style when the component unmounts
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [showModal]);

  const handleCardClick = () => {
    if (type === "Video" || type === "Audio") {
      setShowModal(true);
      if (type === "Video") {
        setVideoSource(videoURL);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="MediaCard--Container">

      <Card style={{ width: "280px" }} className="MediaCard">
        <div className="card-img-container" onClick={handleCardClick}>
          <Card.Img variant="top" src={thumbnail ? thumbnail : largeImage} />
          {(videoURL || audioURL) && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>

        <Card.Body id={id} className="d-none">
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>

          {/* <button>Start Now</button> */}
        </Card.Body>
      </Card>


      {showModal && (videoURL || audioURL) && (
        <Modal
          style={{ position: "relative" }}
          className="MediaCard--modal"
          show={showModal}
          onHide={closeModal}
          size="lg"
        >
          <Button
            size="lg"
            onClick={closeModal}
            style={{
              padding: "0",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
              aspectRatio: "1/1",
              position: "absolute",
              right: "0px",
              top: "-40px",
              background: "#FFF",
              color: "#000",
              border: "0",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              style={{
                width: "20px",
                height: "20px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Button>
          <Modal.Body>

            {type === "Video" && videoURL !== undefined && videoURL !== "" && !videoURL.includes("iframe") ? (
              (videoURL.includes("vimeo") ? <iframe src={videoURL} width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title={title} style={{ width: '100%', height: '100%' }}></iframe> : <video src={videoSource} controls autoPlay  />)

            ) : (
              type === "Audio" && audioURL !== undefined && audioURL !== "" ? (
                <>
                  <img src="/assets/img/Audio.jpg" width={100} height={100} className="mb-5" />
                  <audio className="audio-player" src={audioURL} controls autoPlay />
                </>
              ) : (

                <div
                  className="VideoIframe"
                  dangerouslySetInnerHTML={{ __html: video?.embed_html || videoURL }}
                />

              )
            )}

          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default MediaCardResponse;
