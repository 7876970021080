import React from 'react'
import '.././Plans.css'
import { useAuth } from '../../../auth';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import { useNavigate } from 'react-router-dom';

const ComparePlans = () => {

    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    const onFree = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", '/stella')
            setShowLloginPopup(true);
        }
        else {
            return navigate('/stella');
        }
    }

    const onPaid = (item) => {

        sessionStorage.setItem("subscriptionID", item);
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            navigate(`/subscription-order-summary`)
        }
    }

    const onLearnMore = (item) => {
        if (item === 2) {
            navigate(`/know-more/platinum`)
        } else if (item === 11) {
            navigate(`/know-more/premium`)
        }
    }

    return (
        <div>
            {/* <!------ Compare-plan-area (desktop-display) ------> */}
            <div class="compare-plan-area mobile-display-none desktop-display">
                <div className="container">
                    <h2 className="heading"><span className="diff-clr">Compare</span> our plans</h2>
                    <p className="mobile-display-none desktop-display">Plans which help you save money everyday</p>
                    <div className="compare-plan-inner">
                        <table>
                            <tr>
                                <th className="md">Monthly Delights</th>
                                <th className="care">CARE</th>
                                <th className="pre"><img src="assets/img/table1.jpg" /><h3>PREMIUM</h3></th>
                                <th className="plat"><img src="assets/img/table2.jpg" /><h3>PLATINUM</h3></th>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>STELLA</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom">Your ultimate AI coach on the journey to <br />
                                    achieve Mental Wellbeing</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>ASSESSMENTS WITH STELLA</strong></td>
                                <td className="bg-custom"></td>
                                <td className="bg-custom"></td>
                                <td className="bg-custom"></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom">Let Stella support you through clinically approved <br />
                                    assessments to identify your underlying concerns</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>EXCLUSIVE SELF CARE CONTENT</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom">A diverse range of resources in the form of audio,<br />
                                    video, and blogs</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>PREMIUM COURSES</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom">Access to top-tier courses to elevate your mental <br />
                                    health and well-being.
                                </td>
                                <td className='text-center'>Basic Wellness Programs</td>
                                <td className='text-center'>40+ exclusive courses<br />
                                    tailor built for you</td>
                                <td className='text-center'>40+ exclusive courses<br />
                                    tailor built for you</td>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>PERSONALIZED SESSIONS</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom">Tailored to your preferences, with top certified <br />
                                    professionals</td>
                                <td className='text-center'>-</td>
                                <td className='text-center'>-</td>
                                <td className='text-center'>Unlimited 1-on-1 sessions. <br />
                                    Video, voice, chat.</td>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>PERSONALIZED CARE PLANS</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom">Tailored to your preferences, with top certified <br />
                                    professionals</td>
                                <td className='text-center'>-</td>
                                <td className='text-center'>-</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom"><strong>Plan Pricing</strong></td>
                                <td className="pr-tbl">
                                    <span>No Cost</span>
                                    <div className="plan1-btn">
                                        <button type="button" onClick={onFree} className="btn center-btn tbl-btn"><a href="#">Start Now</a></button>
                                    </div>
                                </td>
                                <td className="pr-tbl">
                                    <span>$14.99 /month</span>
                                    <div className="plan1-btn">
                                        <button type="button" onClick={() => onPaid(11)} className="btn center-btn tbl-btn"><a href="#">Subscribe Now</a></button>
                                    </div>
                                    <button type="button" onClick={() => onLearnMore(11)} className="btn center-btn learn-btn"><a href="#">Learn more</a></button>
                                </td>
                                <td className="pr-tbl">
                                    <span>$134.99 /month</span>
                                    <div className="plan1-btn">
                                        <button type="button" onClick={() => onPaid(2)} className="btn center-btn tbl-btn"><a href="#">Subscribe Now</a></button>
                                    </div>
                                    <button type="button" onClick={() => onLearnMore(2)} className="btn center-btn learn-btn"><a href="#">Learn more</a></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!------ Compare-plan-area (mobile-display) ------> */}
            <div className="compare-plan-area mobile-display desktop-display-none">
                <div className="container">
                    <h2 className="heading"><span className="diff-clr">Compare</span> our plans</h2>
                    <p className="mobile-display-none desktop-display">Plans which help you save money everyday</p>
                    <div className="compare-plan-inner">
                        <table>
                            <tr>
                                <th className="md">Monthly Delights</th>
                                <th className="care">CARE</th>
                                <th className="pre"><img src="assets/img/table1.jpg" /><h3>PREMIUM</h3></th>
                                <th className="plat"><img src="assets/img/table2.jpg" /><h3>PLATINUM</h3></th>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size"><strong>STELLA</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom m-para-size">Your ultimate AI coach on the journey to
                                    achieve Mental Wellbeing</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size"><strong>ASSESSMENTS WITH STELLA</strong></td>
                                <td className="bg-custom"></td>
                                <td className="bg-custom"></td>
                                <td className="bg-custom"></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom m-para-size">Let Stella support you through clinically approved
                                    assessments to identify your underlying concerns</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size"><strong>EXCLUSIVE SELF CARE CONTENT</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom m-para-size">A diverse range of resources in the form of audio,
                                    video, and blogs</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size"><strong>PREMIUM COURSES</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom m-para-size">Access to top-tier courses to elevate your mental
                                    health and well-being.
                                </td>
                                <td className="text-center m-para-size">Basic Wellness Programs</td>
                                <td className="text-center m-para-size">40+ exclusive courses<br />
                                    tailor built for you</td>
                                <td className="text-center m-para-size">40+ exclusive courses<br />
                                    tailor built for you</td>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size"><strong>PERSONALIZED SESSIONS</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom m-para-size">Tailored to your preferences, with top certified
                                    professionals</td>
                                <td className='text-center '>-</td>
                                <td className='text-center '>-</td>
                                <td className="text-center m-para-size">Unlimited 1-on-1 sessions. <br />
                                    Video, voice, chat.</td>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size"><strong>PERSONALIZED CARE PLANS</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="bg-wh">
                                <td className="bg-custom m-para-size">Tailored to your preferences, with top certified
                                    professionals</td>
                                <td className='text-center'>-</td>
                                <td className='text-center'>-</td>
                                <td><img src="assets/img/Clip path group.png" className="icon-img" /></td>
                            </tr>
                            <tr>
                                <td className="bg-custom m-table-size" style={{verticalAlign:"middle"}}><strong>Plan Pricing</strong></td>
                                <td className="pr-tbl">
                                    <span>No Cost</span>
                                    <div className="plan1-btn">
                                        <button type="button" onClick={onFree} className="btn center-btn tbl-btn"><a href="#">Start Now</a></button>
                                    </div>
                                    <span>&nbsp;</span>
                                </td>
                                <td className="pr-tbl">
                                    <span>$14.99 /month</span>
                                    <div className="plan1-btn">
                                        <button type="button" onClick={() => onPaid(11)} className="btn center-btn tbl-btn"><a href="#">Subscribe Now</a></button>
                                    </div>
                                    <button type="button" onClick={() => onLearnMore(11)} className="btn center-btn learn-btn"><a href="#">Learn more</a></button>
                                </td>
                                <td className="pr-tbl">
                                    <span>$134.99 /month</span>
                                    <div className="plan1-btn">
                                        <button type="button" onClick={() => onPaid(2)} className="btn center-btn tbl-btn"><a href="#">Subscribe Now</a></button>
                                    </div>
                                    <button type="button" onClick={() => onLearnMore(2)} className="btn center-btn learn-btn"><a href="#">Learn more</a></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComparePlans