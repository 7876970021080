import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation, Pagination } from 'swiper';
import { SubscriptionPacks } from './core/request';
import { useAuth } from '../../auth';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Switch } from '@mui/material';
import { Helmet } from 'react-helmet';

const theme = createTheme({
    palette: {
        primary: {
            main: '#541CF5',
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
});

const Plans = () => {
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [isHovering, setIsHovering] = useState(false);
    const [hoverIndex, setHoverIndex] = useState();
    const [packageType, setPackageType] = useState("Monthly");
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    var body = { "country_id": 233 }
    useEffect(() => {
        SubscriptionPacks(body).then(resp => {
            if (resp.ResponseCode === 200) {
                setSubscriptionData(resp?.data?.packs);
            }
            else {
                alert(resp?.ResponseMessage)
            }
        })
    }, []);

    const onFree = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", '/Stella')
            setShowLloginPopup(true);
        }
        else {
            return navigate('/Stella');
        }
    }

    const onPaid = (item) => {
        debugger
        sessionStorage.setItem("subscriptionID", item?.id);
        if (!currentUser) {
            var plan_id = item?.id;
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            sessionStorage.setItem("subscriptionID", plan_id)
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            var plan_id = item?.id;
            //var fff = current_month;
            sessionStorage.setItem("subscriptionID", plan_id)
            navigate(`/subscription-order-summary`)
        }
    }

    const handleMouseOver = (index) => {
        setIsHovering(true);
        setHoverIndex(index)
    };

    const handleMouseOut = (index) => {
        setIsHovering(false);
        setHoverIndex(index)
    };

    const handelPackageType = () => {
        var sdata = (packageType === "Monthly" ? "Quarterly" : "Monthly");
        setPackageType(sdata);
    }

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>
                    Find the Right Plans - United We Care
                </title>
            </Helmet>
            <div id='plans' className='plansMainContainer' style={{}}>
                <div className='my-5 col-md-4 d-block mx-auto'>
                    <h1 className='font-inter text-center fs-1 mt-3 font-w700'>Find a <span style={{ color: "#541CF5" }}>Plan</span> that Vibes Right for You!</h1>
                    <p className='font-inter text-center px-4 px-md-0'>
                        Embrace the plan that aligns with your aspirations and prioritize your well-being with United We Care.
                    </p>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <span className='fs-5 font-w700 font-helvetica' style={{ color: "#541CF5" }}>Monthly</span>
                    <Switch className='plan-duration-toggle' onChange={handelPackageType} />
                    <span className='fs-5 font-w700 font-helvetica' style={{ color: "#541CF5" }}>Quaterly</span>
                </div>
                <div className='py-5 d-block mx-auto' style={{ width: "90%" }}>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        breakpoints={{
                            450: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1600: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        style={{ height: "750px" }}
                    >
                        <SwiperSlide className=''>
                            <div className='rounded-3 plansContainer d-block m-1'
                                onMouseOver={() => handleMouseOver(999)}
                                onMouseOut={() => handleMouseOut(999)}
                                // key={index}
                                style={{ boxShadow: '0px 4px 20px 0px rgba(50, 50, 50, 0.20)', height: '700px' }}>
                                {isHovering && hoverIndex === 999 &&
                                    <div className='col-12 rounded-top py-2' style={{ background: "#541CF5", height: "10%" }}>
                                        <div className='mostPopular'>
                                        </div>
                                    </div>
                                }
                                <div className='py-3 bg-white d-flex flex-column align-items-center justify-content-center' style={{ borderTopLeftRadius: 12, borderBottom: '0.50px rgba(0, 0, 0, 0.10) solid', height: "20%" }}>
                                    <h5 className='text-center' style={{ color: '#323232', fontSize: 32, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>Care</h5>
                                    <h3 className='text-center' style={{ color: '#323232', fontSize: 48, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>Complimentary</h3>
                                </div>
                                <div className='py-3 px-4 d-flex flex-column justify-content-between' style={{ background: "#F5F5F5", height: isHovering && hoverIndex === 999 ? "70%" : "80%" }}>
                                    <ul className='planslistStyleImage font-helvetica lh-lg p-0' style={{ marginTop: "10px", fontSize: "20px" }}>
                                        <li className='font-helvetica' style={{ fontSize: "20px", lineHeight: "28px" }}><span style={{ color: "#541CF5" }}>✓</span> Basic Wellness Programs</li>
                                        <li className='font-helvetica' style={{ fontSize: "20px", lineHeight: "28px" }}><span style={{ color: "#541CF5" }}>✓</span> Access to STELLA - World’s Most Advanced AI Wellness Coach</li>
                                        <li className='font-helvetica' style={{ fontSize: "20px", lineHeight: "28px" }}><span style={{ color: "#541CF5" }}>✓</span> Enriching self care content and insightful articles</li>
                                    </ul>
                                    <Button
                                        onClick={onFree}
                                        className='w-100 rounded-2 d-block mx-auto mt-5 py-3 font-helvetica'
                                        style={{ border: "1px solid #541CF5", color: "#541CF5", background: "rgba(77, 66, 249, 0.10)", fontSize: "20px", lineHeight: "28px", fontWeight: 700, margin: 30 }}>
                                        Start For Free
                                    </Button>
                                </div>
                            </div>
                        </SwiperSlide>
                        {subscriptionData && subscriptionData?.map(o => { return { ...o, available: o.available.filter(p => p.name == packageType) } })?.filter(o => o.available.length > 0)?.map((data, index) =>
                            <SwiperSlide className=''>
                                <div className='rounded-3 plansContainer m-1'
                                    onMouseOver={() => handleMouseOver(index)}
                                    onMouseOut={() => handleMouseOut(index)}
                                    key={index}
                                    style={{ boxShadow: '0px 4px 20px 0px rgba(50, 50, 50, 0.20)', height: '700px' }}>
                                    {isHovering && hoverIndex === index &&
                                        <div className='col-12 rounded-top py-2' style={{ background: "#541CF5", height: "10%" }}>
                                            {subscriptionData?.recommended &&
                                                <div className='mostPopular'>
                                                    <h6 className='font-inter text-center fs-4 lh-lg text-white'>Most popular</h6>
                                                    <img src='/assets/img/Frame 84.png' alt='' />
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className='rounded-top py-3 bg-white d-flex flex-column align-items-center justify-content-center' style={{ borderTopLeftRadius: 12, borderBottom: '0.50px rgba(0, 0, 0, 0.10) solid', height: "20%" }}>
                                        <h5 className='text-center' style={{ color: '#323232', fontSize: 32, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>{data?.name}</h5>
                                        {data?.available?.filter(o => o.name === packageType).map((item, index) =>
                                            <h3 className='text-center' style={{ color: '#323232', fontSize: 48, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>$ {item?.price_usd}<span style={{ color: "#636363", fontSize: "32px" }}> /{item?.name}</span></h3>
                                        )}
                                    </div>
                                    <div className='py-3 px-4 d-flex flex-column justify-content-between rounded-bottom' style={{ background: "#F5F5F5", height: isHovering && hoverIndex === index ? "70%" : "80%" }}>
                                        {/* <ul className='planslistStyleImage' style={{ marginTop: "10px" }}>
                                    <li>Basic Wellness Programs</li>
                                    <li>Access to STELLA - World’s Most Advanced AI Wellness Coach</li>
                                    <li>Enriching self care content and insightful articles</li>
                                </ul> */}
                                        <p className='font-helvetica' style={{ fontSize: "20px", lineHeight: "28px" }} dangerouslySetInnerHTML={{ __html: data?.plan_description }}></p>
                                        <Button
                                            onClick={() => onPaid(data)}
                                            className='w-100 rounded-2 d-block mx-auto py-3 font-helvetica'
                                            style={{ border: "1px solid #541CF5", color: "#541CF5", background: "rgba(77, 66, 249, 0.10)", margin: "30px", fontSize: "20px", lineHeight: "28px", fontWeight: 700 }}>
                                            Get started
                                        </Button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                        {/* <SwiperSlide className=''>
                        <div className='rounded-3 plansContainer' style={{ boxShadow: '4px 4px 20px rgba(50, 50, 50, 0.20)', height: '600px' }}>
                            <div className='py-3 bg-white d-flex flex-column align-items-center justify-content-center' style={{ borderTopLeftRadius: 12, borderBottom: '0.50px rgba(0, 0, 0, 0.10) solid', height: "25%" }}>
                                <h5 className='text-center' style={{ color: '#323232', fontSize: 32, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>Platinum Care</h5>
                                <h3 className='text-center' style={{ color: '#323232', fontSize: 48, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>$134.99 <span style={{ color: "#636363", fontSize: "32px" }}>/month</span></h3>
                            </div>
                            <div className='px-3 pb-5 d-flex flex-column justify-content-between' style={{ background: "#F5F5F5", height: "75%" }}>
                                <ul className='planslistStyleImage' style={{ marginTop: "10px" }}>
                                    <li>Basic Wellness Programs</li>
                                    <li>Access to STELLA - World’s Most Advanced AI Wellness Coach</li>
                                    <li>Enriching self care content and insightful articles</li>
                                </ul>
                                <Button className='col-10 rounded-2 d-block mx-auto mt-5' style={{ border: "1px solid #541CF5", color: "#541CF5", background: "rgba(77, 66, 249, 0.10)" }}>
                                    Start for FREE
                                </Button>
                            </div>
                        </div>
                    </SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
            <div className='py-5' style={{ backgroundImage: `url("/assets/img/Image-container.png")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)" }}>
                <h2 className='font-helvetica text-center text-white fs-1 lh-lg font-w700'>Why Choose Us?</h2>
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper plansChooseUs col-11 col-md-9 d-block mx-auto">
                    <SwiperSlide className='d-block mx-auto bg-white rounded-3' style={{ height: "500px" }}>
                        <div className='rounded-3 d-flex align-items-center justify-content-between ps-md-5 plansChooseUs p-2 p-md-0 h-100'>
                            <div className='px-3 col-12 col-md-8 d-flex flex-column align-items-center align-items-md-start justify-content-evenly h-100'>
                                <h3 className='font-helvetica fs-1 text-black font-w700 col-md-3 text-center text-md-start'>Comprehensive Approach</h3>
                                <p className='font-helvetica text-black fs-5 col-md-11 text-center text-md-start'>
                                    Worried that an online wellness program alone might not be enough for you? Fear not!
                                    <br /> <br />
                                    Our Platinum Care plan offers access to licensed coaches, ensuring you have a comprehensive toolkit to support your mental well-being.
                                </p>
                                <Button className='border-0 rounded-5 my-3' style={{ background: "#FD6F1F" }}>
                                    <a href='#plans' className='text-decoration-none text-white'>Explore More</a>
                                </Button>
                            </div>
                            <div className='col-4 d-none d-md-block'>
                                <img src='/assets/img/Group 1000005756.png' alt='' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='d-block mx-auto bg-white rounded-3' style={{ height: "500px" }}>
                        <div className='rounded-3 d-flex align-items-center plansChooseUs justify-content-between ps-md-5 p-2 p-md-0 h-100'>
                            <div className='px-3 col-md-8 d-flex flex-column justify-content-between align-items-center align-items-md-start justify-content-evenly h-100'>
                                <h3 className='font-helvetica fs-1 text-black font-w700 col-md-3'>Personalized Matches</h3>
                                <p className='font-helvetica text-black fs-5'>
                                    If your current wellness coach isn't the right fit, we'll quickly re-match you with someone who truly understands your needs. Want to explore options yourself?
                                    <br /> <br />
                                    Our user-friendly app, STELLA, gives you access to a diverse list of certified wellness coaches, complete with filters to find your perfect match.
                                </p>
                            </div>
                            <div className='col-4 mt-3 d-none d-md-block'>
                                <h5 className='font-helvetica fs-4 font-w700' style={{ color: "#2D31A6" }}>Download the App Now!</h5>
                                <div className='d-flex gap-2'>
                                    <a href='https://apps.apple.com/in/app/united-for-her/id1501325167'>
                                        <img src='/assets/img/Group 5845.png' alt='' />
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=com.UnitedFor.Her&hl=en_IN'>
                                        <img src='/assets/img/Group(2).png' alt='' />
                                    </a>
                                </div>
                                <img src='/assets/img/iPhone 13 Pro.png' alt='' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='bg-white d-block mx-auto rounded-3' style={{ height: "500px" }}>
                        <div className='rounded-3 d-flex align-items-center justify-content-between ps-md-5 p-2 p-md-0 h-100' style={{}}>
                            <div className='px-3 col-md-8 h-100 d-flex flex-column align-items-center align-items-md-start justify-content-evenly'>
                                <h3 className='font-helvetica fs-1 text-black font-w700 col-md-3'>Flexible Rescheduling</h3>
                                <p className='font-helvetica text-black fs-5 col-11'>
                                    Life can be unpredictable. We get that!
                                    <br /> <br />
                                    With United We Care, rescheduling is a breeze. No more canceling and waiting for a week; we empower you to book a new time that suits your schedule.
                                </p>
                                <Button className='border-0 rounded-5 my-3 text-white' style={{ background: "#FD6F1F" }}>
                                    <a href='#plans' className='text-decoration-none text-white'>Subscribe</a>
                                </Button>
                            </div>
                            <div className='col-4 d-none d-md-block'>
                                <img src='/assets/img/dummyImage.png' alt='' />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='row col-12 justify-content-between align-items-center'>
                <div className='col-md-8 px-0 d-flex flex-column my-5 align-items-center justify-content-center'>
                    <div>
                        <h2 className='font-helvetica fs-1 font-w700 mx-4 mx-md-0'>Meet your 24x7 wellness expert, <a className='text-decoration-none' href='' style={{ color: "#541CF5" }}>Stella</a>!</h2>
                        <ul className='plansStellaList font-helvetica lh-lg fs-4'>
                            <li>Interacts in 29 vernacular languages</li>
                            <li>Understands 40 different human emotions</li>
                            <li>Help diagnose with 90% accuracy</li>
                            <li>Speak to her about your feelings anytime, anywhere for <a href='' className='text-decoration-none font-w700' style={{ color: "#541CF5" }}>FREE</a></li>
                        </ul>
                        <Button className='border-0 rounded-5 my-3 text-white px-3 py-2 d-block mx-auto mx-md-0' style={{ background: "#FD6F1F" }}>
                            <a href='/stella' className='text-decoration-none text-white'>Talk to Stella</a>
                        </Button>
                    </div>
                </div>
                <img src='/assets/img/dummyImage2.png' alt='' className='col-md-4 px-0' />
            </div>
        </ThemeProvider>
    )
}

export default Plans