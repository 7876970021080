import React from 'react'
import '.././Plans.css'

const TeamArea = () => {
    return (
        <div>
            {/* <!------ team-area ------> */}
            <div className="team-area">
                <h2 className="heading m-dis"> Talk to<span className="diff-clr">100+ Renowned Experts</span> and<br />
                    mental health professionals, globally </h2>
                <div className="our-team">
                    <img src="assets/img/team1.jpg" alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Rowena C Herrera</h3>
                        <p className="description">
                            15+ years of Experience<br />
                            <i> Mental Health and Wellness Coach</i>
                            <br />
                            <br />
                            “Hi, my name is Rowena, and I am excited to work with you as your mental health/wellness coach. I am a graduate of Florida State University where I hold a BS in Psychology and Art Therapy.”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Rowena C Herrera<img src="assets/img/team-v.png" alt="" /></h3>
                        <span className="post"><img src="assets/img/calender.png" alt="" />Available Today</span>
                    </div>
                </div>
                <div className="our-team">
                    <img src="assets/img/team2.jpg" alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Maya Harrison</h3>
                        <p className="description">
                            15+ years of Experience<br />
                            <i> Mental Health and Wellness Coach</i>
                            <br />
                            <br />
                            “Hi, my name is Rowena, and I am excited to work with you as your mental health/wellness coach. I am a graduate of Florida State University where I hold a BS in Psychology and Art Therapy.”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Maya Harrison<img src="assets/img/team-v.png" alt="" /></h3>
                        <span className="post"><img src="assets/img/calender.png" alt="" />Available Today</span>
                    </div>
                </div>
                <div className="our-team">
                    <img src="assets/img/team3.jpg" alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Kenichi Mekaru</h3>
                        <p className="description">
                            15+ years of Experience<br />
                            <i> Mental Health and Wellness Coach</i>
                            <br />
                            <br />
                            “Hi, my name is Rowena, and I am excited to work with you as your mental health/wellness coach. I am a graduate of Florida State University where I hold a BS in Psychology and Art Therapy.”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Kenichi Mekaru<img src="assets/img/team-v.png" /></h3>
                        <span className="post"><img src="assets/img/calender.png" />Available Today</span>
                    </div>
                </div>
                <div className="our-team">
                    <img src="assets/img/team4.jpg" alt="" />
                    <div className="team-content">
                        <h3 className="post-title">Rowena C Herrera</h3>
                        <p className="description">
                            15+ years of Experience<br />
                            <i> Mental Health and Wellness Coach</i>
                            <br />
                            <br />
                            “Hi, my name is Rowena, and I am excited to work with you as your mental health/wellness coach. I am a graduate of Florida State University where I hold a BS in Psychology and Art Therapy.”
                        </p>
                    </div>
                    <div className="team-prof">
                        <h3 className="post-title">Rowena C Herrera<img src="assets/img/team-v.png" /></h3>
                        <span className="post"><img src="assets/img/calender.png" />Available Today</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamArea