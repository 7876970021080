import React from 'react'
import '.././Plans.css'

const PersonalDataArea = () => {
    return (
        <div>
            {/* <!------ personal-data-area (desktop-display) ------> */}
            <div className="personal-data-area mobile-display-none desktop-display">
                <div className="container">
                    <div className="personal-data-inner">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="personal-data-text">
                                    <h3>Your personal data is <span className="diff-clr"> secure</span> with us!</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="personal-data-img">
                                    <ul>
                                        <li><img alt='' src="assets/img/iso.webp.png" /></li>
                                        <li><img alt='' src="assets/img/hippa.webp.png" /></li>
                                        <li><img alt='' src="assets/img/eu.webp.png" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!------ personal-data-area (mobile-display) ------> */}
            <div className="personal-data-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="personal-data-inner">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="personal-data-text">
                                    <h3>Your personal data is <span className="diff-clr"> secure</span> with us!</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="personal-data-img">
                                    <ul>
                                        <li><img src="assets/img/miso.webp.jpg" alt='' /></li>
                                        <li><img src="assets/img/mhippa.webp.jpg" style={{ padding: "19px 0" }} alt='' /></li>
                                        <li><img src="assets/img/meu.webp.jpg" alt='' /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalDataArea