import React from 'react'
import '.././Plans.css'
import { useAuth } from '../../../auth';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import { useNavigate } from 'react-router-dom';

const PickUpPlans = () => {

    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    const onFree = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", '/stella')
            setShowLloginPopup(true);
        }
        else {
            return navigate('/stella');
        }
    }

    const onPaid = (item) => {

        sessionStorage.setItem("subscriptionID", item);
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            navigate(`/subscription-order-summary`)
        }
    }

    const onLearnMore = (item) => {
        if (item === 2) {
            navigate(`/know-more/platinum`)
        } else if (item === 11) {
            navigate(`/know-more/premium`)
        }
    }

    return (
        <div>
            {/* <!------ pick-plan-area ------> */}
            <div id='Subscribe_Now' className="pick-plan-area">
                <div className="container">
                    <h2 className="heading">Pick Your<span className="diff-clr"> Plan</span></h2>
                    <div className="pick-plan-inner">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="plan-bg">
                                    <div className="plan">
                                        <h3>Care</h3>
                                        <h6>Available to you 24x7!</h6>
                                        <p><span className="price">$ 0 </span>/month</p>
                                        <hr />
                                        <h4>Benefits</h4>
                                        <ul>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Chat with STELLA</h5>
                                                <p>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Basic Wellness Courses</h5>
                                                <p>Basic Wellness Courses to elevate your
                                                    well-being</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Exclusive enriching content</h5>
                                                <p>Videos, audio, and blogs</p>
                                            </li>
                                        </ul>
                                        <div className="plan-btn">
                                            <button type="button" onClick={onFree} className="btn"><a href="#">Start Now</a></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="plan-bg1">
                                    <div className="plan1">
                                        <h3>Premium Care</h3>
                                        <h6>A plan that actually takes CARE of you</h6>
                                        <p><span className="price">$ 14.99 </span>/month</p>
                                        {/* <div className="seller-area">
                                            <img src="assets/img/Best Seller.png" />
                                            <p>Best Seller</p>
                                        </div> */}
                                        <hr />
                                        <h4>Benefits</h4>
                                        <ul>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Chat with STELLA</h5>
                                                <p>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Premium Courses</h5>
                                                <p>Exclusive access to courses aimed at
                                                    enhancing your mental wellness</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Exclusive enriching content</h5>
                                                <p>Videos, audio, and blogs</p>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan1-btn">
                                                    <button type="button" onClick={() => onPaid(11)} className="btn col-12"><a href="#">Subscribe Now</a></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan2-btn">
                                                    <button type="button" onClick={() => onLearnMore(11)} className="btn col-12" style={{ whiteSpace: "nowrap" }}><a href="#">Learn More</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="plan1-para">
                                    <p>And the best part, our plans come with 7 days cooling-off period </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="plan-bg2 m-t">
                                    <div className="plan1">
                                        <h3>Platinum Care</h3>
                                        <h6>The only mental health subscription you
                                            will need</h6>
                                        <p><span className="price">$134.99 </span>/month</p>
                                        {/* <div className="seller-area m-t-n">
                                            <img src="assets/img/Medal.png" />
                                            <p>Best Seller</p>
                                        </div> */}
                                        <hr />
                                        <h4>Benefits</h4>
                                        <ul>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Chat with STELLA</h5>
                                                <p>Unlock self-improvement potential with Stella's comprehensive assessment</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Premium Courses</h5>
                                                <p>Exclusive access to courses aimed at
                                                    enhancing your mental wellness</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Exclusive enriching content</h5>
                                                <p>Videos, audio, and blogs</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Customized care plans </h5>
                                                <p>Designed specifically to meet your needs</p>
                                            </li>
                                            <li>
                                                <img src="assets/img/check-bx.png" />
                                                <h5>Personalised 1-1 sessions</h5>
                                                <p>Unlimited</p>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan1-btn">
                                                    <button type="button" onClick={() => onPaid(2)} className="btn new"><a href="#">Subscribe Now</a></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="plan2-btn">
                                                    <button type="button" onClick={() => onLearnMore(2)} className="btn new" style={{ whiteSpace: "nowrap" }}><a href="#">Learn More</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickUpPlans