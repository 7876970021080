import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, ButtonGroup, Form, InputGroup, Modal } from 'react-bootstrap'
import { usOnBoarding_Disclaimer, usOnBoarding_registrationStatus } from '../core/_request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const RegisterAsProfessionalSummaryTab = (props) => {
    console.log("props", props)
    const [data, setData] = useState({})
    const [disclaimerModal, setDisclaimerModal] = useState(false);
    const [loading, setLoading] = useState("unchecked")
    const [registeredData, setRegisteredData] = useState({})
    const handleClose = () => setDisclaimerModal(false);
    const [isStatusActive, setIsStatusActive] = useState(true)

    var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
    useEffect(() => {

        var body = { "user_id": user_id }
        usOnBoarding_Disclaimer(body).then((resp) => {
            if (resp.code === 200) {
                setData(resp.data)
                // console.log("data", resp?.data?.disclaimer)
            }
            else {
                toast(resp?.data?.error ?? resp?.message);
            }
        })
        // if (isStatusActive) {
        //     debugger
        //     var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
        //     var body = { "user_id": user_id }
        //     usOnBoarding_registrationStatus(body).then((resp) => {
        //         setRegisteredData(resp.data)
        //         setIsStatusActive(false)
        //     })
        // }

    }, [user_id, registeredData]);
    //user_id,  

    const handleShow = () => {
        user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
        var body = { "user_id": user_id }
        usOnBoarding_registrationStatus(body).then((resp) => {
            if (resp.code === 200 && resp?.data?.profile === true && resp?.data?.professional === true && resp?.data?.scheduling === true && resp?.data?.payment === true) {
                setDisclaimerModal(true);
            }
            else {
                toast("Please Complete Your Profile.");
            }
        })
    }

    const disclaimerModalSubmit = () => {
        setDisclaimerModal(false)
        props.onNext();
    }

    const handleCheckBox = (event) => {
        if (event.target.checked) {
            setLoading("checked")
        } else {
            setLoading("unchecked")
        }
    }

    const profileTab = () => {
        props.onClick('profile');
    }

    const ProfessionalTab = () => {
        props.onClick('professionalDetail');
    }

    const SchedulingTab = () => {
        props.onClick('scheduling');
    }

    const PaymentTab = () => {
        props.onClick('payment');
    }

    return (
        <div>
            <div className='d-flex align-items-center gap-3 py-3 rounded-top px-3' style={{ background: "#C4E5F9" }}>
                <img src='/assets/img/Vector(28).png' alt='' />
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>Summary</p>
            </div>
            <div className='mx-3 my-2'>
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>
                    Information
                </p>
                <p className='font-inter' style={{ color: "#6A6C6A", fontSize: "16px" }}>
                    Complete your information before we can proceed.
                </p>
            </div>
            <div className='mx-3 my-2'>
                <div className='d-flex flex-column gap-3'>
                    {props.registeredData?.profile &&
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ background: "#2F5711" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (1).png' alt='' />
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Profile Details
                                </p>
                            </div>
                            <div>
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Completed
                                </p>
                            </div>
                        </div>
                        ||
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ border: "1px solid #A8200D" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (9).png' alt='' />
                                <p className='font-inter' style={{ fontSize: "16px", color: "#A8200D" }}>
                                    Profile Details
                                </p>
                            </div>
                            <div>
                                <Button className='rounded-3 border-0 text-black' onClick={profileTab} style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                                    <p className='font-inter text-black'>
                                        Complete Now
                                    </p>
                                </Button>
                            </div>
                        </div>
                    }
                    {props.registeredData?.professional &&
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ background: "#2F5711" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (2).png' alt='' />
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Professional Details
                                </p>
                            </div>
                            <div>
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Completed
                                </p>
                            </div>
                        </div>
                        ||
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ border: "1px solid #A8200D" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (10).png' alt='' />
                                <p className='font-inter' style={{ fontSize: "16px", color: "#A8200D" }}>
                                    Professional Details
                                </p>
                            </div>
                            <div>
                                <Button className='rounded-3 border-0 text-black' onClick={ProfessionalTab} style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                                    <p className='font-inter text-black'>
                                        Complete Now
                                    </p>
                                </Button>
                            </div>
                        </div>
                    }
                    {props.registeredData?.scheduling &&
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ background: "#2F5711" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (3).png' alt='' />
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Scheduling
                                </p>
                            </div>
                            <div>
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Completed
                                </p>
                            </div>
                        </div>
                        ||
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ border: "1px solid #A8200D" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (11).png' alt='' />
                                <p className='font-inter' style={{ fontSize: "16px", color: "#A8200D" }}>
                                    Scheduling
                                </p>
                            </div>
                            <div>
                                <Button className='rounded-3 border-0 text-black' onClick={SchedulingTab} style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                                    <p className='font-inter text-black'>
                                        Complete Now
                                    </p>
                                </Button>
                            </div>
                        </div>
                    }
                    {props.registeredData?.payment &&
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ background: "#2F5711" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (4).png' alt='' />
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Payment
                                </p>
                            </div>
                            <div>
                                <p className='font-inter text-white' style={{ fontSize: "16px" }}>
                                    Completed
                                </p>
                            </div>
                        </div>
                        ||
                        <div className='col-12 rounded-3 d-flex justify-content-between align-items-center px-3' style={{ border: "1px solid #A8200D" }}>
                            <div className='d-flex align-items-center gap-3 py-3'>
                                <img src='/assets/img/Vector (5).png' alt='' />
                                <p className='font-inter' style={{ fontSize: "16px", color: "#A8200D" }}>
                                    Payment
                                </p>
                            </div>
                            <div>
                                <Button className='rounded-3 border-0 text-black' onClick={PaymentTab} style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                                    <p className='font-inter text-black'>
                                        Complete Now
                                    </p>
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                <div className='d-flex align-items-center justify-content-end my-5'>
                    <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                        <Button
                            className='rounded-3 border-0 text-black lh-lg px-3'
                            style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}
                            onClick={handleShow}
                        >
                            Next
                        </Button>
                    </ButtonGroup>
                </div>
                <Modal
                    show={disclaimerModal}
                    onHide={() => setDisclaimerModal(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='disclaimer-popUp'
                >
                    <Modal.Body>
                        <div className='px-5'>
                            <p dangerouslySetInnerHTML={{ __html: data?.disclaimer }} className='font-inter lh-lg' style={{ fontSize: "16px", color: "#21231E" }}></p>
                        </div>
                        <hr />
                        <div className='mx-2'>
                            <div className='d-flex gap-3 align-items-center'>
                                <Form.Check aria-label="T&C" onChange={handleCheckBox} />
                                <label className='font-inter font-w500' style={{ fontSize: "16px", color: "#21231E" }}>
                                    "I, understand and accept &nbsp;
                                    <Link to='/term-and-condition' target='_blank' style={{ color: "#0065FF" }}>T&C"</Link>
                                </label>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: data?.footer }} className='font-inter font-w500' style={{ fontSize: "12px", color: "#6A6C6A" }}>

                            </p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center my-3'>
                            <ButtonGroup className='d-flex flex-row align-items-center justify-content-center me-3'>
                                <Button className='rounded-5 border-0 text-black py-2 px-4 font-inter lh-lg'
                                    style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}
                                    onClick={disclaimerModalSubmit}
                                    disabled={loading === "unchecked"}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <ToastContainer />
        </div>
    )
}

export default RegisterAsProfessionalSummaryTab