import { useState, useEffect, forwardRef } from "react";
import EnableHolidayModeModal from "./modals/EnableHolidayModeModal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";

import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import TextField from '@mui/material/TextField';

import MyScheduleTable from "./tables/MyScheduleTable";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

//Icon Button
import IconButton from '@mui/material/IconButton';

//Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useAuth } from "../../auth";



import dayjs from "dayjs";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExpertScheduleDB = () => {
  const [workHourStart, setWorkHourStart] = useState("");
  const [workHourEnd, setWorkHourEnd] = useState("");
  const [nextDays, setNextDays] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [times, setTimes] = useState([]);
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");


  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [openEditReschedule, setOpenEditReschedule] = useState(false);
  const [openDeleteReschedule, setOpenDeleteReschedule] = useState(false);

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [rescheduleDates, setResheduleDates] = useState({})
  const [holidayModeDialogDate, setHolidayModeDialogDate] = useState(dayjs().format("DD MMM, YYYY"))

  const handleClickOpenRescheduleDialog = () => {
    setOpenRescheduleDialog(true);
  };

  const handleCloseRescheduleDialog = () => {
    setOpenRescheduleDialog(false);
  };

  const handleCloseEditRescheduleDialog = () => {
    setOpenEditReschedule(false)
  }

  const handleCloseDeleteRescheduleDialog = () => {
    setOpenDeleteReschedule(false)
  }

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userType, logout } = useAuth();


  // Check if user is logged in
  useEffect(() => {

    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate('/login');
      window.location.href = "https://www.unitedwecare.com/";
    }
  }, [location, navigate]);



  const daysOfWeek = [
    { value: "mon", label: "Monday" },
    { value: "tue", label: "Tuesday" },
    { value: "wed", label: "Wednesday" },
    { value: "thu", label: "Thursday" },
    { value: "fri", label: "Friday" },
    { value: "sat", label: "Saturday" },
    { value: "sun", label: "Sunday" },
  ];

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  const getSlotsData = async () => {
    const payload = {
      user_id: userID,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const { slots } = await postResponse.data;
      //const { holidays } = await postResponse.data;

      setSelectedSlots(slots);
      //setHolidayList(holidays)
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTimes = async () => {
    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/expert/dashboard/time-slots",
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();

      setTimes(postResponse.data?.time_slots);
      // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  //Time ranges
  useEffect(() => {
    return () => {
      fetchTimes();
      getSlotsData();
    }
  }, []);

  const handleWorkHourStartChange = (event) => {
    setWorkHourStart(event.target.value);
  };
  const handleWorkHourEndChange = (event) => {
    setWorkHourEnd(event.target.value);
  };

  const handleSelectDays = (event) => {
    const data = event.target.value;
    setSelectedDays(data);
  };

  const handleSelectNextDays = (event) => {
    setNextDays(event.target.value);
  };

  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };


  const handleAutoPopulate = async () => {
    const payload = {
      user_id: userID,
      start_time_id: workHourStart,
      end_time_id: workHourEnd,
      week_days: selectedDays,
      days: Number(nextDays),
    };

    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/expert/dashboard/schedule-autopopulate",
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();

      if (postResponse.code == 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const countSlotsBetweenDates = (slots, startDate, endDate) => {

    const start = dayjs(startDate, "YYYY/MM/DD").format("YYYY-MM-DD");
    const end = dayjs(endDate, "YYYY/MM/DD").format("YYYY-MM-DD");

    let count = 0;
    slots.forEach((slot) => {
      const slotDate = dayjs(slot.date);
      if (slotDate.isBetween(start, end, null, "[]")) {
        count++;
      }
    });

    return count;
  };


  const numberOfSlots = countSlotsBetweenDates(selectedSlots, rescheduleDates.start_date, rescheduleDates.end_date);

  const handleHolidayMode = (date, result) => {
    result.expert_id = currentUser?.UserId
    result.from_date = dayjs(result.start_date, "YYYY-MM-DD").format("YYYY/MM/DD");
    result.to_date = dayjs(result.end_date, "YYYY-MM-DD").format("YYYY/MM/DD");
    //console.log("result", result)
    setHolidayModeDialogDate(dayjs(date).format("dddd, D MMMM"))
    setResheduleDates(result)
    setOpenRescheduleDialog(true)
  }

  //console.log("selectedSlots", selectedSlots)

  const handleSaveEditRescheduleDialog = async () => {
    const payload = rescheduleDates

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/holiday`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();

      //console.log("postResponsed", postResponse)
      if (postResponse.code == 200) {
        setOpenEditReschedule(false);
        setOpenRescheduleDialog(false);
        setSnackbarMessage("Holiday Mode dates updated!");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

  }

  const handleDeleteReschedule = async () => {
    const payload = {id: rescheduleDates.id};

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/cancel-holiday`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();

      //console.log("postResponsed", postResponse)
      if (postResponse.code == 200) {
        setOpenDeleteReschedule(false);
        setOpenRescheduleDialog(false);
        setSnackbarMessage("Holiday Mode dates updated!");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    
  }


  return (
    <>
      <div className="my-3 ScheduleDashboard">
        <div className="ScheduleDashboard--Header d-flex">
          <h2>My Schedule</h2>
          <EnableHolidayModeModal />
        </div>

        <div className="ScheduleDashboard--Content d-flex gap-3 mt-3">

          {/* Populate Automatically */}
          <div className="bg-white rounded-3 p-3 PopulateSection d-none">
            <h4>Populate Automatically</h4>

            <div className="d-flex gap-2 mt-3 PopulateSection--Inputs">
              <FormControl fullWidth>
                <InputLabel id="work-start-label">Workhour Start From</InputLabel>
                <Select
                  labelId="work-start-label"
                  id="work-start-select"
                  value={workHourStart}
                  label="Workhour Start From"
                  onChange={handleWorkHourStartChange}
                >
                  {times.map((time) => (
                    <MenuItem key={time.id} value={time.id}>
                      {time.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="work-end-label">Workhour Ends At</InputLabel>
                <Select
                  labelId="work-end-label"
                  id="work-end-select"
                  value={workHourEnd}
                  label="Workhour ends at"
                  onChange={handleWorkHourEndChange}
                >
                  {times.map((time) => (
                    <MenuItem key={time.id} value={time.id}>
                      {time.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>Working Days</InputLabel>
                <Select
                  multiple
                  value={selectedDays}
                  label="Select Days of week"
                  onChange={handleSelectDays}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {daysOfWeek.map((day) => (
                    <MenuItem
                      key={day.value}
                      value={day.label.toLocaleLowerCase()}
                    >
                      <Checkbox
                        checked={selectedDays.includes(day.label.toLocaleLowerCase())}
                        name={day.label}
                      />
                      {day.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <TextField label="For Next" value={Number(nextDays)} onChange={handleSelectNextDays} inputProps={{ inputMode: 'numeric' }} />

              </FormControl>
            </div>

            <Button className="GreenCTA mt-3" onClick={handleAutoPopulate}>
              Add to Schedule
            </Button>
          </div>

          <div className="bg-white rounded-3 p-3 ManualSection">
            <h4>Manually select Time Slots</h4>

            {/* Go to date */}

            {/* <div className="mt-3 d-flex align-items-center gap-3">
            <div style={{ flex: "1" }}></div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker label="Go to date" sx={{ width: "100%" }} />
              </DemoContainer>
            </LocalizationProvider>

            <Button className="GreenCTA mt-2 GoToDate">Go</Button>
          </div> */}

            <div className="ManualSection--Selector">
              <MyScheduleTable onHolidayDateClick={handleHolidayMode} />
            </div>
          </div>
        </div>


        {/* Holidaymode Date Click Dialog */}
        <Dialog
          open={openRescheduleDialog}
          onClose={handleCloseRescheduleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >


          <div id="alert-dialog-title" style={{ display: 'flex', flexDirection: 'column', padding: '20px 24px' }}>
            <h2>Holiday Mode is active</h2>
            <span style={{ fontWeight: '400', fontSize: '1.25rem' }}>{holidayModeDialogDate}</span>
          </div>
          <DialogContent sx={{ minWidth: '600px', width: '100%' }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: '#000', fontSize: '1rem', fontWeight: '400' }}>
              Rejoice. You are in holiday today! Take a much deserved break. Remember your mental health is as important as your clients.
            </DialogContentText>
          </DialogContent>
          <DialogActions
          //sx={{ borderTop: '1px solid lightgray' }}
          >
            <IconButton aria-label="edit" onClick={() => { setOpenEditReschedule(true) }}>
              <EditIcon />
            </IconButton>

            <IconButton aria-label="delete" onClick={() => { setOpenDeleteReschedule(true) }}>
              <DeleteIcon />

            </IconButton>


            {/* <Button onClick={handleCloseRescheduleDialog}>
              Close
            </Button> */}
          </DialogActions>
        </Dialog>

        {/* Holidaymode Edit Dates Click Dialog */}
        <Dialog
          open={openEditReschedule}
          onClose={handleCloseEditRescheduleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >


          <div id="alert-dialog-title" style={{ display: 'flex', flexDirection: 'column', padding: '20px 24px' }}>
            <h2>{holidayModeDialogDate}</h2>
          </div>
          <DialogContent sx={{ minWidth: '600px', width: '100%' }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: '#000', fontSize: '1rem', fontWeight: '400' }}>
              Do you want to edit holiday dates?
            </DialogContentText>

            <div className="d-flex mt-3 align-items-center gap-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={dayjs(rescheduleDates?.from_date)}
                  onChange={(date) => setResheduleDates((prevData) => ({ ...prevData, from_date: dayjs(date).format("YYYY/MM/DD") }))}
                  label="New From Date"
                  disablePast={true}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={dayjs(rescheduleDates?.to_date)}
                  onChange={(date) => setResheduleDates((prevData) => ({ ...prevData, to_date: dayjs(date).format("YYYY/MM/DD") }))}
                  label="New to Date"
                  disablePast={true}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>


            {numberOfSlots > 0 && (
              <>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <WarningRoundedIcon sx={{ color: "#D2293D" }} />
                  <p style={{ fontSize: "16px" }}>
                    You have {numberOfSlots} Appointment between selected dates
                  </p>
                </div>
                <Button
                  sx={{
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    color: "#1b8bcf",
                    backgroundColor: "#1b8bcf2b",
                    marginBottom: "10px",
                  }}
                  className="mt-3 redirectToAppointmentsCTA"
                  variant="contained"
                  disableElevation
                  onClick={() => navigate("/expert/appointments")}
                >
                  Go to Appointments
                </Button>
              </>
            )}

          </DialogContent>
          <DialogActions
          //sx={{ borderTop: '1px solid lightgray' }}
          >

            {/* <Button variant="primary" onClick={handleCloseEditRescheduleDialog}>
              Save
            </Button> */}

            <Button onClick={handleCloseEditRescheduleDialog} sx={{ textTransform: 'capitalize' }}>
              Cancel
            </Button>

            <Button
              disabled={numberOfSlots > 0 ? true : false}
              className="ConfirmBooking PrimaryCTA"
              onClick={handleSaveEditRescheduleDialog}
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Holidaymode Delete Dates Click Dialog */}
        <Dialog
          open={openDeleteReschedule}
          onClose={() => setOpenDeleteReschedule(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >


          <div id="alert-dialog-title" style={{ display: 'flex', flexDirection: 'column', padding: '20px 24px' }}>
            <h2>{holidayModeDialogDate}</h2>
          </div>
          <DialogContent sx={{ minWidth: '600px', width: '100%' }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: '#000', fontSize: '1rem', fontWeight: '400' }}>
              Are you sure you want to remove holiday?
            </DialogContentText>
          
          </DialogContent>
          <DialogActions
          //sx={{ borderTop: '1px solid lightgray' }}
          >

            {/* <Button variant="primary" onClick={handleCloseEditRescheduleDialog}>
              Save
            </Button> */}

            <Button onClick={() => setOpenDeleteReschedule(false)} sx={{ textTransform: 'capitalize' }}>
              Cancel
            </Button>

            <Button
              className="ConfirmBooking PrimaryCTA"
              onClick={handleDeleteReschedule}
              variant="contained"
              disableElevation
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>

    </>
  );
};

export default ExpertScheduleDB;
