import { TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import {
    getExpertbyId_WebService, subscription_subscribe_odoo, UseCouponCodeService, SubscriptionPacks, updatePhoneNumber, AddAppointmentUserService, AddUserPackageService, UserDetailsService, AddTransactionService,
    UpdateTransactionService,
    DeductFromSubscription
} from './core/request'
import { useAuth } from '../../auth'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { getCountryCode, getCurrencyCode } from '../core/commonFun';
import { useContext } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import moment from 'moment';
import { Helmet } from 'react-helmet'

function loadRazorpayScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const SubscriptionOrderSummary = () => {
    const [loading, setLoading] = useState(false);
    const [packsData, setPacksData] = useState({});
    const [optionData, setOptionData] = useState();
    const [couponCode, setCouponCode] = useState();
    const [couponloading, setCouponloading] = useState(false);
    const [expertDetailsData, setExpertDetailsData] = useState();
    const [mainAmountAfterCoupone, setMainAmountAfterCoupone] = useState();
    const [number, setNumber] = useState();
    const [couponCodeMessage, setCouponCodeMessage] = useState();
    const [data, setData] = useState();
    const [dataLayer, setDataLayer] = useState([]);
    const [dataLayerJSON, setDataLayerJSON] = useState(null)

    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();
    //let { id } = useParams();
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();
    const search = useLocation().search;

    const subscriptionID = JSON.parse(sessionStorage.getItem("subscriptionID"));
    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let id = sessionStorage.getItem("subscriptionID")
    //console.log("first", id)
    var body = { "id": id }
    useEffect(() => {
        SubscriptionPacks(body).then(resp => {
            if (resp?.ResponseCode === 200) {
                var price = resp?.data?.packs?.find(p => p?.id === parseInt(id))?.available[0];
                setOptionData(price);
                setPacksData(resp?.data)
            }
            // console.log(packsData, "packsData")
        })
        var sdata = JSON.parse(localStorage.getItem("bookAnAppointmentData"));
        if (sdata) {
            getExpertbyId_WebService(sdata?.selectSlot?.Expert).then(async resp => {
                setData(sdata);
                setExpertDetailsData(resp.data);
            });
        }
    }, []);

    // console.log("packsData", packsData);
    // console.log("optionData", optionData)

    useEffect(() => {

        let dataOptions = {
            event: "purchase",
            ecommerce: {
                value: +optionData?.price_usd,
                currency: "USD",
                items: [
                    {
                        item_name: packsData.packs !== undefined ? packsData?.packs[0]?.name : "",
                        price: +optionData?.price_usd,
                        item_brand: "United We Care",
                        item_category: "Subscription",
                        index: 1,
                        duration: optionData?.name,
                        mobile: "mobile number here",
                        quantity: 1,
                        paymentSuccess: null
                    }
                ]
            }
        }

        setDataLayerJSON(dataOptions)

        // Initialize the data layer
        //window.dataLayer = window.dataLayer || [];

        // Push the subscription data to the data layer
        //window.dataLayer.push(dataOptions);

        //console.log("dataOptions", dataOptions)


    }, [optionData])

    // if (window.dataLayer) {
    //     console.log("The data layer has been added to the body.", window.dataLayer);
    // } else {
    //     console.log("The data layer has not been added to the body.");
    // }

    const applyCoupon = () => {
        if (!couponCode) {
            setCouponCodeMessage("Please fill coupon code.")
            return;
        }
        setCouponloading(true);
        var body = { "CouponCode": couponCode, "Type": expertDetailsData.RegisterYourselfAs, "Amount": optionData?.price_usd };
        UseCouponCodeService(body).then(resp => {
            if (resp.data == "") {
            }
            else {
                setMainAmountAfterCoupone(resp.data);
            }
            setCouponCodeMessage(resp.ResponseMessage)
            setCouponloading(false);
        })
    }

    const packsNameChange = event => {
        var jsonParse = JSON.parse(event.target.value)
        setOptionData(jsonParse);
        setDataLayerJSON((prevState) => ({
            ...prevState,
            duration: jsonParse,
            price: +optionData?.price_usd,
        }))
    }

    sessionStorage.setItem("SubscriptionDetails", JSON.stringify(dataLayerJSON))

    const onChangeNumber = () => {
        var body = {
            MobileNo: number
        }
        updatePhoneNumber(body).then(resp => {
            if (resp.data == "") {
            }
        })
    }

    const addTrans = (response, AddUserPackageData, TotalAmount, UserDetails, Indianpackagewithprice) => {

        let rendId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "_web";
        var body = {
            ExpertId: data?.selectSlot?.Expert,
            PackageId: AddUserPackageData.PackageId,
            TransactionId: rendId,
            //Amount:this.state.Indianpackagewithprice.Amount,
            Amount: TotalAmount,
            //Coupon: this.state.CouponCode,
            isMarkAutoRenew: false,
            TransactionType: "Credit Card",
            PaymentGateway_OrderId: "",
            Billing_Address_Master: {
                TransactionId: rendId,
                Name: UserDetails.Name,
                Email: UserDetails.Emailid,
                Mobile: UserDetails.MobileNo,
                //Address:this.state.UserDetails.Address.City+", "+this.state.UserDetails.Address.Country+", "+this.state.UserDetails.Address.Pin+" "
            },
            // DiscountAmount: (countryCode == "IN" ? (Indianpackagewithprice.Amount - TotalAmount) : 0),
            DiscountAmount: 0,
            PaymentGateway_OrderId: response?.razorpay_payment_id
        }

        AddTransactionService(body).then((item) => {

            var tbody = { TransactionId: rendId, Status: "Completed" }
            UpdateTransactionService(tbody).then(trp => {
                try {
                    var subscribe_odoo_body = {
                        "user_id": user_id,
                        "plan_id": parseInt(id),
                        "duration_id": optionData?.id,
                        "payment_method": "card",
                        "payment_id": response?.razorpay_payment_id,
                        "order_id": rendId,
                        "signature": ""
                    }
                    subscription_subscribe_odoo(subscribe_odoo_body).then(trp => console.log("subscription_subscribe_odoo resp", trp));
                } catch { }

                setLoading(false);
                setDataLayerJSON((prevData) => ({
                    ...prevData,
                    paymentSuccess: true
                }))

                window.dataLayer.push(dataLayerJSON)
                navigate("/payment-success");
            });
        });
    }

    const Stripe_Payment = token => {
        setLoading(true);
        onChangeNumber();


        const TotalAmount = Math.round(mainAmountAfterCoupone ?? optionData?.price_usd);

        var body = {
            stripeToken: token.id,
            amount: TotalAmount,
            currency: 'USD',
            ExpertId: data?.selectSlot?.Expert, // Expert Id
            AppointmentDate: moment(data?.selectedDate).format("YYYY-MM-DD"),
            App_StartTime: data?.selectSlot?.Time_Duration,
            CallMode: "Video_Call",
            CallTime: data?.callTime
        }

        const AppointmentId = new URLSearchParams(search).get("AppointmentId");
        // if (AppointmentId) { 
        //     body.AppointmentId = AppointmentId;
        //     RescheduledService(body).then(resp => {
        //         alert("Appointment Successfully Rescheduled!");
        //         navigate(`/user-dashboard/my-appointments/${AppointmentId}`);
        //     });
        // }
        // else {
        AddAppointmentUserService(body).then(async AddAppointmentresp => {
            var tbody = {
                ExpertId: data?.selectSlot?.Expert,
                PackageName: "Pay As You go - USA",
                Country: currentUser.Country,
                Type: expertDetailsData?.RegisterYourselfAs,
                HSTAmount: 0,
                AppointmentCount: 0,
                DurationInMonths: 0,
                Amount: TotalAmount,
            }
            if (AddAppointmentresp.ResponseCode === 200) {
                AddUserPackageService(tbody).then(resp => {
                    var UserDetails = UserDetailsService().then((resp) => resp.data);
                    var response = { razorpay_payment_id: AddAppointmentresp?.data?.PaymentId };
                    addTrans(response, resp.data, TotalAmount, UserDetails, data?.data2);
                })
            }
            else if (AddAppointmentresp.ResponseCode === 400) {
                setLoading(false);
                alert(AddAppointmentresp.ResponseMessage);
            }
            else {
                setDataLayerJSON((prevData) => ({
                    ...prevData,
                    paymentSuccess: false
                }))
                navigate("/payment-failed");
            }
        })
        // }
    };


    return (
        <Container>
            <div className='col-md-9 d-block mx-auto my-5'>
                <Helmet>
                    <title>Subscription Order Summary - United We Care</title>
                </Helmet>
                <h6 className='font-inter fs-2'>Order Summary</h6>
                <p className='font-inter fs-5 lh-lg'>You are just one step away from beginning the healing journey.</p>
                {packsData?.packs?.filter(p => p?.id === parseInt(id))?.map((item, index) =>
                    <div className='rounded-3 d-md-flex my-3 py-3' style={{ border: "1px solid #E2E2E1", }}>
                        <div className='py-2 px-3 col-md-6 pb-3'>
                            <h6 className='font-inter fs-4 lh-base'>{item?.name}</h6>
                            <p className='font-inter fs-6' dangerouslySetInnerHTML={{ __html: item?.plan_description }}></p>
                        </div>
                        <div class="vr d-none d-md-inline-block" style={{ width: '3px' }}></div>
                        <hr className='d-block d-md-none' />
                        <div className='py-2 px-3 w-100 d-flex flex-column align-items-center pb-5'>
                            <div className='col-12 lh-lg'>
                                <Form.Select onChange={packsNameChange} size="lg" className='my-3'>
                                    {item?.available?.map((option, index) =>
                                        <option value={JSON.stringify(option)}>{option?.name}</option>
                                    )}
                                </Form.Select>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className='font-inter fs-6 lh-lg'>Charges:</p> <p className='font-inter fs-6'>$ {optionData?.price_usd ?? item?.available[0]?.price_usd}</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className='font-inter fs-6 lh-lg'>Discount:</p> <p className='font-inter fs-6'>$ 0</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h6 className='font-inter fs-6 lh-lg'>Final Amount :</h6> <h6 className='font-inter fs-6'>$ {mainAmountAfterCoupone ?? optionData?.price_usd ?? item?.available[0]?.price_usd}</h6>
                                </div>
                                <div className='d-flex justify-content-between justify-content-md-between rounded-2 my-3'
                                    style={{ border: "1px solid #D9D9D9", borderRight: "none" }} >
                                    <input className='rounded-2 border-0'
                                        type='text' placeholder='Discount / Referral code'
                                        value={couponCode} onChange={(e) => setCouponCode(e.target.value)}
                                        style={{ height: "45px", paddingLeft: 7 }}
                                    />
                                    {
                                        couponloading &&
                                        <Button className='col-3 py-2'><img src="/assets/img/loading.gif" alt="Unitedwecare" style={{ height: 26, width: 26 }} /></Button>
                                        ||
                                        <Button className='font-inter fs-6 text-black border-0 px-md-4 py-2' style={{ background: "#C4E5F9" }} onClick={() => applyCoupon()}>Apply</Button>
                                    }
                                </div>
                                <p className='mx-md-4 font-inter' style={{ color: "red" }}>{couponCodeMessage}</p>
                                <div className='d-flex align-items-center gap-3 mt-3'>
                                    <h6 className='font-inter fs-5' style={{ whiteSpace: 'nowrap' }}>Bill To <span>(Optional)</span></h6><hr className='w-100' />
                                </div>
                                <div className='d-flex align-items-center justify-content-between mt-3'>
                                    <p className='font-inter fs-5'>Mobile</p>
                                    <TextField type='tel' placeholder={currentUser?.MobileNo}
                                        value={number} onChange={(e) => {
                                            setNumber(e.target.value);
                                            setDataLayerJSON((prevData) => ({
                                                ...prevData,
                                                mobile: e.target.value
                                            }))
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {loading &&
                    <Button disabled className='d-block mx-auto border-0 rounded-5 my-5 p-3 text-black' style={{ background: "#9FE770", cursor: 'no-drop' }}>Processing.... wait</Button>
                    ||
                    <StripeCheckout
                        token={Stripe_Payment}
                        stripeKey="pk_live_51KjhlAKti5B1FKZIDKf4UDgvU8Wug8B8zEJ2Tu2CRmil6w4wzGe9oAAX1uW1E0LFZMfur20TCubjuAWvtDRrXKxb00KYCoOflT"
                        // stripeKey="pk_test_51KjhlAKti5B1FKZIL5nmufAp52YBEsiQUhoJuReIKs4IT2jz153eKg7jvrqvbWVBsztYcxzafpIExixJOTOMneTU00UYdGiSDf"
                        name={currentUser?.Name}
                        description="Book your slot with UWC expert"
                        image="https://app.uwc.world/Content/adminlogo.png" // the pop-in header image (default none)
                        ComponentClass="div"
                        panelLabel="Pay" // prepended to the amount in the bottom pay button
                        amount={Math.round(mainAmountAfterCoupone ?? optionData?.price_usd) * 100} // cents
                        currency={"USD"}
                        email={currentUser?.Emailid}
                        locale="auto"
                        allowRememberMe
                    >
                        <Button className='d-block mx-auto border-0 rounded-5 my-5 p-3 text-black' style={{ background: "#9FE770" }} onClick={() => {
                            let StartJSON = dataLayerJSON
                            StartJSON.event = "begin_checkout"

                            window.dataLayer.push(StartJSON)
                        }}>Proceed To Payment</Button>
                    </StripeCheckout>
                }
            </div>
        </Container>
    )
}

export default SubscriptionOrderSummary