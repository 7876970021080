import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubscriptionPaymentFeedback } from './core/request';
import { Helmet } from 'react-helmet';

const ThankYou = () => {

    const [isDataAdded, setIsDataAdded] = useState(false)
    const navigate = useNavigate();
    const [experience, setExperience] = useState();

    // useEffect(() => {
    //     setTimeout(() => {
    //         navigate("/my-subscription")
    //     }, 5000);
    //     return () => clearTimeout()
    // }, []);

    // useEffect(() => {
    //     // Check if the data has already been added
    //     if (!isDataAdded) {
    //         const subscriptionDetails = sessionStorage.getItem("SubscriptionDetails");
    //         const parsedDetails = JSON.parse(subscriptionDetails);

    //         // Check if paymentSuccess is not null and not false
    //         if (parsedDetails.paymentSuccess !== null && parsedDetails.paymentSuccess !== false) {
    //             console.log("ADDING DATA LAYER");
    //             window.dataLayer = window.dataLayer || [];
    //             window.dataLayer.push(parsedDetails);
    //         }

    //         setIsDataAdded(true);
    //     }
    // }, []);

    const subscriptionID = JSON.parse(sessionStorage.getItem("subscriptionID"));
    const onclickThumbsup = () => {
        setExperience(2);

        var body = {
            "subscription_id": subscriptionID,
            "thumbs_up": 2
        }
        SubscriptionPaymentFeedback(body).then(resp => {
            if (resp.ResponseCode === 200) {
                alert("Thank You for Feedback.");
                navigate("/my-subscription");
            }
        })
    }
    const onclickThumbsdown = () => {
        setExperience(1);

        var body = {
            "subscription_id": subscriptionID,
            "thumbs_up": 1
        }
        SubscriptionPaymentFeedback(body).then(resp => {
            if (resp.ResponseCode === 200) {
                alert("Thank You for Feedback.");
                navigate("/my-subscription");
            }
        })
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center gap-4' style={{ backgroundImage: `url("/assets/img/Thank you.png")`, backgroundRepeat: "no-repeat", backgroundSize: "100vw 100vh" }}>
            <Helmet>
                <title>Payment Success - United We Care</title>
            </Helmet>
            <img src='/assets/img/Clip path group.png' alt='' />
            <h1 className='font-helvetica font-w700 text-center fs-1' style={{ color: "#541CF5", textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>Thank You!</h1>
            <h2 className='font-helvetica text-black text-center'>We are happy to help you.</h2>
            <p className='font-helvetica text-black text-center fs-4'>How was your payment experience? </p>
            <div className='d-flex align-items-center gap-3 justify-content-center mb-5'>
                <div onClick={onclickThumbsup} className='w-auto p2' style={{ borderRadius: "12.762px", border: experience === 2 ? "2px solid #FD6F1F" : "2.553px solid #E1E5EE", background: "rgba(225, 229, 238, 0.31)" }}>
                    <img src='/assets/img/thumbsup.png' alt='' />
                </div>
                <div onClick={onclickThumbsdown} className='w-auto' style={{ borderRadius: "12.762px", border: experience === 1 ? "2px solid #FD6F1F" : "2.553px solid #E1E5EE", background: "rgba(225, 229, 238, 0.31)" }}>
                    <img src='/assets/img/thumbsdown.png' alt='' />
                </div>
            </div>
        </div >
    )
}

export default ThankYou