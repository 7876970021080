const ComingSoon = () => {
  return (
    <div className="ComingSoon">
      <img src="/assets/img/coming-soon.png" width={100} />
      <h6>This is under development</h6>
      <p>
        We're hard at work building something awesome! Stay tuned for the launch
        of this exciting new feature.
      </p>
    </div>
  );
};

export default ComingSoon;
