import React from 'react'
import "./PlanDetailsPage.css"
import PlanDetailsLanding from '../components/planDetails/PlanDetailsLanding'
import PlanDetailsStats from '../components/planDetails/PlanDetailsStats'
import PlanDetailsReviewSlide from '../components/planDetails/PlanDetailsReviewSlide'
import ThreeImgBackground from '../components/planDetails/ThreeImgBackground'
import { WhyTrustUs } from '../components/planDetails/WhyTrustUs'
import WhyPlan from '../components/planDetails/WhyPlan'

const PlanDetailsPage = ({type}) => {
    //console.log("TYPE", type)
    return (
        <div>
            {/* Header */}
            <PlanDetailsLanding type={type} />

            {/* Stats */}
            <PlanDetailsStats />

            {/* Carousel Section */}
           <WhyPlan type={type} />

            {/* Trust */}
            <WhyTrustUs />

            {/* Affordable Healing */}
            <ThreeImgBackground />

            {/* Reviews */}
            <PlanDetailsReviewSlide />
        </div>
    )
}

export default PlanDetailsPage