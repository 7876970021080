import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import CourseTabItem from "../../tabs/clients/CourseTabItem";
import ScreenerTabItem from "../../tabs/clients/ScreenerTabItem";
import AppointmentTabItem from "../../tabs/clients/AppointmentTabItem";
import NoteTabItem from "../../tabs/clients/NoteTabItem";
import PrescriptionTabItem from "../../tabs/clients/PrescriptionTabItem";

import moment from "moment";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import TextField from "@mui/material/TextField";

//Loader spinner
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth } from "../../../../auth";
import jwtDecode from "jwt-decode";
import AddAppointmentModal from "../../modals/AddAppointmentModal";

import { useNavigate, useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ClientDetailsView = ({
  clientName,
  onScreenerView,
  clientID,
  clientDetails,
}) => {
  // console.log("clientName", clientName)
  // console.log("clientID", clientID)
  // console.log("clientDetails", clientDetails)
  const [selectedTab, setSelectedTab] = useState(0);
  const [clientViewDetails, setClientViewDetails] = useState({});
  const [checkClientID, setCheckClientID] = useState(clientID);
  const [clientAppointmentsList, setClientAppointmentsList] = useState([]);
  const [clientNotes, setClientNotes] = useState([]);
  const [clientPrescriptions, setClientPrescriptions] = useState([]);
  const [clientScreeners, setClientScreeners] = useState([]);
  const [clientScreenersQNA, setClientScreenersQNA] = useState([]);
  const [clientCourses, setClientCourses] = useState([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [chatClientList, setChatClientList] = useState([]);

  const [bearerToken, setBearerToken] = useState(localStorage.getItem("token"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isNoteAdded, setIsNoteAdded] = useState(null)

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  const getToken = async () => {
    const payload = {
      OTP: "7777",
      UserName: currentUser?.Emailid,
    };

    const otpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1231243123hjvjVJHVvjv",
      DeviceId: "us_web",
      DeviceType: "web",
      Lat: "us_lat",
      Long: "us_long",
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/Auth/LoginByOTP`,
        {
          method: "POST",
          headers: otpHeaders,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();

      const token = postResponse?.data;
      const value = jwtDecode(token);

      setBearerToken(value.access_token);

      localStorage.setItem("token", value.access_token);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setCheckClientID(clientID);
    setClientViewDetails(clientDetails);
  }, [clientID]);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  //Check if CLient ID
  const getClientID = () => {
    if (
      (checkClientID === "" || checkClientID === null || checkClientID === undefined) &&
      window.location.href.split("?")[1].includes("id")
    ) {
      const extractedClientID = window.location.href
        .split("?")[1]
        .split("id=")[1];
      setCheckClientID(extractedClientID);
    } else {
      setCheckClientID(clientID);
    }
  };

  useEffect(() => {
    getClientID();
  }, []);

  //Fetch Client Screeners
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    const postClientScreenersData = async () => {
      try {
        const screenerBody = {
          expert_id: currentUser?.UserId,
          user_id: checkClientID
          // user_id: currentUser?.UserId,
        }

        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(screenerBody),
          }
        );
        const postResponse = await response.json();

        const { assignments } = await postResponse.data;
        const { worksheets } = await postResponse.data;

        setClientScreeners(assignments);
        setClientScreenersQNA(worksheets)
        //setUserData(postResponse?.data);

        //console.log("data", postResponse);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientScreenersData();
  }, [checkClientID])


  //Fetch Client Appointments
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    const payload = {
      expert_id: currentUser?.UserId,
      user_id: Number(checkClientID),
    };

    const postClientAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { appointments } = await postResponse.data;

        setClientAppointmentsList(appointments);
        //setUserData(postResponse?.data);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientAppointmentsData();
  }, [checkClientID]);

  //Fetch Client Notes
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    const payload = {
      jsonrpc: "2.0",
      params: {
        creator_id: userID,
        assign_id: checkClientID,
      },
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/uwc_notes/list`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const token = await postResponse.data;
        const decodedToken = jwtDecode(token);

        setClientNotes(decodedToken?.notes);
        //setUserData(postResponse?.data);

        //console.log("data", decodedToken?.notes);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
  }, [checkClientID]);



  //Fetch Client Prescriptions
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    //console.log("userAppointmentItem", userAppointmentItem)

    const prescriptionHeaders = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
      deviceid: "123",
      devicetype: "web",
      lat: "1",
      long: "1",
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://app.uwc.world/api/appointment/GetAppointment_Documents_Files_Sent_Expert?AppointmentId=0&ToUserId=${checkClientID}`,
          {
            method: "GET",
            headers: prescriptionHeaders,
          }
        );
        const postResponse = await response.json();

        if (postResponse?.ResponseCode == 401) {
          getToken();
          postClientNotesData();
        }

        if (postResponse?.ResponseCode == 200) {
          const token = postResponse?.data;
          const decodedToken = jwtDecode(token);

          //const { prescriptions } = await postResponse.data;
          //console.log("userAppointmentID", userAppointmentID)
          //console.log("decodedToken", decodedToken);
          setClientPrescriptions(decodedToken);
        }

        //setUserData(postResponse?.data);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();

  }, [chatClientList]);



  //Fetch Client Courses
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }
    const payload = {
      userId: checkClientID,
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/community/course/active_courses`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { courses } = await postResponse.data;

        setClientCourses(courses);
        //setUserData(postResponse?.data);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
  }, [checkClientID]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewScreener = (title, id, type) => {
    //console.log("mid", id)
    onScreenerView(title, id, type);
  };

  //Notes
  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  //Send Note
  const handlePostNote = async () => {
    setIsNoteAdded(false)

    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: +clientDetails?.id,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse?.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Redirect to chat
  const handleChat = () => {
    navigate(`/expert/chat?clientID=${clientID}`)
  }


  //console.log("clientPrescriptions", clientPrescriptions)
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={8}>
          <div
            style={{
              background: "#fff",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              className="ClientDetails--Tabs"
            >
              <Tab label="Appointments" />
              {/* <Tab label="Worksheets" /> */}
              <Tab label="Notes" />
              <Tab label="Prescriptions" />
              <Tab label="Screeners" />
              <Tab label="Courses" />
            </Tabs>


            {/* Appointments Panel */}
            <CustomTabPanel value={selectedTab} index={0}>
              <div className="Tab--Panel Appointments--Panel">
                {/* Appointment */}
                {clientAppointmentsList?.map((item) => (
                  <AppointmentTabItem
                    key={item.ID}
                    status={item.Status}
                    date={item.AppDate}
                    time={item.AppTime}
                    duration={item.duration}
                  />
                ))}
              </div>
            </CustomTabPanel>

            {/* <CustomTabPanel value={selectedTab} index={1}>
            <div className="Tab--Panel Worksheets--Panel">
              <div className="WorksheetItem">
                <h6>Sleep Activity</h6>
                <span>Assigned on : 10 may 2023</span>
                <span>Weekly</span>
              </div>
            </div>
          </CustomTabPanel> */}

            {/* Notes Panel */}
            <CustomTabPanel value={selectedTab} index={1}>
              <div className="Tab--Panel Notes--Panel">
                {
                  clientNotes.length > 0 ? (
                    clientNotes?.map((note) => (
                      <NoteTabItem
                        key={note.id}
                        date={note.create_date}
                        name={note.name}
                        content={note.content}
                      />
                    ))
                  ) : (
                    <p style={{ fontWeight: '500', textAlign: 'center' }}>
                      There are no notes attached with {clientViewDetails?.name}.
                    </p>
                  )
                }
              </div>
            </CustomTabPanel>

            {/* Prescriptions panel */}
            <CustomTabPanel value={selectedTab} index={2}>
              <div className="Tab--Panel Prescriptions--Panel">
                {clientPrescriptions.length > 0 ? (
                  clientPrescriptions?.map((item) => (
                    <PrescriptionTabItem
                      key={item.Id}
                      title={item.Title}
                      note={item.Note}
                      date={item.CreatedDateTime}
                      linkedFile={item.DocsFile}
                    />
                  ))
                ) : (
                  <p style={{ fontWeight: '500', textAlign: 'center' }}>
                    There are no prescription notes attached with {clientViewDetails?.name}.
                  </p>
                )}
              </div>
            </CustomTabPanel>


            {/* Screeners Panel */}
            <CustomTabPanel value={selectedTab} index={3}>
              <div className="Tab--Panel Screeners--Panel">
                {
                  clientScreeners.length > 0 || clientScreenersQNA.length > 0 ? (
                    <>
                      {clientScreeners.map(item => (
                        <ScreenerTabItem
                          type="Options"
                          key={item.id}
                          id={item.id}
                          title={item.name}
                          submittedOn={item.score_date}
                          onViewClick={handleViewScreener}
                        />
                      ))}
                      {clientScreenersQNA.map(item => (
                        <ScreenerTabItem
                          type="QNA"
                          key={item.id}
                          id={item.id}
                          title={item.name}
                          submittedOn={item.score_date}
                          onViewClick={handleViewScreener}
                        />
                      ))}
                    </>
                  ) : (
                    <p style={{ fontWeight: '500', textAlign: 'center' }}>
                      {clientViewDetails?.name} has not taken any screeners. Please take this up in your session.
                    </p>
                  )
                }
              </div>
            </CustomTabPanel>


            {/* Courses Panel */}
            <CustomTabPanel value={selectedTab} index={4}>
              <div className="Tab--Panel Courses--Panel">
                {clientCourses?.map((item) => (
                  <CourseTabItem
                    key={item.id}
                    title={item.name}
                    status={item.status}
                    img={item.website_thumbnail_url}
                  />
                ))}
              </div>
            </CustomTabPanel>
          </div>
        </Grid>

        <Grid item xs={4}>
          <div
            className="ClientDetailsGrid--Right"
            style={{
              background: "#fff",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <img src={clientDetails.image} width={110} height={110} />
            <h6>{clientDetails.name}</h6>
            <span>{clientDetails.country}</span>

            <div className="d-flex align-items-center gap-2 ClientActions--Container">
              <Button onClick={handleChat}>
                <ChatOutlinedIcon />
                Chat
              </Button>

              <Button onClick={handleNotesOpen}>
                <TextSnippetOutlinedIcon />
                Add Notes
              </Button>

              {/*<Button>
              <MoreVertOutlinedIcon />
              More
            </Button> */}
            </div>

            <div className="d-flex align-items-center gap-2 CTA--Container">
              {/* <Button
                sx={{ flex: "1", minWidth: "0" }}
                variant="contained"
                className="PrimaryCTA"
                disableElevation
              >
                Book Appointment
              </Button> */}

              <AddAppointmentModal clientDetails={clientViewDetails} />
            </div>

            <span className="d-none" style={{ color: "#6A6C6A", fontSize: "14px" }}>
              Last Active :{" "}
              <b style={{ color: "#000" }}>{clientDetails.last_active}</b>
            </span>
          </div>
        </Grid>
      </Grid>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >

        {
          (isNoteAdded !== null && !isNoteAdded) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        }
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientDetails.image}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientDetails.name}</h6>
              {/* <span>
                {formattedDate} | {appointmentTime} | {clientType}
              </span> */}
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostNote}
            disabled={note == "" ? true : false}
          >
            Add Note
          </Button>
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClientDetailsView;
