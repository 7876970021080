import React from 'react'
import '.././Plans.css'

const PartnerArea = () => {
    return (
        <div>
            {/* <!------ partner-area ------> */}
            <div className="partner-area">
                <div className="container">
                    <div className="partner-inner">
                        <h2 className="heading text-lf">Our <span className="diff-clr"> Global</span> Partners</h2>
                        <div className="row">
                            <div className="col-lg-6 col-md-6  mobile-display-none desktop-display">
                                <img src="assets/img/img6.JPG" alt='' />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <img src="assets/img/img7.JPG" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerArea