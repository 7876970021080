import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown,
  Container,
  Offcanvas,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../auth";
import { SignInDrawer } from "./SignInDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import SearchIcon from '@mui/icons-material/Search';
import 'bootstrap/dist/css/bootstrap.min.css';

const searchPlaceholder = ["an Expert", "a course", "a blog"];

function Header2() {
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);
  const [searchText, setSearchText] = useState();

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";

  const headerSearch = (e) => {
    debugger
    setSearchText(e.target.value);
  }

  const onHeaderSearchIcon = () => {
    window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`
  }

  const handleEnterKeyDown = (e) => {
    debugger
    if (e.key === 'Enter') {
      window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`
    }
  }

  //const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const onLogout = () => {
    //console.log("logout")
    logout();
    navigate("/");
  };

  const togglePopup = (state) => {
    setMenuOpen(false);
    setShowLloginPopup(state);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const timer = () => {
      setChangingPlaceholder(prevIndex => {
        if (prevIndex === searchPlaceholder.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      })
    };
    setInterval(timer, 5000);

    //cleanup function in order clear the interval timer
    //when the component unmounts
    return () => { clearInterval(timer); }
  }, []);

  const handleClose = () => setMenuOpen(false);

  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(null);

  // useEffect(() => {
  //   if (
  //     previousLocation &&
  //     previousLocation.pathname == "/onboarding-stella" &&
  //     location.pathname !== "/onboarding-stella"
  //   ) {
  //     // Alert or perform any other action when the user navigates from the previous location
  //     //console.log("Kimochi!!")

  //     const headers = {
  //       "Content-Type": "application/json",
  //       "X-API-Key": stellaAPIKey,
  //     };

  //     const sessionID = sessionStorage.getItem("stella_s_id");

  //     const body = {
  //       senderId: sessionID,
  //     };

  //     const getStellaClose = async () => {
  //       try {
  //         //setIsLoading(true);

  //         const response = await fetch(
  //           `${baseURL}/new-uwc/stella/close/session`,
  //           {
  //             method: "POST",
  //             headers,
  //             body: JSON.stringify(body),
  //           }
  //         );

  //         if (!response.ok) {
  //           // Handle error response
  //           throw new Error("Error fetching languages");
  //         }

  //         const jsonData = await response.json();
  //         console.log("RESPONSE", response);

  //         if (response.status == 200) {
  //           console.log("CLEAR");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     getStellaClose();
  //   }

  //   // Save the current location as the previous location
  //   setPreviousLocation(location);
  // }, [location, previousLocation]);

  return (
    <div style={{ background: "#FFFFFF", boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.09)" }} className="header-container">
      <Navbar key={"sm"} expand={"sm"}>
        <div className="d-flex align-items-center justify-content-between col-12" style={{ padding: "5px 5%" }}>
          {/* logo Desktop */}
          <Navbar.Brand className="d-none d-md-block">
            <a
              href="https://www.unitedwecare.com/us"
              className="text-decoration-none"
            >
              <img src="/assets/img/Group(3).png" alt="" />
            </a>
          </Navbar.Brand>
          {/* logo Mobile */}
          <Navbar.Brand className="d-block d-md-none">
            <a
              href="https://www.unitedwecare.com/us"
              className="text-decoration-none"
            >
              <img src="/assets/img/Logo(2).png" alt="" />
            </a>
          </Navbar.Brand>

          {/* <Form className="d-flex d-md-none align-items-center ms-2 rounded-5 me-3" style={{ border: "0.5px solid #541CF5", height: "45px" }}>
            <InputGroup>
              <FormControl onChange={(e) => headerSearch(e)} onKeyDown={(e) => handleEnterKeyDown(e)} type="search" className="ms-2 border-0 headerSearch" style={{ borderRight: "none" }} placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`} />
              
              <SearchIcon fontSize="small" sx={{ color: "#541CF5", display: "block", marginY: "auto", marginRight: "10px" }} onClick={onHeaderSearchIcon} />
              
            </InputGroup>
          </Form> */}

          {/*  If User Login*/}
          {(currentUser && (
            <>
              <Navbar.Toggle
                aria-controls={"offcanvasNavbar-expand-sm"}
                onClick={toggleMenu}
              />
              <Navbar.Offcanvas
                id={"offcanvasNavbar-expand-sm"}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                placement="end"
                style={{ backgroundColor: "#fff" }}
                restoreFocus={false}
                show={menuOpen}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body className="justify-content-md-end">
                  <Nav className="align-items-baseline align-items-md-center">
                    {/* Mobile View Start */}
                    <Nav.Link
                      className="mx-2 d-block d-md-none text-decoration-none header-title lh-lg"
                      onClick={toggleMenu}
                      href="https://www.unitedwecare.com/about-us/"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header-blue"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">Professionals</NavDropdown.Item>
                      <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}

                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Centre"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header-blue"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">Blogs</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">Wellness Library</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/plans"
                        className="text-decoration-none header-title lh-lg"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Plans and Pricing
                      </Link>
                    </Nav.Link>

                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprise"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">USA</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">India</NavDropdown.Item>
                    </NavDropdown>
                    {/* Mobile View End */}

                    {/* Desktop View Start */}
                    <Nav.Link className="mx-2 d-none d-md-block text-decoration-none header-title lh-lg"
                      href="https://www.unitedwecare.com/about-us/"
                      style={{ whiteSpace: "nowrap" }}>
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg mx-2 d-none d-md-block text-black header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">Professionals</NavDropdown.Item>
                      <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Center"
                      className="font-inter text-decoration-none header-title lh-lg mx-2 d-none d-md-block text-black header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">Blogs</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">Wellness Library</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="mx-2 d-none d-md-block"
                    >
                      <Link
                        to="/plans"
                        className="text-decoration-none header-title lh-lg"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Plans and Pricing
                      </Link>
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprises"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">India</NavDropdown.Item>
                    </NavDropdown>
                    {/* Desktop View End */}
                    {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none header-title lh-lg'>Stella</Link></Nav.Link>)} */}
                    {(currentUser.UserType == userType.ENDUSER && (
                      <NavDropdown
                        title={
                          <>
                            Hi{" "}
                            {currentUser?.Name.split(" ").slice(0, 1).join("")}
                          </>
                        }
                        id={`offcanvasNavbarDropdown-expand-sm`}
                        className="rounded-5 ms-md-3 px-4 px-md-3 header-title-user py-1 toggleButton text-white"
                        style={{ backgroundColor: "#541CF5", color: "#fff !important" }}
                      >
                        {/* Mobile View Start */}
                        <NavDropdown.Item
                          className="d-block d-md-none"
                        >
                          <Nav.Link onClick={toggleMenu}>
                            <Link
                              to="/user-dashboard/my-appointments"
                              className="text-decoration-none"
                            >
                              My Appointments
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="d-block d-md-none"
                        >
                          <Nav.Link onClick={toggleMenu}>
                            <Link
                              to="/user-dashboard/course/"
                              className="text-decoration-none"
                            >
                              My Courses
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="d-block d-md-none"
                        >
                          <Nav.Link onClick={toggleMenu}>
                            <Link
                              to="/my-subscription"
                              className="text-decoration-none"
                            >
                              My Subscription
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <Nav.Link
                          className="d-block d-md-none"
                          onClick={toggleMenu}
                        >
                          <Button
                            className="bg-white border-0 rounded-4 w-100 text-start"
                            style={{ color: "#6384E3" }}
                            onClick={onLogout}
                          >
                            Logout
                          </Button>
                        </Nav.Link>
                        {/* Mobile View End */}
                        {/* Desktop View Start */}
                        <NavDropdown.Item
                          className="d-none d-md-block"
                        >
                          <Nav.Link>
                            <Link
                              to="/user-dashboard/my-appointments"
                              className="text-decoration-none"
                            >
                              My Appointments
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="#action/3.2"
                          className="d-none d-md-block"
                        >
                          <Nav.Link>
                            <Link
                              to="/user-dashboard/course/"
                              className="text-decoration-none"
                            >
                              My Courses
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="d-none d-md-block"
                        >
                          <Nav.Link>
                            <Link
                              to="/my-subscription"
                              className="text-decoration-none"
                            >
                              My Subscription
                            </Link>
                          </Nav.Link>
                        </NavDropdown.Item>
                        <Nav.Link href="#!" className="d-none d-md-block">
                          <Button
                            className="bg-white border-0 rounded-4 w-100 text-start"
                            style={{ color: "#6384E3" }}
                            onClick={onLogout}
                          >
                            Logout
                          </Button>
                        </Nav.Link>
                        {/* Desktop View End */}
                      </NavDropdown>
                    )) ||
                      (currentUser.UserType == userType.EXPERT && (
                        <NavDropdown
                          title={
                            <>
                              Hi{" "}
                              {currentUser?.Name.split(" ")
                                .slice(0, 1)
                                .join("")}
                            </>
                          }
                          id="basic-nav-dropdown"
                          className="rounded-3 ms-3 px-2 header-title-user"
                          style={{ backgroundColor: "#541CF5", color: "#fff !important" }}
                        >
                          {/* Mobile View Start */}
                          <NavDropdown.Item
                            className="d-block d-md-none"
                          >
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/user-dashboard/my-appointments"
                                className="text-decoration-none"
                              >
                                My Appointments
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            // href="#action/3.1"
                            className="d-block d-md-none"
                          >
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link
                            href="#!"
                            className="text-white d-block d-md-none"
                            onClick={toggleMenu}
                          >
                            <Button
                              className="bg-white border-0 rounded-4 w-100 mx-1 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                          {/* Mobile View End */}
                          {/* Desktop View Start */}
                          <NavDropdown.Item
                            href="#action/3.1"
                            className="d-none d-md-block"
                          >
                            <Nav.Link>
                              <Link
                                to="/user-dashboard/my-appointments"
                                className="text-decoration-none"
                              >
                                My Appointments
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className="d-none d-md-block"
                          >
                            <Nav.Link>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link
                            className="text-white d-none d-md-block"
                          >
                            <Button
                              className="bg-white border-0 rounded-4 w-100 mx-1 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                          {/* Desktop View End */}
                        </NavDropdown>
                      ))}
                  </Nav>
                  {/* <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> */}
                  <Form className="d-none d-md-flex align-items-center ms-2 rounded-5" style={{ border: "0.5px solid #541CF5", height: "45px" }}>
                    <InputGroup>
                      <FormControl onChange={(e) => headerSearch(e)} onKeyDown={(e) => handleEnterKeyDown(e)} type="search" className="ms-2 border-0 headerSearch" style={{ borderRight: "none" }} placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`} />
                      {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
                      <SearchIcon sx={{ color: "#541CF5", display: "block", marginY: "auto", marginRight: "10px" }} onClick={onHeaderSearchIcon} />
                      {/* </InputGroup.Text> */}
                    </InputGroup>
                  </Form>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </>
          )) || (
              <>
                <Navbar.Toggle
                  aria-controls={"offcanvasNavbar-expand-sm"}
                  onClick={toggleMenu}
                />
                <Navbar.Offcanvas
                  id={"offcanvasNavbar-expand-sm"}
                  aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                  placement="end"
                  style={{ backgroundColor: "#C4E5F9" }}
                  className="px-2"
                  restoreFocus={false}
                  show={menuOpen}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton></Offcanvas.Header>

                  <Nav className="align-items-baseline align-items-md-center justify-content-end">
                    {/* Mobile View Start */}
                    <Nav.Link
                      className="mx-2 d-block d-md-none text-decoration-none header-title lh-lg"
                      onClick={toggleMenu}
                      href="https://www.unitedwecare.com/about-us/"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">Professionals</NavDropdown.Item>
                      <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Centre"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">Blogs</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">Wellness Library</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/plans"
                        className="text-decoration-none header-title lh-lg"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Plans and Pricing
                      </Link>
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprise"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">USA</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">India</NavDropdown.Item>
                    </NavDropdown>
                    {/* Mobile View End */}

                    {/* Desktop View Start */}
                    <Nav.Link className="mx-2 d-none d-md-block text-decoration-none header-title lh-lg"
                      href="https://www.unitedwecare.com/about-us/"
                      style={{ whiteSpace: "nowrap" }}>
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">Professionals</NavDropdown.Item>
                      <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Center"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">Blogs</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">Wellness Library</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="mx-2 d-none d-md-block"
                    >
                      <Link
                        to="/plans"
                        className="text-decoration-none header-title lh-lg"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Plans and Pricing
                      </Link>
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprises"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">India</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link
                      className="mx-2 d-none d-md-block"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Stella
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className="mx-2 d-none d-md-block"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Enterprise
                      </Link>
                    </Nav.Link> */}

                    {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none header-title lh-lg'>Stella</Link></Nav.Link>)} */}

                    {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white"><Link className="text-decoration-none header-title lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                    <Nav.Link
                      onClick={() => {
                        togglePopup(true);
                      }}
                      className="text-white"
                    >
                      <Link className="text-decoration-none header-title lh-lg">
                        <Button
                          id="header_login"
                          className="text-white border-0 rounded-4 px-5 mx-2"
                          style={{ backgroundColor: "#541CF5" }}
                        >
                          Login
                        </Button>
                      </Link>
                    </Nav.Link>
                    {/* <div className="rounded-5  px-2" style={{ background: 'rgba(84, 28, 245, 0.06)', border: '0.25px #1B8BCF solid' }}>
                      <div className="col-12" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div><span style={{ color: '#1B8BCF', fontSize: 18, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>Search</span><span style={{ color: '#1B8BCF', fontSize: 14, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>..</span></div>
                        <div>
                          <img src="\assets\img\SVG - Search.png" alt="" />
                        </div>
                      </div>
                    </div> */}
                    <Form className="d-none d-md-flex align-items-center ms-2 rounded-5" style={{ border: "0.5px solid #541CF5", height: "45px" }}>
                      <InputGroup>
                        <FormControl onChange={(e) => headerSearch(e)} onKeyDown={(e) => handleEnterKeyDown(e)} type="search" className="ms-2 border-0 headerSearch" style={{ borderRight: "none" }} placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`} />
                        {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
                        <SearchIcon sx={{ color: "#541CF5", display: "block", marginY: "auto", marginRight: "10px" }} onClick={onHeaderSearchIcon} />
                        {/* </InputGroup.Text> */}
                      </InputGroup>
                    </Form>
                  </Nav>


                  <Modal
                    show={ShowLloginPopup}
                    onHide={() => togglePopup(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="m-0 col-4 signInModal"
                    style={{ height: "100vh" }}
                  >
                    <SignInDrawer />
                  </Modal>
                </Navbar.Offcanvas>
              </>
            )}
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center col-12">
          <Form className="d-flex d-md-none align-items-center justify-content-center rounded-2 col-11 my-2" style={{ border: "0.5px solid #541CF5", height: "45px" }}>
            <InputGroup>
              <FormControl onChange={(e) => headerSearch(e)} onKeyDown={(e) => handleEnterKeyDown(e)} type="search" className="ms-2 border-0 headerSearch" style={{ borderRight: "none" }} placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`} />
              {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
              <SearchIcon sx={{ color: "#541CF5", display: "block", marginY: "auto", marginRight: "10px" }} onClick={onHeaderSearchIcon} />
              {/* </InputGroup.Text> */}
            </InputGroup>
          </Form>
        </div>
      </Navbar>
    </div >
  );
}

export default Header2;
