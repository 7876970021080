import { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
} from "react-router-dom";
import DashboardHeader from "./component/DashboardHeader";

//Icons
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import LockIcon from "@mui/icons-material/Lock";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

const menus = [
  { name: "Dashboard", imageLink: "/assets/img/", link: "dashboard" },
  { name: "Appointments", imageLink: "/assets/img/", link: "appointments" },
  { name: "Clients", imageLink: "/assets/img/", link: "clients" },
  { name: "Chat", imageLink: "/assets/img/", link: "chat" },
  { name: "My Schedule", imageLink: "/assets/img/", link: "my-schedule" },
  { name: "Worksheets", imageLink: "/assets/img/", link: "worksheets" },
  { name: "Articles", imageLink: "/assets/img/", link: "articles" },
  { name: "Posts", imageLink: "/assets/img/", link: "posts" },
  { name: "Notifications", imageLink: "/assets/img/", link: "notifications" },
  { name: "Communities", imageLink: "/assets/img/", link: "communities" },
  { name: "Help Center", imageLink: "/assets/img/", link: "help-center" },
  { name: "Payouts", imageLink: "/assets/img/", link: "payouts" },
  { name: "Settings", imageLink: "/assets/img/", link: "settings" },
];

const Dashboard = () => {
  const [isActive, setIsActive] = useState("");
  const location = useLocation();
  const currentUrl = location.pathname;
  let currentPathName = currentUrl?.split("/")[currentUrl.split("/").length - 1];



  useEffect(() => {
    //console.log("currentUrl", currentPathName)

    let filteredData = menus.filter((item) => item.link == currentPathName);
    setIsActive(filteredData[0]?.name);
  }, [currentUrl]);
  const onClickMenu = (name) => {
    // debugger
    setIsActive(name);
  };
  return (
    <>
      <DashboardHeader />
      <Container
        fluid
        style={{ background: "#F5F5F5" }}
        className="ExpertDashboard"
      >
        <Row className="px-2">
          <Col md={2} className="px-0 ExpertDashboard--Col">
            <Nav className="flex-column gap-1 my-3 NavigationRail">
              {/* {isActive} */}
              {menus.map((menu, index) => (
                <Nav.Link
                  key={menu.name}
                  as={Link}
                  to={menu?.link}
                  onClick={() => onClickMenu(menu?.name)}
                  className="d-flex align-items-center gap-3"
                  style={{
                    background: isActive === menu?.name ? "black" : "",
                    borderRadius: "8px",
                    fontSize: "14px",
                  }}
                >
                  {menu?.name == "Dashboard" && (
                    <DashboardOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Appointments" && (
                    <EventAvailableOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Clients" && (
                    <GroupOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Chat" && (
                    <ForumOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "My Schedule" && (
                    <CalendarMonthOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Worksheets" && (
                    <EventAvailableOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Articles" && (
                    <EventAvailableOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Posts" && (
                    <SettingsOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Notifications" && (
                    <NotificationsOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Communities" && (
                    <HeadsetMicOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Help Center" && (
                    <HeadsetMicOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Payouts" && (
                    <PaymentOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}
                  {menu?.name == "Settings" && (
                    <ManageAccountsOutlinedIcon
                      sx={{ color: isActive === menu?.name ? "#FFF" : "#000" }}
                    />
                  )}

                  <p
                    className="font-inter"
                    style={{
                      color: isActive === menu?.name ? "white" : "black",
                    }}
                  >
                    {menu?.name}
                  </p>
                  {isActive === menu?.name &&
                    (menu.name == "Communities" || menu.name == "Posts" ? (
                      <LockIcon sx={{ color: "#FFF", marginLeft: "auto" }} />
                    ) : (
                      <ArrowRightAltOutlinedIcon
                        sx={{ color: "#FFF", marginLeft: "auto" }}
                      />
                    ))}
                </Nav.Link>
              ))}
            </Nav>
          </Col>

          {/* Content */}
          <Col md={10} className="mt-3 ExpertDashboard--Col">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
