import { useState, useEffect } from "react";

import Button from "@mui/material/Button";

//Table
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";

import moment from "moment";

const ViewWorksheetTableRow = ({
  id,
  name,
  status,
  img,
  country,
  assignedOn,
  completedOn,
  viewClick,
}) => {
  return (
    <TableRow
      id={id}
      className={`ClientDetails--TableRow`}
      sx={{
        backgroundColor: "#FFF",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell className="Table--Profile">
        <div className="d-flex align-items-center gap-2">
          <img
            src={img}
            width={45}
            height={45}
            style={{ borderRadius: "50%" }}
          />

          <div>
            <h6>{name}</h6>
            <span>{country}</span>
          </div>
        </div>
      </TableCell>

      <TableCell className="Table--AssignedOn">
        <div>
          <p>{moment(assignedOn).format("DD MMMM YYYY")}</p>
        </div>
      </TableCell>

      <TableCell className="Table--CompletedOn">
        {status == "Not Completed" ? (
          <p>Ongoing</p>
        ) : (
          <p>{moment(completedOn).format("DD MMMM YYYY")}</p>
        )}
      </TableCell>

      <TableCell className="Table--Progress">
        <p
          style={
            status == "Not Completed"
              ? {
                  backgroundColor: "#D9D9D9 !important",
                  color: "#414141 !important",
                }
              : {}
          }
        >
          {status}
        </p>
        {/* <span>+ Assign</span> */}
      </TableCell>

      <TableCell align="right">
        <div className="Table--Actions d-flex">
          <Button
            sx={{
              minWidth: "80px !important",
              width: "80px",
              maxWidth: "80px",
              marginLeft: "auto",
            }}
            onClick={() =>
              viewClick(id, name, status, img, country, assignedOn, completedOn)
            }
          >
            <VisibilityIcon />
            <p>View</p>
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ViewWorksheetTableRow;
