import { useState } from "react";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const ClientDetailsTableRow = ({
  id,
  image,
  name,
  country,
  notes,
  screener,
  sessions,
  type,
  worksheets,
  onClickCallback,
  onReschedule,
  onAddNote,
  onPrescription,
  onCancel,
}) => {
  const selectedRowClass = "SelectedTableRow";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = () => {
    if (onClickCallback) {
      onClickCallback(name, id, image, country, notes, screener, sessions, type, worksheets);
    }
  };

  const onChat = () => {
    navigate(`/expert/chat?clientID=${id}`)
  }

  const onCourseUnderTaken = () => {
    navigate(`/expert/clients?client=${name}&id=${id}`)
  }

  return (
    <TableRow
      className={`ClientDetails--TableRow`}
      sx={{
        backgroundColor: "#FFF",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>
        <img
          src={image}
          width={45}
          height={45}
          style={{ borderRadius: "50%", objectFit: "cover", objectPosition: "top" }}
        />
      </TableCell>
      <TableCell className="Table--Name">
        <div>
          <p>{name}</p>
          <span>{country}</span>
        </div>
      </TableCell>

      <TableCell className="Table--Sessions">
        <p>{sessions}</p>
      </TableCell>

      {/* <TableCell className="Table--Screener">
        <p>{screener}</p>
      </TableCell> */}
      <TableCell className="Table--SessionNotes">
        <p>{notes}</p>
      </TableCell>

      <TableCell className="Table--Worksheets">
        <p>{worksheets}</p>
        {/* <span>+ Assign</span> */}
      </TableCell>
      <TableCell align="right">
        <div className="Table--Actions d-flex">
          <Button onClick={handleRowClick}>
            <VisibilityIcon />
            <p>View</p>
          </Button>

          <Button onClick={onChat}>
            <ChatBubbleOutlineOutlinedIcon />
            <p>Chat</p>
          </Button>

          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <ArrowDropDownOutlinedIcon />
          </Button>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            className="DarkDropdown"
          >
            <MenuItem sx={{ fontSize: "14px" }} onClick={onAddNote}>
              Add Note
            </MenuItem>
            <Divider />

            {/* <MenuItem sx={{ fontSize: "14px" }}>Book Appointment</MenuItem>
                <Divider /> */}

            {/* <MenuItem sx={{ fontSize: "14px" }} onClick={onReschedule}>
              Reschedule
            </MenuItem> */}
            {/* <Divider />
            <MenuItem sx={{ fontSize: "14px" }} onClick={onPrescription}>
              Add Presciptions
            </MenuItem> */}
            <Divider />
            <MenuItem sx={{ fontSize: "14px" }} onClick={onCourseUnderTaken}>Courses Undertaken</MenuItem>

          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ClientDetailsTableRow;
