import { useState, useEffect } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Button from "@mui/material/Button";

//Grid
import Grid from "@mui/material/Grid";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

import CalendarViewAppointmentCard from "../cards/CalendarViewAppointmentCard";

import isUserLoggedIn from "../helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const localizer = momentLocalizer(moment);

const AppointmentCalendarView = () => {
  const [calendarView, setCalendarView] = useState("month");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [appointmentList, setAppointmentList] = useState([]);
  const [allAppointmentList, setAllAppointmentList] = useState([]);
  const [hasAppointmentData, setHasAppointmentData] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  let userID;
  const { currentUser, userType, logout } = useAuth();

  // useEffect(() => {
  //   // Check if user is logged in
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     // Redirect to "/login"
  //     navigate("/login");
  //   } else {
  //     userID = JSON.parse(localStorage.getItem("profile")).UserId;
  //   }
  // }, [location, navigate]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  //Appointments List
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
      date: activeDate,
    };

    const postAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        const { appointments } = postResponse?.data;

        setAppointmentList(appointments);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postAppointmentsData();
  }, [activeDate]);

  //All Appointments List
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId
    };

    const postAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        const { appointments } = postResponse?.data;

        setAllAppointmentList(appointments);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postAppointmentsData();
  }, []);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  const handleDateClick = (date) => {
    setActiveDate(date.format("YYYY-MM-DD"));
  };

  const handleCalendarView = (event) => {
    setCalendarView(event.target.value);
  };

  const currentMonth = moment().month(selectedMonth).format("MMMM");
  const startOfMonth = moment().month(selectedMonth).startOf("month");
  const endOfMonth = moment().month(selectedMonth).endOf("month");
  const startDate = startOfMonth.clone().startOf("week");
  const endDate = endOfMonth.clone().endOf("week");
  const today = moment();

  const dates = [];

  // Generate an array of dates from previous month, current month, and next month
  const currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate, "day")) {
    dates.push(currentDate.clone());
    currentDate.add(1, "day");
  }



  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //console.log("allAppointmentList", allAppointmentList)

  const events = allAppointmentList?.filter((appointment) => appointment.Status !== "Cancelled")?.map((appointment) => ({
    id: appointment.ID,
    title: appointment.Name,
    start: moment(
      `${appointment.AppDate} ${appointment.AppTime}`,
      "DD MMM YYYY hh:mm A"
    ).toDate(),
    end: moment(
      `${appointment.AppDate} ${appointment.AppTime}`,
      "DD MMM YYYY hh:mm A"
    )
      .add(appointment.duration.split(" ")[0], "minutes")
      .toDate(),
    joinUrl: appointment.join_url,
    status: appointment?.Status
  }));


  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.Status === 'Completed' ? 'green' : 'red',
    };
    return { style };
  };


  const EventComponentMonthly = ({ event }) => {
    return (
      <div className="d-flex align-items-center gap-1">
        <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#1a84c4' }}></div>
        <p>{event.title}</p>
      </div>
    );
  };

  const EventComponentWeekly = ({ event }) => {
    // console.log("event",event.status)
    return (
      <div>
        <p>{event.title}</p>
        <p>{moment(event.start).format("h:mm")} - {moment(event.end).format("h:mm a")}</p>
        {/* Add other event details as needed */}
      </div>
    );
  };

  const EventComponentDaily = ({ event }) => {
    return (
      <div className="daily-event">
        <p>{event.title}</p>
        <p>{moment(event.start).format("h:mm")} - {moment(event.end).format("h:mm a")}</p>
        {/* Add other event details as needed */}
      </div>
    );
  };


  const customEventPropGetter = (event, start, end, isSelected) => {
    return { className: event.Status === 'Completed' ? 'completed-event' : 'incomplete-event' };
  };

  const components = {
    month: {
      event: EventComponentMonthly,
    },
    week: {
      event: EventComponentWeekly,
    },
    day: {
      event: EventComponentDaily
    }
  };

  //Start from 6:00 AM
  const minTime = moment().format("YYYY-MM-DDTHH:mm:ss").replace(/[0-9]{2}:[0-9]{2}:[0-9]{2}/, "06:00:00");

  //End at 6:00 AM
  const maxTime = moment().format("YYYY-MM-DDTHH:mm:ss").replace(/[0-9]{2}:[0-9]{2}:[0-9]{2}/, "23:30:00");

  return (
    <div className="AppointmentCalendarView--Component">

      <div className="CalendarView--Content">

        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          step={30}
          views={{ month: true, week: true, day: true }}
          components={components}
          //min={minTime}
          //max={maxTime}
          style={{ height: 1000 }}
        />
      </div>

      {/* Monthly view */}

      {/* <div className="AppointmentCalendarView--Header">
        <div>
          <h6>{moment(activeDate, "YYYY-MM-DD").format("dddd D MMMM YYYY")}</h6>
          <span>Total {appointmentList.length} Appointments</span>
        </div>

        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            alignItems: "center",
            color: "#000",
            fontSize: "16px",
            fontWeight: "500",
            background: "#e7f6ff",
            borderRadius: "15px",
            height: "40px",
            width: "125px",
          }}
        >
          {currentMonth}
        </div>

      </div>
      <Grid container spacing={2} sx={{ marginLeft: 0, marginRight: 0 }}>
        <Grid item xs={5} className="Appointments--Container">
          {appointmentList?.map((item) => {
            return (
              <CalendarViewAppointmentCard
                id={item.ID}
                name={item.Name}
                image={item.ProfileImage}
                type={item.client_type}
                status={item.Status}
                sessionType={item.CallingMode}
                date={item.AppDate}
                day={item.day}
                time={item.AppTime}
              />
            );
          })}
        </Grid>
        <Grid item xs={7} className="Calendar--Section">
          {dates.map((date) => {
            const formattedDate = moment(date).format("DD MMM YYYY");
            const isActiveDate =
              moment(date).format("YYYY-MM-DD") === activeDate;
            const hasData = appointmentList?.some(
              (appointment) => appointment.AppDate === formattedDate
            );
            return (
              <Button
                key={date.format("D")}
                className={`date-button ${date.isSame(startOfMonth, "month") ? "" : "inactive"
                  } ${date.format("YYYY-MM-DD") === activeDate ? "isActive" : ""
                  }`}
                onClick={() => handleDateClick(date)}
              >
                <div className="day">{date.format("ddd")}</div>
                <div className="date">{date.format("D")}</div>
              </Button>
            );
          })}
        </Grid>
      </Grid> */}
    </div>
  );
};

export default AppointmentCalendarView;
