import { useState, useEffect } from "react";

import Button from "@mui/material/Button";

//Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewWorksheetTableRow from "../../tables/ViewWorksheetTableRow";

import jwtDecode from "jwt-decode";
import moment from "moment";

const ViewWorksheet = ({ worksheetID, viewClient, viewClicked }) => {
  const [clientsList, setClientsList] = useState([]);

 // console.log("worksheetID", worksheetID)

  const handleClient = (id, name, status, img, country, assignedOn, completedOn) => {
    //viewClient(name);
    viewClient(id, name, status, img, country, assignedOn, completedOn)
  };

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const getWorksheetData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/uwc_worksheet_data_template/list/${worksheetID}`,
          {
            method: "POST",
            headers,
          }
        );
        const postResponse = await response.json();
        const res = postResponse?.data;
        const data = jwtDecode(res);

        //console.log("DATA", data)
        setClientsList(data?.worksheet_list);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getWorksheetData();
  }, []);

  //console.log("clientsList", clientsList);

  return (
    <div>
      <TableContainer sx={{ marginTop: "20px", width: "100%" }}>
        <Table
          className="ViewWorksheet--Table"
          sx={{ minWidth: 650, width: "100%" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Profile</TableCell>
              <TableCell>Assigned On</TableCell>
              <TableCell>Completed On</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsList?.map((item) => (
              <ViewWorksheetTableRow
                key={item.worksheet_data_id}
                id={item.worksheet_data_id}
                name={item.user_name}
                status={item.state}
                img={item.image}
                country={item.country}
                assignedOn={item.assigned_date}
                completedOn={item.completed_date}
                viewClick={handleClient}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ViewWorksheet;
