import { Input } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../../../auth";
import { getAuth, signInAnonymously } from "firebase/auth";

//JWT
import jwtDecode from "jwt-decode";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, userType, logout } = useAuth();
  const [bearerToken, setBearerToken] = useState(sessionStorage.getItem("token"));

  const [anchorEl, setAnchorEl] = useState(null);

  const payload = {
    OTP: "7777",
    UserName: currentUser?.Emailid,
  };

  const otpHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer 1231243123hjvjVJHVvjv",
    DeviceId: "us_web",
    DeviceType: "web",
    Lat: "us_lat",
    Long: "us_long",
  };

  useEffect(() => {
    if (bearerToken == undefined || bearerToken == null || bearerToken == "") {
      let isMounted = true; // Flag to check if the component is still mounted

      const getToken = async () => {
        try {
          const response = await fetch(
            `https://app.uwc.world/api/Auth/LoginByOTP`,
            {
              method: "POST",
              headers: otpHeaders,
              body: JSON.stringify(payload),
            }
          );

          if (!isMounted) {
            // Component has been unmounted, no need to process the response
            return;
          }

          const postResponse = await response.json();

          const token = postResponse?.data;

          const value = jwtDecode(token);

          setBearerToken(value.access_token);
          sessionStorage.setItem("token", value.access_token);
          window.location.reload()
        } catch (error) {
          console.error("Error:", error);
        }
      };



      // Cleanup function to unsubscribe when the component is unmounted
      return () => {
        getToken();
        isMounted = false;
      };
    }
  }, [bearerToken]);

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }, [])

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    // Perform any profile-related actions here
    navigate("/expert/settings");
    handleDropdownClose();
  };

  const handleLogoutClick = () => {
    // Perform logout actions here
    logout();
    window.location.href = "https://www.unitedwecare.com/";
    handleDropdownClose();
  };

  return (
    <div style={{ background: "#1B8BCF" }}>
      <Navbar key={"sm"} expand={"sm"} style={{ height: "75px" }}>
        <Container fluid className="justify-content-between px-5">
          <div className="d-flex align-items-center gap-5">
            <Navbar.Brand className="d-none d-md-block">
              <a href="https://www.unitedwecare.com/us" target="_blank" className="text-decoration-none">
                <img src="/assets/img/group(1).png" alt="" />
              </a>
            </Navbar.Brand>
            <Navbar.Brand className="d-block d-md-none">
              <Link to="/" className="text-decoration-none">
                <img src="/assets/img/group(1).png" alt="" />
              </Link>
            </Navbar.Brand>
            {/* <div>
                            <img src='/assets/img/Mask group(22).png' alt='' />
                            <Input className='font-inter'
                                style={{ color: "white", whiteSpace: "nowrap", width: "400px" }}
                                defaultValue="Search Clients, Appointments, Worksheet etc..." inputProps='' />
                        </div> */}
          </div>
          <Nav className="gap-4 align-items-baseline align-items-md-center justify-content-end">
            <Link to="/expert/appointments">
              <img src="/assets/img/Mask group(19).png" alt="" />
            </Link>
            <Link to="/expert/chat">
              <img src="/assets/img/Mask group(20).png" alt="" />
            </Link>
            <Link to="/expert/notifications">
              <img src="/assets/img/Mask group(21).png" alt="" />
            </Link>
            <div>
              <Button onClick={handleDropdownOpen}>
                <img
                  src={currentUser?.ProfileImage}
                  width={45}
                  height={45}
                  alt=""
                  style={{ borderRadius: "50%", objectFit: "cover", objectPosition: 'top' }}
                />
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
              >
                <MenuItem onClick={handleProfileClick} sx={{ width: "200px" }}>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogoutClick} sx={{ width: "200px" }}>
                  Logout
                </MenuItem>
              </Menu>

              {/* Use Link component to navigate to "/dashboard/settings" */}
              {/* <Link to="/dashboard/settings">Go to Settings</Link> */}
            </div>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
