import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { Container } from 'react-bootstrap'
import { MySubscriptionService } from './core/_request';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import CollectionsIcon from '@mui/icons-material/Collections';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import { FormControl, TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import { useAuth } from '../../auth';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
// import '../../styles/dashboard/ExpertChatDashboard.scss'
// import '../../styles/dashboard/ExpertChatDashboard.css'

const UserDashboardChat = () => {
    const { id } = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        const auth = getAuth();
        signInAnonymously(auth)
            .then(() => {
                // Signed in..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
            });
        MySubscriptionService().then(resp => setData(resp?.data?.find(o => o.ID == id)));

        setChatData((prevData) => ({
            ...prevData,
            clientAppointmentId: id,
        }));
        fetchChatHistory(id)
        console.log("data", data)
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);
    const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [bearerToken, setBearerToken] = useState(
        sessionStorage.getItem("token")
    );
    const [numRetries, setNumRetries] = useState(0);
    const maxRetries = 3; // Set a maximum number of retries
    const [chatData, setChatData] = useState({
        chatID: 0,
        clientID: 0,
        clientName: "",
        clientProfilePicture: "",
        clientAppointmentId: 0,
        chatHistory: [],
    });
    const [sendChatValue, setSendChatValue] = useState("");
    const [groupedChats, setGrouped] = [];
    const [searchQuery, setSearchQuery] = useState("");

    const { currentUser, userType, logout } = useAuth();
    const chatHistoryRef = useRef(null);

    const scrollChatToBottom = () => {
        //console.log("chatHistoryRef", chatHistoryRef.current.scrollHeight)
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    };

    const navigate = useNavigate();
    const location = useLocation();

    // Check if user is logged in
    useEffect(() => {
        const isLoggedIn = currentUser;

        if (!isLoggedIn) {
            // Redirect to "/login"
            //navigate('/login');
            window.location.href = "/";
        }
    }, [location, navigate]);

    useEffect(() => {
        scrollChatToBottom();
    }, [chatData.chatHistory]);

    const headers = {
        "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
        "Content-Type": "application/json",
    };

    const getToken = async () => {
        const payload = {
            OTP: "7777",
            UserName: currentUser?.Emailid,
        };

        const otpHeaders = {
            "Content-Type": "application/json",
            Authorization: "Bearer 1231243123hjvjVJHVvjv",
            DeviceId: "us_web",
            DeviceType: "web",
            Lat: "us_lat",
            Long: "us_long",
        };

        try {
            const response = await fetch(
                `https://app.uwc.world/api/Auth/LoginByOTP`,
                {
                    method: "POST",
                    headers: otpHeaders,
                    body: JSON.stringify(payload),
                }
            );

            const postResponse = await response.json();

            const token = postResponse?.data;

            const value = jwtDecode(token);

            setBearerToken(value.access_token);
            sessionStorage.setItem("token", value.access_token);
            window.location.reload();
        } catch (error) {
            console.error("Error:", error);
        }
    };

    //fetch chat
    const fetchChatHistory = async (AppointmentId) => {
        const chatClientsHeaders = {
            "Content-Type": "application/json",
            deviceid: "123",
            devicetype: "web",
            lat: "1",
            long: "1",
            Authorization: `Bearer ${bearerToken}`,
        };
        try {
            const response = await fetch(
                `https://app.uwc.world/api/offline_chat/getOffline_chatByAppointmentId?Appointment_Id=${AppointmentId}`,
                {
                    method: "GET",
                    headers: chatClientsHeaders,
                }
            );
            const getResponse = await response.json();

            if (getResponse?.ResponseCode == 401 && numRetries < maxRetries) {
                //setNumRetries((prevNumRetries) => prevNumRetries + 1);
                await getToken();
                fetchChatHistory(id);
            }

            if (getResponse?.ResponseCode == 200) {
                const decodedData = jwtDecode(getResponse?.data);

                const sortedChatHistory = decodedData?.Messages?.sort((a, b) => {
                    return moment(a.timestamp, "DD/MMM/YYYY HH:mm").diff(
                        moment(b.timestamp, "DD/MMM/YYYY HH:mm")
                    );
                });
                //console.log("decodedData", decodedData)

                setChatData((prevData) => ({
                    ...prevData,
                    chatHistory: sortedChatHistory,
                }));
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    //Call API every 30 seconds
    useEffect(() => {
        let interval;
        // Call the API every 30 seconds using setInterval

        if (chatData?.clientAppointmentId !== 0) {
            interval = setInterval(() => {
                //console.log("CALLED")
                fetchChatHistory(chatData?.clientAppointmentId);
            }, 30000); // 30 seconds in milliseconds
        }

        // Clean up the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []);

    //post chat
    const postChat = async (fromID, toID, message, Appointment_Id) => {
        const chatClientsHeaders = {
            "Content-Type": "application/json",
            deviceid: "123",
            devicetype: "web",
            lat: "1",
            long: "1",
            Authorization: `Bearer ${bearerToken}`,
        };
        const chatBody = {
            to_id: data?.ExpertId,
            from_id: JSON.parse(localStorage.getItem("profile"))?.UserId,
            message,
            Appointment_Id: data?.ID,
        };
        try {
            const response = await fetch(
                `https://app.uwc.world/api/offline_chat/Add`,
                {
                    method: "POST",
                    headers: chatClientsHeaders,
                    body: JSON.stringify(chatBody),
                }
            );
            const getResponse = await response.json();

            if (getResponse?.ResponseCode == 401) {
                getToken();
                postChat(fromID, toID, message, Appointment_Id);
            }

            if (getResponse?.ResponseCode == 200) {
                //Do nothing
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    //Fetch Clients
    useEffect(() => {
        const chatClientsHeaders = {
            "Content-Type": "application/json",
            DeviceId:
                "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
            DeviceType: "ios",
            Lat: "1",
            Long: "1",
            Authorization: `Bearer ${bearerToken}`,
        };

        const postClientsData = async () => {
            try {
                const response = await fetch(
                    `https://app.uwc.world/api/transaction/getMyAspirantsForExpert`,
                    {
                        method: "GET",
                        headers: chatClientsHeaders,
                    }
                );
                const postResponse = await response.json();

                //console.log("postResponse", postResponse)

                if (postResponse?.ResponseCode == 401) {
                    getToken();
                    postClientsData();
                }

                if (postResponse?.ResponseCode == 200) {
                    setClientList(jwtDecode(postResponse?.data));
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };
        postClientsData();
    }, []);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setShowAttachmentPreview(true);
    };

    const handleRemoveAttachment = () => {
        setSelectedFile(null);
        setShowAttachmentPreview(false);
    };

    const handleClientCicked = (item) => {
        setChatData((prevData) => ({
            ...prevData,
            clientID: item.Id,
            clientName: item.Name,
            clientProfilePicture: item.ProfileImage,
            clientAppointmentId: item.AppointmentId,
            chatHistory: [],
        }));

        fetchChatHistory(item.AppointmentId);
        //scrollChatToBottom();
    };

    const handleSendChat = () => {
        const chatObj = {
            Appointment_Id: chatData?.clientAppointmentId,
            from_id: currentUser?.UserId,
            message: sendChatValue,
            timestamp: moment().format("DD/MMM/YYYY HH:mm"),
            to_id: chatData?.clientID,
        };

        //console.log("chatObj", chatObj)
        setChatData((prevData) => ({
            ...prevData,
            chatHistory: [...prevData.chatHistory, chatObj],
        }));

        setSendChatValue("");
        postChat(
            chatObj.from_id,
            chatObj.to_id,
            chatObj.message,
            chatObj.Appointment_Id
        );
    };

    const handleTextChange = (e) => {
        setSendChatValue(e.target.value);
    };

    //Search Functionality
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredClients = clientList.filter((item) => {
        return item.Name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (
        <Container className='rounded-3 my-3' style={{ transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px" }}>
            <div className='d-flex align-items-center justify-content-between border-bottom' style={{ height: "10vh" }}>
                <div className='d-flex align-items-center gap-3 py-3'>
                    <img src={data?.ProfileImage} className='rounded-circle' height={50} width={50} alt='' />
                    <div>
                        <p className='font-inter fontw-500 fs-5'>{data?.Name}</p>
                    </div>
                </div>
                {/* <div className='d-flex gap-3'>
                    <Link><CallIcon style={{ color: "#919EAB" }} /></Link>
                    <Link><MoreVertSharpIcon style={{ color: "#919EAB" }} /></Link>
                </div> */}
            </div>
            <div className='border-bottom' style={{ minHeight: "60vh" }}>
                {/* {chatData.clientID !== 0 && ( */}
                <>
                    <div
                        className="ChatSection--Chats py-2 px-3"
                        ref={chatHistoryRef}
                    >
                        {/* {chatData?.chatHistory?.map((chat) => (
                    <div
                      className={`${
                        chat.from_id == currentUser?.UserId
                          ? "sentChat"
                          : "receivedChat"
                      } d-flex align-items-start gap-3`}
                    >
                      <img
                        src={"https://picsum.photos/200/200"}
                        width={45}
                        height={45}
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                      /> 

                      <div className="messagesArea">
                        <div>{chat.message}</div>
                      </div>
                    </div>
                  ))} */}

                        {chatData?.chatHistory
                            ?.sort((a, b) => a.ID - b.ID)
                            ?.reduce((acc, chat) => {
                                const isCurrentUser =
                                    chat.from_id === currentUser?.UserId;
                                const prevChat = acc[acc.length - 1];

                                if (
                                    prevChat &&
                                    prevChat.isCurrentUser === isCurrentUser
                                ) {
                                    // If the previous chat type is the same as the current chat type,
                                    // add the current message to the previous chat's messages array
                                    prevChat.messages.push(chat.message);
                                } else {
                                    // If the previous chat type is different from the current chat type,
                                    // add a new chat message object to the accumulator
                                    acc.push({
                                        isCurrentUser,
                                        messages: [chat.message],
                                    });
                                }

                                return acc;
                            }, [])
                            .map((chat, index) => (
                                <div
                                    className={`${chat.isCurrentUser ? "sentChat" : "receivedChat"
                                        } d-flex align-items-start gap-3`}
                                    key={index}
                                    style={{ justifyContent: chat.isCurrentUser ? "end" : "start", flexDirection: chat.isCurrentUser ? "row-reverse" : "row", textAlign: chat.isCurrentUser ? 'right' : 'left' }}
                                >
                                    <img
                                        src={
                                            chat.isCurrentUser
                                                ? currentUser?.ProfileImage
                                                : chatData?.clientProfilePicture
                                        }
                                        width={45}
                                        height={45}
                                        style={{
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                            objectPosition: "top",
                                        }}
                                        alt=''
                                    />

                                    <div className="messagesArea mt-2">
                                        {chat.messages.map((message, messageIndex) => (
                                            <div key={messageIndex}>{message}</div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                    </div>


                </>
                {/* )} */}
            </div>
            <div className="SendText--Container p-3">
                <FormControl
                    sx={{ width: "100%" }}
                    className="rounded-3 TextBox--Container"
                    variant="outlined"
                >
                    {selectedFile && (
                        <div className="SelectedFile">
                            <AttachFileIcon
                                sx={{ fill: "#1B8BCF", transform: "rotate(45deg)" }}
                            />
                            <div>
                                <p>{selectedFile.name}</p>
                                <span>{`${(selectedFile.size / 1024).toFixed(
                                    2
                                )} KB`}</span>
                            </div>

                            <IconButton
                                onClick={handleRemoveAttachment}
                                aria-label="remove"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    )}

                    <OutlinedInput
                        className="TextBox"
                        id="outlined-adornment-weight"
                        sx={{ background: "#FFF", borderRadius: "10px" }}
                        value={sendChatValue}
                        onChange={handleTextChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <>
                                    <IconButton
                                        aria-label="send"
                                        onClick={handleSendChat}
                                    >
                                        <SendIcon />
                                    </IconButton>

                                    {/* <IconButton aria-label="attach">
                              <label htmlFor="file-upload">
                                <AttachFileIcon />
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileSelect}
                              />
                            </IconButton> */}
                                </>
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Type you message here..."
                    />
                </FormControl>
            </div>
        </Container>
    )
}

export default UserDashboardChat