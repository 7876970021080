import React, { useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { getCourseDetail_WebService, getDashboardCourseList_WebService } from '../core/_request';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import { courseData } from './core/models';
import { Helmet } from 'react-helmet';

const DashboardMyCoursesTab = () => {
    const navigate = useNavigate();
    const [myCourseData, setMyCourseData] = useState();
    const [data, setData] = useState();
    const { currentUser } = useAuth();

    useEffect(() => {
        const body = { "userId": currentUser.UserId }

        getDashboardCourseList_WebService(body).then(resp => {
            // console.log("resp Data", resp.data)
            setMyCourseData(resp.data);
        });
    }, [])

    const onClick = (item) => {
        // console.log("item", item)
        const body = { "courseId": item.course_id, "userId": currentUser?.UserId }
        // console.log("req", body)

        var tcourseData = courseData.find(o => o.id == item.course_id);

        getCourseDetail_WebService(body).then(resp => {
            console.log("resp09", resp)
            if (resp?.Data && resp?.Data && resp?.Data?.type == "detail" && tcourseData) {
                navigate(`/user-dashboard/course/details/${tcourseData?.urlTitle}`);
            }
            else if (resp?.Data && resp?.Data && resp?.Data?.type == "options") {
                navigate(`/user-dashboard/course/course-questions/${tcourseData?.id}`);
            }
            else if (resp?.Data && resp?.Data && resp?.Data?.type == "consultation" && tcourseData) {
                navigate(`/book-an-appointment/${tcourseData?.urlTitle}`);
            }
            else if (resp?.Data && resp?.Data && resp?.Data?.type == "payment" && tcourseData) {
                navigate(`/course/details/${tcourseData?.urlTitle}`);
            }
            else if (resp?.Data && resp?.Data && resp?.Data?.type == "dashboard") {
                navigate("/user-dashboard/course");
            }
            else if (resp?.Data && resp?.Data && resp?.Data?.type == "course" && tcourseData) {
                // console.log("resp?.Data?.data?.type", resp?.Data?.data?.type)
                navigate(`/user-dashboard/course/course-preview/${tcourseData?.urlTitle}`);
            }
            //setCourseDetailData(resp.Data);
            // console.log("respData", resp.Data)

        });

    }


    return (
        <div>
            <Helmet>
                <title>My Courses - United We Care</title>
            </Helmet>
            <div className='d-none d-md-flex align-items-center gap-3 py-4 px-5' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25", borderBottom: "2px solid #FF9C00" }}><img src='/assets/img/vector(11).png' alt='' />
                <p className='font-w500'>My Courses</p>
            </div>
            {myCourseData?.courses?.map((item) => (
                // console.log("item", item)||
                <div className='row align-items-center px-md-5 py-3 gap-3 gap-md-0 mx-1' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                    <p className='col-md-5 d-flex justify-content-between align-items-center'>
                        <button onClick={() => onClick(item)} style={{ border: "none", background: "none" }}>
                            {/* Healing With Meditation */}
                            {item?.name}
                        </button>
                        <button onClick={() => onClick(item)} className='d-block d-md-none' style={{ border: "none", background: "none" }}>
                            <Badge bg='success' text='light' className='py-2 rounded-5'>
                                {/* Assigned */}
                                {item?.status}
                            </Badge>
                        </button>
                    </p>
                    <p className='col-md-5'>Start Date : <span>
                        {item?.start_date}
                    </span></p>
                    <div className='col-md-2 d-none d-md-block'>
                        <button onClick={() => onClick(item)} style={{ border: "none", background: "none" }}>
                            <Badge bg='success' text='light' className='py-2 rounded-5'>
                                {/* Assigned */}
                                {item?.status}
                            </Badge>
                        </button>
                    </div>
                </div>
            ))
            }
            {/* <div className='d-flex justify-content-between px-5 py-3' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                <p>Healing With Meditation</p>
                <div className='d-flex align-items-center gap-4'><p>Due Date :</p> <p>23 Jun’22</p></div>
                <div className='d-flex align-items-center gap-4'>
                    <Badge bg='warning' text='dark' className='py-2 rounded-5'>Assigned</Badge>
                </div>
            </div>
            <div className='d-flex justify-content-between px-5 py-3' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                <p>Healing With Meditation</p>
                <div className='d-flex align-items-center gap-4'><p>Due Date :</p> <p>23 Jun’22</p></div>
                <div className='d-flex align-items-center gap-4'>
                    <Badge bg='warning' text='dark' className='py-2 rounded-5'>Assigned</Badge>
                </div>
            </div> */}
        </div>
    )
}

export default DashboardMyCoursesTab