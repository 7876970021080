import React from 'react'
import '.././Plans.css'

const HeroArea = () => {
    return (
        <div>
            {/* <!------ Hero-area (desktop-display)------> */}
            <div className="hero-area mobile-display-none desktop-display">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-xs-6">
                            <div className="hero-text">
                                <h1>Find a <span className="diff-clr">plan</span> that’s<br /> right for you </h1>
                                <h4>Plans Starting From <span className="diff-clr">$14.99/month</span></h4>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-xs-6">
                                        <div className="icon-area">
                                            <div className="icon-bg">
                                                <img src="assets/img/Edit Property.png" />
                                            </div>
                                            <h5>Created by you</h5>
                                            <p>Pick and choose services you need</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-xs-6">
                                        <div className="icon-area">
                                            <div className="icon-bg">
                                                <img src="assets/img/Heart.png" />
                                            </div>
                                            <h5>Always with you</h5>
                                            <p>An AI health assistance to guide you 24*7</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-xs-6">
                                        <div className="icon-area">
                                            <div className="icon-bg">
                                                <img src="assets/img/Tune.png" />
                                            </div>
                                            <h5>Flexible for you</h5>
                                            <p>Evolves with your needs and choices</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="Subscribe-btn">
                                    <button type="button" className="btn"><a href="#Subscribe_Now">Subscribe Now</a></button>
                                </div>
                                <p className="btm-text">Trusted By Over <span className="diff-clr">300K+</span> Subscribers</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-xs-6">
                            <div className="hero-img">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="img1">
                                            <img src="assets/img/img1.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8">
                                        <div className="img2">
                                            <img src="assets/img/img2.jpg" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3">
                                        <div className="img3">
                                            <img src="assets/img/img3.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="img4">
                                            <img src="assets/img/img4.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="img5">
                                            <img src="assets/img/img5.jpg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whats-app">
                    <a href="#"><img src="assets/img/wp.png" /></a>
                </div>
            </div>

            {/* <!------ Hero-area (mobile-display)------> */}
            <div className="hero-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="hero-text">
                        <h1 className="mobile-display desktop-display-none text-center">Find a <span className="diff-clr">plan</span> that’s right for you </h1>
                        <h4>Plans Starting From <span className="diff-clr">$14.99/month</span></h4>
                        <div className="Subscribe-btn">
                            <button type="button" className="btn"><a href="#Subscribe_Now">Subscribe Now</a></button>
                        </div>
                        <p className="btm-text">Trusted By Over <span className="diff-clr-m">300K+</span> Subscribers</p>
                        <div className="hero-img">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-6">
                                    <div className="img1">
                                        <img src="assets/img/img1.jpg" alt='' />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-6">
                                    <div className="img2">
                                        <img src="assets/img/img2.jpg" alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-4">
                                    <div className="img3">
                                        <img src="assets/img/img3.jpg" alt='' />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-5">
                                    <div className="img4">
                                        <img src="assets/img/img4.jpg" alt='' />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <div className="img5">
                                        <img src="assets/img/img5.jpg" alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gap-3">
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="icon-area">
                                    <div className="icon-bg">
                                        <img src="assets/img/Edit Property.png" alt='' />
                                    </div>
                                    <h5>Created by you</h5>
                                    <p>Pick and choose services you need</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="icon-area">
                                    <div className="icon-bg">
                                        <img src="assets/img/Heart.png" alt='' />
                                    </div>
                                    <h5>Always with you</h5>
                                    <p>An AI health assistance to guide you 24*7</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 pb-3">
                                <div className="icon-area">
                                    <div className="icon-bg">
                                        <img src="assets/img/Tune.png" alt='' />
                                    </div>
                                    <h5>Flexible for you</h5>
                                    <p>Evolves with your needs and choices</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroArea