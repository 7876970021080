import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Grid from "@mui/material/Grid";
import CalendarDayCard from "../cards/CalendarDayCard";
import CalendarAppointmentCard from "../cards/CalendarAppointmentCard";

import Button from "@mui/material/Button";
import moment from "moment";
import CalendarViewAppointmentCard from "../cards/CalendarViewAppointmentCard";

import isUserLoggedIn from "../helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth";

const getDatesArray = () => {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + i
    );
    dates.push(date);
  }

  return dates;
};

const CalendarDays = () => {
  const [month, setMonth] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [appointmentList, setAppointmentList] = useState([]);

  const currentMonth = moment().month(selectedMonth).format("MMMM");
  const datesArray = getDatesArray();
  const [hasAppointmentData, setHasAppointmentData] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, userType, logout } = useAuth();

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  //Appointments List
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
      date: activeDate,
    };

    const postAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        const { appointments } = postResponse?.data;

        setAppointmentList(appointments);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postAppointmentsData();
  }, [activeDate]);

  const handleDateClick = (date) => {
    setActiveDate(moment(date).format("YYYY-MM-DD"));
  };


  return (
    <div
      className="CalendarWidget Widget rounded-4"
      style={{ background: "#FFFFFF" }}
    >
      <div className="d-flex justify-content-between p-3 align-items-center Widget--Header">
        <h6 className="font-inter fs-5">Calendar</h6>

        <div
          style={{
            paddingLeft: "10px",
            display: "flex",
            alignItems: "center",
            color: "#000",
            fontSize: "16px",
            fontWeight: "500",
            background: "#e7f6ff",
            borderRadius: "15px",
            height: "40px",
            width: "125px",
            textAlign: "center",
          }}
        >
          {selectedMonth}
        </div>
      </div>

      <div className="CalendarWidget--Content">
        <div className="Content--Days ">
          <div className="Days--Container">
            {datesArray?.map((item) => {
              const isActiveDate =
                moment(item).format("YYYY-MM-DD") === activeDate;
              const hasData = appointmentList?.some(
                (appointment) => appointment.AppDate === item
              );

              return (
                <Button
                  onClick={() => handleDateClick(item)}
                  className={`Day--Item ${isActiveDate ? "SelectedDay" : ""}`}
                >
                  <span>{moment(item).format("ddd")}</span>
                  <p>{moment(item).format("D")}</p>
                  {hasData && <div className="hasAppointment"></div>}
                </Button>
              );
            })}
          </div>
        </div>

        <div className="Appointments--Container px-2 pt-3 pb-5">
          {appointmentList?.map((item) => {
            return (
              <CalendarViewAppointmentCard
                id={item.ID}
                name={item.Name}
                image={item.ProfileImage}
                type={item.client_type}
                status={item.Status}
                sessionType={item.CallingMode}
                date={item.AppDate}
                day={item.day}
                time={item.AppTime}
              />
            );
          })}
          {appointmentList.length == 0 && (
            <p style={{ textAlign: "center" }}>
              No appointment on{" "}
              {moment(activeDate, "YYYY-MM-DD").format("DD MMM YYYY")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarDays;
