import React, { useEffect, useState } from 'react'
import DetailPageCard from './DetailPageCard'
import DetailPageTabs from './DetailPageTabs'
import { getExpertbyId_WebService } from './core/_request';
import { getCountryCode } from './core/commonFun';
import { Helmet } from 'react-helmet';

const ExpertDetails = (props) => {
    const [data, setData] = useState();
    const countryCode = getCountryCode();

    useEffect(() => {
        const { id } = props;
        // console.log("id", id)
        getExpertbyId_WebService(id).then(resp => {
            var data = resp.data;
            if (countryCode == "IN") data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
            else data["countryAmount"] = data.USD_Amount;
            setData(data);
            //console.log(resp.data)
        });
    }, [])

    return (
        data &&
        <div>
            <Helmet>
                <title>
                    {data?.Name + ":" + " Professional " + data?.RegisterYourselfAs + " - United We Care"}
                </title>
            </Helmet>
            <div className='' style={{ backgroundSize: "100% 300px", backgroundRepeat: "no-repeat", backgroundImage: `url("/assets/img/Rectangle 2187.png")` }}>
                <div className='container px-0 py-md-3'>
                    <p className='fs-2 text-black text-center pb-5 py-4 px-3 font-w600 lh-base font-inter d-none d-md-block'>{data?.RegisterYourselfAs}</p>
                    <div className='row col-12 justify-content-between align-content-center align-items-md-start w-100 mx-0 position-relative'>
                        <div className='col-md-4 my-1 mx-auto px-0 px-md-2'>
                            <DetailPageCard data={data} />
                        </div>
                        <div className='col-md-8 p-0 m-0 my-1'>
                            <DetailPageTabs data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
        ||
        <div>
            <div className='' style={{ background: "linear-gradient(180deg, #E7F6FF 0%, #e7f6ff 100%);", padding: 0, margin: 0 }}>
                <div className='container py-5'>
                    <p className='fs-3 text-white mt-5 mb-3'>Waiting...</p>
                    <div className='row col-12'>
                        <center>
                            <div><img src="/assets/img/loading.gif" alt="Unitedwecare" style={{ height: 26, width: 26, margin: 17, cursor: "pointer" }} /></div>
                        </center>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ExpertDetails