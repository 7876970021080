import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe'
import { Button, Col, Collapse, Container, Nav, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { courseData } from '../core/models'
import { getCountryCode, getCurrencyCode } from '../../core/commonFun'
import 'swiper/css';
import "swiper/css/navigation";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext';
import { OODOCoursePaymentService, course_clubs, get_course_details_web_by_Id } from '../../core/_request';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import { useAuth } from '../../../auth';
import { Helmet } from 'react-helmet';

const CourseDetailID1 = (props) => {
    const { id } = useParams();
    const data = courseData?.find(o => o.id == id)
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();
    const [readMoreDescriptionText, setReadMoreDescriptionText] = useState();
    const [readMoreWhyTakeCourseText, setReadMoreWhyTakeCourseText] = useState(); const [sectionRefs, setSectionRefs] = useState([]);
    const [structurEventKey, setStructurEventKey] = useState(0);
    const [navIndex, setNavIndex] = useState(1);
    const [course_details, setCourse_details] = useState();
    const navigate = useNavigate();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const [headerClubsData, setHeaderClubsData] = useState([]);
    const { currentUser } = useAuth();
    // console.log("languages", course_details?.languages);
    useEffect(() => {
        var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
        var body = { userId: user_id }
        get_course_details_web_by_Id(id).then(resp => {
            setCourse_details(resp?.data)
        });

        setSectionRefs(
            Array.from(document.querySelectorAll("section")).map((ref) => {
                return { id: ref.id, top: ref.offsetTop };
            })
        );

        // course_clubs().then(resp => {
        //     setHeaderClubsData(resp?.data?.clubs);
        //     console.log("setCourse_details", resp?.data?.clubs)
        // });
    }, []);

    const onEnrollNow = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `course/details/${id}`)
            setShowLloginPopup(true);
        }
        else {
            if (course_details?.course_type === "free") {
                debugger
                var requestt = { userId: currentUser?.UserId, courseId: data?.id };
                OODOCoursePaymentService(requestt).then(_ => navigate("/user-dashboard/course/my-courses"));;
            }
            else if (course_details?.course_type === "premium") {
                if (course_details?.inr_price === 0) {
                    var requestt = { userId: currentUser?.UserId, courseId: data?.id };
                    OODOCoursePaymentService(requestt).then(_ => navigate("/user-dashboard/course/my-courses"));
                } else if (course_details?.inr_price > 0) {
                    localStorage.setItem("retrunurl", `course/details/${id}`)
                    navigate('/plans');
                }
            }
        }
    }


    // const handleIndexClick = (index) => {
    //     const section = sectionRefs[index];
    //     document.getElementById(section.id).scrollIntoView({ behavior: "smooth" });
    // };



    return (
        <div>
            <Helmet>
                <title>{course_details?.name + " - United We Care"}</title>
            </Helmet>
            <Container>
                <div className='row p-md-3'>
                    <div className='col-md-6 d-flex flex-column justify-content-start'>
                        <div className='d-flex align-items-center justify-content-between px-2 my-2 rounded-2'>
                            <div className='row align-items-center gap-1'>
                                <p className='font-inter w-auto rounded-2' style={{ fontSize: "14px", color: "#0E0F0C", background: "#EEF9FF" }}>
                                    {course_details?.club?.name}
                                </p>
                            </div>
                        </div>
                        <h1 className='font-inter fs-5 pb-2 fs-3 font-w600 my-md-2 lh'>
                            {course_details?.name}
                        </h1>
                        <div className='col-md-6 d-block d-md-none'>
                            <Iframe
                                src={course_details?.video?.video?.player_embed_url}
                                width='100%'
                                height='230px'
                                className='border-2'
                                // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
                                styles={{ borderRadius: "10px" }}>
                            </Iframe>
                        </div>
                        <div className='d-flex gap-3 py-2'>
                            <div className='d-flex gap-2 align-items-center justify-content-start'>
                                <img src='/assets/img/Mask group(10).png' alt='' />
                                <p className='font-inter font-w500 fs-6' style={{ color: "#0E0F0C" }}>{course_details?.duration}</p>
                            </div>
                            <div className='d-flex gap-2 align-items-center justify-content-start'>
                                <img src='/assets/img/image 14(1).png' alt='' />
                                <p className='font-inter font-w500 fs-6' style={{ color: "#0E0F0C" }}>{course_details?.rating}</p>
                            </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center justify-content-start pb-2'>
                            <img src='/assets/img/Mask group(11).png' alt='' />
                            <p className='font-inter font-w500 fs-6 lh-lg' style={{ color: "#0E0F0C" }}>{course_details?.languages}</p>
                        </div>
                    </div>
                    <div className='col-md-6 d-none d-md-block'>
                        <Iframe
                            src={course_details?.video?.video?.player_embed_url}
                            width='100%'
                            height='300px'
                            className='border-2'
                            // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
                            styles={{ borderRadius: "10px" }}>
                        </Iframe>
                    </div>
                </div>
            </Container>
            <div>
                <div>
                    <div className='py-3 sticky-top p-2' style={{ backgroundColor: "#EEF9FF" }}>
                        <nav className='col-md-6 mx-auto d-flex hideScrollbar gap-3' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}>
                            <ul className='d-flex list-unstyled gap-3 justify-content-center'>
                                {course_details?.overview &&
                                    <li
                                        className={navIndex == "1" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}
                                        style={{ background: "white" }}>
                                        <a className='text-decoration-none font-inter' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "1" ? "white" : "black" }} href="#section1" onClick={() => setNavIndex(1)}>
                                            OVERVIEW
                                        </a>
                                    </li>
                                }
                                {course_details?.advantages &&
                                    <li className={navIndex == "2" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "2" ? "white" : "black" }} href="#section2" onClick={() => setNavIndex(2)}>
                                            ADVANTAGES
                                        </a>
                                    </li>
                                }
                                {course_details?.inclusions &&
                                    <li className={navIndex == "3" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "3" ? "white" : "black" }} href="#section3" onClick={() => setNavIndex(3)}>
                                            INCLUSIONS
                                        </a>
                                    </li>
                                }
                                {course_details?.requirements &&
                                    <li className={navIndex == "4" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "4" ? "white" : "black" }} href="#section4" onClick={() => setNavIndex(4)}>
                                            REQUIREMENTS
                                        </a>
                                    </li>
                                }
                                {course_details?.success_rate &&
                                    <li className={navIndex == "5" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "5" ? "white" : "black" }} href="#section5" onClick={() => setNavIndex(5)}>
                                            SUCCESS RATE
                                        </a>
                                    </li>
                                }
                                {course_details?.modules?.length != 0 &&
                                    <li className={navIndex == "6" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "6" ? "white" : "black" }} href="#section6" onClick={() => setNavIndex(6)}>
                                            MODULES
                                        </a>
                                    </li>
                                }
                                {course_details?.experts?.length != 0 &&
                                    <li className={navIndex == "7" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "7" ? "white" : "black" }} href="#section7" onClick={() => setNavIndex(7)}>
                                            EXPERTS
                                        </a>
                                    </li>
                                }
                                {
                                    course_details?.reviews?.length != 0 &&
                                    <li className={navIndex == "8" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "8" ? "white" : "black" }} href="#section8" onClick={() => setNavIndex(8)}>
                                            REVIEWS
                                        </a>
                                    </li >
                                }
                                {
                                    course_details?.need_more_help &&
                                    <li className={navIndex == "9" ? 'font-inter text-white bg-black px-3 py-2 rounded-5' : 'font-inter text-black bg-white px-3 py-2 rounded-5'}>
                                        <a className='text-decoration-none font-inter ' style={{ fontSize: 14, fontWeight: '600', color: navIndex == "9" ? "white" : "black" }} href="#section9" onClick={() => setNavIndex(9)}>
                                            NEED MORE HELP
                                        </a>
                                    </li >
                                }
                            </ul >
                        </nav >
                    </div >
                    <div className='col-md-6   mx-auto'>
                        {course_details?.overview &&
                            <section id="section1" className='pt-3'>
                                <div className='my-1 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Overview</h2>
                                    <p className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        {course_details?.overview && course_details?.overview}
                                    </p>
                                </div>
                            </section>
                        }
                        {course_details?.advantages &&
                            <section id="section2" className='pt-3'>
                                {course_details?.advantages && <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Advantages</h2>
                                    <div className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.advantages }} ></div>
                                    </div>
                                </div>}
                            </section>
                        }
                        {course_details?.inclusions &&
                            <section id="section3" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Inclusions</h2>
                                    <div className='font-inter fs-6 p-3' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.inclusions }} ></div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.requirements &&
                            <section id="section4" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Requirements</h2>
                                    <div className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.requirements }} ></div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.success_rate &&
                            <section id="section5" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h2 className='font-w600 font-inter fs-5 text-black lh-lg'>Success Rate</h2>
                                    <div className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        <div dangerouslySetInnerHTML={{ __html: course_details?.success_rate }} ></div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.modules?.length != 0 &&
                            <section id="section6" className='pt-3'>
                                <div className='my-0 p-3'>
                                    <h3 className='font-w600 font-inter fs-5 text-black lh-lg'>Modules</h3>
                                    <div>
                                        <div className="row">
                                            <div className="px-0">
                                                <Accordion
                                                    defaultActiveKey={0}
                                                    onSelect={(eventKey) =>
                                                        setStructurEventKey(eventKey)}
                                                >
                                                    <ul className="timeline list-unstyled position-relative ps-4">
                                                        {
                                                            course_details?.modules?.map((item, index) => (
                                                                <li className="timeline-item bg-white rounded ml-3 p-4">
                                                                    <div className="timeline-arrow"></div>
                                                                    <h6 className="font-inter font-w600 fs-6 lh-1 pb-3">{item?.heading}</h6>
                                                                    <div className='rounded-2 p-2 ms-2' style={{ background: "#EEF9FF" }}>
                                                                        {/* <p className='font-inter font-w600 fs-6 text-black lh-lg'>Understanding the Basics of Sleep</p> */}
                                                                        <Accordion.Collapse eventKey={index}>
                                                                            <p className='font-inter fs-6 text-black lh-lg'>
                                                                                <span dangerouslySetInnerHTML={{ __html: item?.body }} ></span>
                                                                            </p>
                                                                        </Accordion.Collapse>
                                                                        <CustomToggle eventKey={index} >
                                                                            {
                                                                                structurEventKey === index &&
                                                                                <p className='font-inter fs-6'>Show less <img src='/assets/img/Icon(2).png' alt='' /></p>
                                                                                ||
                                                                                <p className='font-inter fs-6'>Show more <img src='/assets/img/Icon(3).png' alt='' /></p>
                                                                            }
                                                                        </CustomToggle>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                        {course_details?.experts?.length != 0 &&
                            <section id="section7" className='pt-3'>
                                <div className='my-0 px-3 py-4' style={{ background: "#EEF9FF" }}>
                                    <h3 className='font-inter font-w600 fs-3 text-center lh-lg'>About the expert</h3>
                                    <Swiper
                                        spaceBetween={50}
                                        pagination={true}
                                        // navigation={true}
                                        modules={[Scrollbar, Pagination, Navigation, A11y]}
                                        className="mySwiper">
                                        {course_details?.experts?.map((item, index) => (
                                            <SwiperSlide className='py-5'>
                                                <div>
                                                    <div className='row align-items-center'>
                                                        <div className='col-3' >
                                                            <img src={item?.image} alt='' style={{ width: 150, borderRadius: 10 }} />
                                                        </div>

                                                        <div className='col-9'>
                                                            <p style={{ fontSize: 16, fontFamily: 'Inter', fontWeight: '600', color: '#000000' }}>{item?.name}</p>
                                                            <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>{item?.type}</p>
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                <img src='/assets/img/flag.png' />
                                                                <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>India</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='font-inter fs-6 mt-3 '>
                                                        <div className='font-inter' dangerouslySetInnerHTML={{ __html: item?.description.substr(0, 400) }} ></div>

                                                    </div>
                                                    <Button onClick={() => navigate(`/detail/${item?.id}`)} className='text-white font-inter text-center d-block mx-auto mt-3' style={{ background: "#1B8BCF" }}>Book Appointment</Button>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                        }
                                    </Swiper>
                                    {/* <div>
                                        <h5 className='font-inter font-w600 fs-3 text-center lh-lg'>About the expert</h5>
                                        <div className='row align-items-center'>
                                            <div className='col-3' >
                                                <img src={course_details?.experts[0]?.image} alt='' style={{ width: 150, borderRadius: 10 }} />
                                            </div>

                                            <div className='col-9'>
                                                <p style={{ fontSize: 16, fontFamily: 'Inter', fontWeight: '600', color: '#000000' }}>{course_details?.experts[0]?.name}</p>
                                                <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>{course_details?.experts[0]?.type}</p>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <img src='/assets/img/flag.png' />
                                                    <p style={{ fontSize: 14, fontFamily: 'Inter', fontWeight: '400', color: '#0E0F0C' }}>India</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='font-inter fs-6 mt-3'>
                                            <span className='font-inter' dangerouslySetInnerHTML={{ __html: course_details?.experts[0]?.description }} ></span>
                                        </p>
                                        <Button onClick={() => navigate(`/detail/${course_details?.experts[0]?.id}`)} className='text-white font-inter text-center d-block mx-auto mt-3' style={{ background: "#1B8BCF" }}>Book Appointment</Button>
                                    </div> */}
                                </div>
                            </section>
                        }
                        {course_details?.reviews?.length != 0 &&
                            <section id="section8" className='pt-3'>
                                <div className='my-0 px-3 py-4'>
                                    <h3 className='font-inter fs-4 text-center lh-lg'>Testimonials</h3>
                                    <Swiper
                                        slidesPerView={2}
                                        pagination={true}

                                        // navigation={true}
                                        modules={[Scrollbar, Pagination, Navigation, A11y]}
                                        className="mySwiper py-3"
                                        breakpoints={{
                                            274: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            844: {
                                                slidesPerView: 2.3,
                                                spaceBetween: 50,
                                            },
                                        }}
                                        style={{
                                            // "--swiper-pagination-color": "#FFBA08",
                                            // "--swiper-pagination-bullet-inactive-color": "#999999",
                                            // "--swiper-pagination-bullet-inactive-opacity": "1",
                                            // "--swiper-pagination-bullet-size": "16px",
                                            "--swiper-pagination-bullet-vertical-gap": "6px"
                                        }}
                                    >
                                        {course_details?.reviews?.map((item, index) => (
                                            <SwiperSlide>
                                                <div className='d-flex flex-column align-items-center' style={{ background: "#EEF9FF", padding: 15, borderRadius: 10, height: 310 }}>
                                                    <p className='font-inter fs-6 text-black' style={{ textAlign: 'justify', height: 270 }}>
                                                        {item?.description.substr(0, 200)} ...
                                                    </p>
                                                    <img src='/assets/img/Group 1000002231.png' alt='' />
                                                    {/* {item?.rating} */}
                                                    <p className='font-inter font-w600 fs-6 text-black'>{item?.user}</p>
                                                    <p className='font-inter fs-6 text-black'>{item?.location}</p>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                        }
                                    </Swiper>
                                </div>
                            </section>
                        }
                        {course_details?.need_more_help &&
                            <section id="section9" className='pt-3'>
                                <div className='my-0 px-3 py-4'>
                                    <h3 className='font-inter font-w600 fs-5 lh-lg'>Need More Help?</h3>
                                    <p className='font-inter fs-6' style={{ color: "#21231E" }}>
                                        {
                                            console.log("courseDetail", course_details) ||
                                            <span dangerouslySetInnerHTML={{ __html: course_details?.need_more_help }}></span>
                                        }
                                    </p>
                                </div>
                            </section>
                        }

                    </div>

                    <div className='sticky-bottom py-3' style={{ backgroundColor: "#fff" }}>
                        <nav className='col-md-6 mx-auto d-flex hideScrollbar gap-3' style={{ overflowX: 'scroll', whiteSpace: "nowrap" }}>
                            {/* {
                                myCourseData && myCourseData?.courses?.find(o => o.course_id === item.id) &&
                                <Card.Link><Button id='course_start' onClick={() => onClick(item)} variant="warning" className='text-white rounded-5 px-4'>Start</Button></Card.Link> ||
                                <Card.Link><Button id='course_enroll' onClick={() => onClickEnroll(item)} variant="warning" className='text-white rounded-5 px-4'>Enroll Now</Button></Card.Link>
                            } */}
                            <Button id='course_detail_enroll' onClick={() => onEnrollNow()} className=' font-inter text-center d-block mx-auto' style={{ background: "#9DEA66", borderWidth: 0, paddingLeft: 50, paddingRight: 50, color: '#000000' }}>Enroll now</Button>
                        </nav>
                    </div>
                </div >
            </div >
        </div >
    )
}

const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <Link
            style={{ color: '#1B8BCF', textDecoration: "none" }}
            onClick={decoratedOnClick}
        >
            {children}
        </Link>
    );
}

export default CourseDetailID1