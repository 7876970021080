import React from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from "file-saver";


//Firebase
import { getDownloadURL, ref, uploadBytesResumable, getStorage } from "firebase/storage";
//import { storage } from "../../../../firebaseConfig";

const PrescriptionTabItem = ({ title, note, linkedFile, date }) => {

  const storage = getStorage();


  const handleDownload = async () => {
    fetch(linkedFile)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'myfile.png';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };
  return (
    <div className="PrescriptionItem">
      <div className="d-flex align-items-center gap-2 justify-content-between">
        <h6>{title}</h6>

        {/* <IconButton color="primary" onClick={() => handleDownload()}>
          <FileDownloadIcon />
        </IconButton> */}

        <a download href={linkedFile} target="_blank">
          <VisibilityOutlinedIcon />
        </a>

      </div>

      <span>{date}</span>
      <p>
        {note}
      </p>
    </div>
  );
};

export default PrescriptionTabItem;
