import React, { useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { getDashboardWorksheetList_WebService } from '../core/_request';
import { useAuth } from '../../auth';
import { Helmet } from 'react-helmet';

const DashboardMyWorksheetTab = () => {

  const [worksheetData, setworksheetData] = useState();
  const { currentUser } = useAuth();

  useEffect(() => {
    const body = {
      "userId": currentUser.UserId,
      "courseId": 5
    }
    getDashboardWorksheetList_WebService(body).then(resp => {
      // console.log("resp Data", resp.data)
      setworksheetData(resp.data);
    });
  }, [])
  return (

    <div className='my-2 my-md-0'>
      <Helmet>
        <title>My Worksheets - United We Care</title>
      </Helmet>
      <div className='d-none d-md-flex align-items-center gap-3 py-4 px-5' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25", borderBottom: "2px solid #FF9C00" }}><img src='/assets/img/vector(11).png' alt='' />
        <p className="font-w500">My Worksheets</p>
      </div>
      {worksheetData?.worksheets.map((worksheet) => (
        <div className='row justify-content-between px-md-5 py-2 py-md-3 gap-1 gap-md-0 mx-1' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='col-md-5'>
              {worksheet?.name}
            </p>
            <Badge bg='success' text='light' className='d-block d-md-none'>
              {/* Upcoming */}
              {worksheet?.status}
            </Badge>
          </div>
          <p className='col-md-5'>Due Date :
            <span>
              {worksheet?.due_date}
            </span>
          </p>
          <div className='d-flex align-items-center gap-md-4 col-md-2'>
            <Badge bg='success' text='light' className='py-1 d-none d-md-block'>
              {worksheet?.status}
            </Badge>
          </div>
        </div>
      ))}
      {/* <div className='d-flex justify-content-between px-5 py-3' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
        <p>Worksheet 2</p>
        <div className='d-flex align-items-center gap-4'><p>Due Date :</p> <p>23 Jun’22</p></div>
        <div className='d-flex align-items-center gap-4'>
          <Badge bg='warning' text='dark' className='py-1'>Upcoming</Badge>
        </div>
      </div>
      <div className='d-flex justify-content-between px-5 py-3' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
        <p>Worksheet 3</p>
        <div className='d-flex align-items-center gap-4'><p>Due Date :</p> <p>23 Jun’22</p></div>
        <div className='d-flex align-items-center gap-4'>
          <Badge bg='warning' text='dark' className='py-1'>Upcoming</Badge>
        </div>
      </div> */}
    </div>
  )
}

export default DashboardMyWorksheetTab