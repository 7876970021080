import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import PayoutWidgetCard from "./cards/PayoutWidgetCard";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TicketDetailsTableRow from "./tables/TicketDetailsTableRow";
import PayoutDetailsTableRow from "./tables/PayoutDetailsTableRow";

import Button from "@mui/material/Button";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import moment from "moment";
import dayjs from "dayjs";
//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../../auth";

const ExpertPayoutsDB = () => {
  const [currency, setCurrency] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const [transferred, setTransferred] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [transactionType, setTransactionType] = useState("All Transactions");

  const [fromDate, setFromDate] = useState(dayjs().subtract(30, 'days').format("MM/DD/YYYY"));
  const [toDate, setToDate] = useState(dayjs().format("MM/DD/YYYY"));

  const handleTransactionChange = (event) => {
    setTransactionType(event.target.value);
  };

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if user is logged in
  useEffect(() => {
   
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate('/login');
      window.location.href = "https://www.unitedwecare.com/";
    }
  }, [location, navigate]);

  const postPayoutData = async () => {
    const payload = {
      user_id: currentUser?.UserId,
      start_date: moment(fromDate, "MM/DD/YYYY").format("MM-DD-YYYY"),
      end_date: moment(toDate, "MM/DD/YYYY").format("MM-DD-YYYY"),
      type: transactionType,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/payouts`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const data = await postResponse.data;
      //console.log("data", data)

      setCurrency(data?.currency);
      setTransactions(data?.transaction_history_ids);

      setTransferred(data?.transferred);
      setTotalEarning(data?.total_earning);
      setTotalPending(data?.total_pending);
      
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    postPayoutData();
  }, []);

  

  const onFromDateChange = (date) => {
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFromDate(formattedDate);
  };

  const onToDateChange = (date) => {
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setToDate(formattedDate);
  };

  return (
    <div className="my-3 PayoutDashboard">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PayoutWidgetCard
            title={"Total Earnings"}
            icon={"wallet"}
            currency={currency}
            value={totalEarning}
          />
        </Grid>

        <Grid item xs={4}>
          <PayoutWidgetCard
            title={"Transferred to Bank"}
            icon={"Bank"}
            currency={currency}
            value={transferred}
          />
        </Grid>

        <Grid item xs={4}>
          <PayoutWidgetCard
            title={"Total Pending"}
            icon={"time"}
            currency={currency}
            value={totalPending}
          />
        </Grid>
      </Grid>

      <div className="PayoutDashboard--Content mt-5">
        <div className="d-flex align-items-center gap-3">
          <h4 style={{ fontSize: "22px" }}>Transactions History</h4>

          <div
            className="ml-auto d-flex align-items-center gap-3"
            style={{ marginLeft: "auto" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From date"
                format="MM/DD/YYYY"
                value={dayjs(fromDate, "MM/DD/YYYY")}
                onChange={onFromDateChange}
                sx={{
                  width: "100%",
                  backgroundColor: "#FFF",
                  borderRadius: "10px",
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To date"
                format="MM/DD/YYYY"
                value={dayjs(toDate, "MM/DD/YYYY")}
                onChange={onToDateChange}
                sx={{
                  width: "100%",
                  backgroundColor: "#FFF",
                  borderRadius: "10px",
                }}
              />
            </LocalizationProvider>

            <FormControl
              sx={{ background: "#FFF", width: "100%", borderRadius: "10px" }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={transactionType}
                onChange={handleTransactionChange}
                fullWidth
              >
                <MenuItem value={"All Transactions"}>All Transactions</MenuItem>
                <MenuItem value={"Session"}>Session</MenuItem>
                <MenuItem value={"Payout"}>Payout</MenuItem>
              </Select>
            </FormControl>

            <Button
              className="GreenCTA"
              variant="contained"
              disableElevation
              onClick={postPayoutData}
            >
              Go
            </Button>
          </div>
        </div>

        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>AMOUNT</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>DATE</TableCell>
                <TableCell>REMARKS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.length === 0 ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>No Transactions</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ) : (
                transactions?.map((item) => {
                  return (
                    <PayoutDetailsTableRow
                      key={item.transaction_code}
                      id={item.id}
                      code={item.transaction_code}
                      amount={item.amount}
                      type={item.type}
                      name={item.name}
                      image={item.image}
                      time={item.date_time}
                      remarks={item.remarks}
                      currency={currency}
                    />
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ExpertPayoutsDB;
