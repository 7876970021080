import React from "react";
import { Link } from "react-router-dom";

const InboxRowCard = ({ name, img, messageType, message, unreadMessages }) => {
  return (
    <div className="d-flex align-items-start justify-content-between p-3 InboxWidget--Item">
      <div className="d-flex align-items-center gap-3">
        <img src={img} alt="" width={45} height={45} style={{ borderRadius: '50%' }} />
        <div className="Item--Contents">
          <div className="Contents--Header">
            <h6 className="font-inter fs-6">{name}</h6>
            {parseInt(unreadMessages, 10) > 0 && (
              <div className="font-inter px-1 Item--UnreadMessages">
                {unreadMessages}
              </div>
            )}
          </div>

          {messageType == "attachment" ? (
            <Link className="d-flex align-items-center">
              <img src="/assets/img/Mask group(23).png" alt="" />
              <p>{message}</p>
            </Link>
          ) : (
            <p className="Item--Subject">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InboxRowCard;
