import React from 'react'
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

const CoursePaymentStatus = () => {
    let { id } = useParams();
    return (
        <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
            <div className='container'>
                <div className="py-5">
                    <h6 className="text-white font-w600 fs-4">Thank you for booking a course.</h6>
                    {/* <p className='text-white'>We will contact you soon.</p> */}
                </div>
                <div className='bg-white' style={{ background: "#FFFFFF", boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                    <div className='py-5 mx-auto px-2 px-md-5 row gap-md-5'>
                        <div className="col-xs-12 col-md-12 text-center">
                        {
                            id == "success" && 
                            <div className="col-xs-12 col-md-12 text-center">
                                <p style={{ marginBottom: 20 }}>Your payment was successful</p> 
                                <Link to={"/user-dashboard/course/my-courses"}><Button className='px-4' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", border: "none", color: "#fff" }}>Take me to my course</Button></Link>
                            </div>
                            || 
                            <div className="col-xs-12 col-md-12 text-center">
                                <p style={{ marginBottom: 20 }}>Payment Failure! <br /> Please try again to complete your booking.</p>
                                <Link to={"/course"}><Button className='px-4' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", border: "none", color: "#fff" }}>View Courses</Button></Link>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoursePaymentStatus