import { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import ClientScreenerDetailsTable from "../../tables/ClientScreenerDetailsTable";
import ClientGeneralQuestionsTable from "../../tables/ClientGeneralQuestionsTable";

import { useAuth } from "../../../../auth";

const ScreenerDetailsView = ({ type, title, clientDetails, clientID, clientName, screenerID }) => {
  const [screenerDetails, setScreenerDetails] = useState(null)

  const { currentUser, userType, logout } = useAuth();


  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };
  //Fetch Client Screeners
  useEffect(() => {
    if (!clientID) {
      // If checkClientID is blank, return early
      return;
    }

    const postClientScreenersData = async () => {
      try {
        const screenerBody = {
          expert_id: +currentUser?.UserId,
          user_id: +clientID,
          test_id: +screenerID
          // expert_id: 1545,
          // user_id: 7632,
          // test_id: 4138
        }

        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(screenerBody),
          }
        );
        const postResponse = await response.json();

        //const { assignments } = await postResponse.data;

        //setClientScreeners(assignments);
        //setUserData(postResponse?.data);
        // console.log("assignments", postResponse)
        // console.log("TYPE", type)
        if (type === "QNA") {
          let filteredItem = postResponse?.data?.worksheets.filter(item => item.id === +screenerID)
          if (filteredItem.length > 0) {
            setScreenerDetails(filteredItem[0])
          }

          //console.log("filteredItem",filteredItem)

        } else {
          if (postResponse?.data.assignments.length > 0) {
            setScreenerDetails(postResponse?.data.assignments[0])
          }

        }

        //console.log("data", postResponse);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientScreenersData();
  }, [clientID])


  //console.log("screenerDetails", screenerDetails)


  return (
    <div className="Screener--Details mt-3">
      <div className="Screener--Header">
        <h4>{title}</h4>
        <span>
          Date of Score : <b>{screenerDetails?.score_date}</b>
        </span>
      </div>

      <div className="Screener--ClientDetails">
        <img
          src={clientDetails.image}
          width={72}
          height={72}
        />
        <div>
          <h6>{clientDetails.name}</h6>
          <p>{clientDetails.country}</p>
          {/* <span>
            Last Active : <b>14 Hours ago</b>
          </span> */}
        </div>
      </div>

      <div className="Screener--Content">
        <TableContainer>
          {type === "QNA" ? (<ClientGeneralQuestionsTable questions={screenerDetails?.questions} />) : (
            <ClientScreenerDetailsTable
              options={screenerDetails?.options}
              responses={screenerDetails?.responses}
              totalScore={screenerDetails?.total_score}
              score={screenerDetails?.int_score}
            />
          )}

          {/* {title === "General Questions" && } */}
        </TableContainer>
      </div>
    </div>
  );
};

export default ScreenerDetailsView;
