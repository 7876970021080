import React from 'react' 

const LandingPage = () => {
    return (
        <div>
            <div className='container-fluid' style={{ backgroundImage: `url("/assets/img/landing-page/banner-bg.png")`, backgroundSize: "100% 100%" }}>
                {/* <Header /> */}
                <div className='d-flex flex-md-row flex-column align-items-center justify-content-evenly'>
                    <div className='col-md-4 col-xs-12'>
                        <h5 className='text-white display-4'>Build a Happier</h5>
                        <h5 className='text-white display-4'>Workplace with UWC</h5>
                        <p className='text-white'>Invest in building a resilient & productive team with the World's fastest growing mental wellness App <br />
                            UWC is helping employees & companies work better by reducing stress, anxiety, and burnout.
                        </p>
                        <button className='' style={{
                            background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "20%"
                        }}>Know More</button>
                    </div>
                    <div className='col-md-6 col-xs-12'>
                        <img src='/assets/img/landing-page/banner.png' alt='' />
                    </div>
                </div>
            </div>
            <div className='my-5'>
                <div className='col-md-5 d-block mx-auto'>
                    <h5 className='display-6 font-w600 text-center'>How are we different from others</h5>
                    <p className=' text-center'>All this customised to suit needs of every employee. UWC provides tools & resources to boost emotional fitness.</p>
                </div>
                <div className='d-block'>
                    <img className='mx-auto' src='/assets/img/landing-page/how-it-works.png' alt=''/>
                </div>
            </div>
        </div >
    )
}

export default LandingPage