import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../auth';
import { useContext } from 'react';
import { AppContext } from '../../context';

export const WhyTrustUs = () => {
    const { planType } = useParams();
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    const onClickSubscribe = (pageType) => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            if (planType === "Platinum") {
                sessionStorage.setItem("subscriptionID", 2);
                navigate(`/subscription-order-summary`)
            }
            else {
                sessionStorage.setItem("subscriptionID", 11);
                navigate(`/subscription-order-summary`)
            }
        }
    }

    return (
        <div className='WhyTrustUs--Area'>
            <div className='WhyTrustUs--Top'>
                <div className='Desc--Area'>
                    <h4>
                        Your <span>Trust</span> Matters to Us
                    </h4>
                    <p>
                        Choose your plan and see the difference for yourself. We offer an array of multiple offerings with flexible options. There are no hidden costs.
                    </p>

                </div>

                <div className='Images--Area'>
                    <img src='/assets/img/planDetails/Picture1.png' />
                    <img src='/assets/img/planDetails/Picture2.png' />
                    <img src='/assets/img/planDetails/Picture3.png' />
                </div>

            </div>
            <div className='WhyTrustUs--Bottom'>

                <div className='Desc--Area'>
                    <h4>11,658,467</h4>
                    <h6>People Subscribed and started their wellness journey</h6>
                    <button onClick={() => onClickSubscribe()}>Join them and start</button>
                </div>
                <picture>
                    <source media="(min-width:465px)" srcset="/assets/img/planDetails/TrustBG.png" />
                    <img src='/assets/img/planDetails/TrustBGAlt.png' />
                </picture>
                <img src='/assets/img/planDetails/globalMap.png' className='WorldMap' />
            </div>
        </div>
    )
}
