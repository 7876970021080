import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap'
import DatePicker from './DatePicker';
import moment from 'moment';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getExpertbyId_WebService, subscription_subscribe_odoo, AppointmentTimeService, AddAppointmentUserService, IndianpackagewithpriceService, getInstituteDiscountOODOService, AddUserPackageService, UserDetailsService, AddTransactionService, UpdateTransactionService, UseCouponCodeService, Get_Appointment_Detailsbyid, updatePhoneNumber, saveAppointmentOrder, isFirstTimeAppointment } from './core/_request';
import { useAuth } from '../auth';
import { getCountryCode, getCurrencyCode } from './core/commonFun';
import { RescheduledService } from './user/core/_request';
import { AppContext } from '../context';
import { useContext } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { DeductFromSubscription } from './subscriptions/core/request';

function loadRazorpayScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const ExpertBookingPreview = () => {
    const [loading, setLoading] = useState(false);
    const [couponloading, setCouponloading] = useState(false);
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const [couponCode, setCouponCode] = useState();
    const [number, setNumber] = useState();
    const [couponCodeMessage, setCouponCodeMessage] = useState();
    const [readMoreExperties, setReadMoreExperties] = useState()
    const [expertDetailsData, setExpertDetailsData] = useState();
    const [data, setData] = useState();
    let { id } = useParams();
    const search = useLocation().search;
    const navigate = useNavigate();
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();
    const { currentUser, logout } = useAuth();
    var ExpertisesArrayCount = expertDetailsData?.Expertise?.split(',').length - 4
    var ExpertisesArray = expertDetailsData?.Expertise?.split(',').length > 4 ? expertDetailsData?.Expertise?.split(',').slice(0, 4) : expertDetailsData?.Expertise?.split(',');
    var ExpertisesArrayAll = expertDetailsData?.Expertise?.split(',')

    var dispr_val = localStorage.getItem("dispr");
    const appid = new URLSearchParams(search).get("appid");

    // const { selectedDate, selectSlot, callTime, expertDetailsData, data2 } = 
    // appid ? getAppDataByAppId()
    // :
    // JSON.parse(localStorage.getItem("bookAnAppointmentData"));


    const [mainAmountAfterCoupone, setMainAmountAfterCoupone] = useState();


    useEffect(() => {
        getExpertbyId_WebService(id).then(async resp => {
            var data1 = resp.data;
            console.log("data111", data1)
            if (countryCode == "IN") data1["countryAmount"] = data1.Hourly_Consultaion_Fee_amount;
            else data1["countryAmount"] = data1.USD_Amount;
            setExpertDetailsData(data1);
            // console.log("expertDetailsData", data)
            var tdata = undefined;

            if (appid) {
                tdata = await getAppDataByAppId();
                const mainAmount = tdata.callTime == 30 ? (data1.countryAmount / 2) : data1.countryAmount;
                tdata["mainAmount"] = mainAmount
                setData(tdata);
            }
            else {
                tdata = JSON.parse(localStorage.getItem("bookAnAppointmentData"))
                const mainAmount = tdata.callTime == 30 ? (data1.countryAmount / 2) : data1.countryAmount;
                tdata["mainAmount"] = mainAmount
                setData(tdata);
            }
            // var body = {
            //     ExpertId: tdata.selectSlot.Expert ,
            //     App_Date: tdata.selectedDate,
            //     App_Time: tdata.selectSlot.Time_Duration,
            //     Session_Type: tdata.callTime,
            //     Amount:tdata.mainAmount

            // }
            // saveAppointmentOrder(body).then(resp => {
            //     console.log("saveAppointmentOrder resp", resp)
            //     if (resp.data == "") {

            //     }
            // })


        });
    }, [])

    const applyCoupon = () => {
        if (!couponCode) {
            // alert("Please fill coupon code.");
            setCouponCodeMessage("Please fill coupon code.")
            return;
        }

        setCouponloading(true);

        var body = { "CouponCode": couponCode, "Type": expertDetailsData.RegisterYourselfAs, "Amount": data.mainAmount };
        // console.log("UseCouponCodeService req", body)
        UseCouponCodeService(body).then(resp => {
            // console.log("UseCouponCodeService resp", resp)
            if (resp.data == "") {

            }
            else {
                setMainAmountAfterCoupone(resp.data);
            }

            // alert(resp.ResponseMessage);
            setCouponCodeMessage(resp.ResponseMessage)
            setCouponloading(false);
        })
    }

    const onChangeNumber = () => {
        var body = {
            MobileNo: number
        }
        updatePhoneNumber(body).then(resp => {
            // console.log("updatePhoneNumber resp", resp)
            if (resp.data == "") {

            }
        })
    }

    const onCheckout = () => {
        debugger
        setLoading(true);
        onChangeNumber();
        var body = {
            ExpertId: id,
            AppointmentDate: moment(data.selectedDate).format("YYYY-MM-DD"),
            App_StartTime: data?.selectSlot?.Time_Duration,
            CallMode: "Video_Call",
            CallTime: data?.callTime
        }

        const AppointmentId = new URLSearchParams(search).get("AppointmentId");
        if (AppointmentId) {
            // console.log("RescheduledService req")
            // console.log(body)
            body.AppointmentId = AppointmentId;
            RescheduledService(body).then(resp => {
                alert("Appointment Successfully Rescheduled!");
                navigate(`/user-dashboard/my-appointments/${AppointmentId}`);
            });
        }
        else {

            AddAppointmentUserService(body).then(async resp => {
                const disAmount = await getInstituteDiscountOODOService(expertDetailsData?.countryAmount ?? 0).then(resp => resp.data);
                const TotalAmount = Math.round(disAmount ?? data.mainAmount);
                var UserDetails = await UserDetailsService().then((resp) => resp.data);
                var tbody = {
                    ExpertId: id,
                    PackageName: "Pay As You go - usa - subscription" + dispr_val,
                    Country: currentUser.Country,
                    Type: data.data2.AppType,
                    HSTAmount: 0,
                    AppointmentCount: 0,
                    DurationInMonths: 0,
                    //Amount:this.state.Indianpackagewithprice.Amount,
                    //TotalAmount:this.state.Indianpackagewithprice.Amount
                    Amount: TotalAmount,
                }
                AddUserPackageService(tbody).then(resp => {
                    // displayRazorpay(TotalAmount, data.data2.PackageName, resp.data, data.data2)
                    addTrans(null, resp.data, TotalAmount, UserDetails, data.data2)
                })
            })
        }
    }

    const displayRazorpay = async (amount, PackageName, AddUserPackageData, Indianpackagewithprice) => {
        var UserDetails = await UserDetailsService().then((resp) => resp.data);
        const res = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const options = {
            key: "rzp_live_Gft79xyY3ppWNz", // Enter the Key ID generated from the Dashboard
            amount: amount * 100,
            currency: countryCode == "IN" ? "INR" : "USD",
            name: UserDetails.Name,
            description: "Pay As You go - " + PackageName,
            handler: async function (response) {
                addTrans(response, AddUserPackageData, amount, UserDetails, Indianpackagewithprice)
            },
            modal: {
                ondismiss: function () {
                    navigate("/payment-status/failed");
                }
            },
            prefill: {
                name: UserDetails.Name,
                email: UserDetails.Emailid,
                contact: number ?? UserDetails.MobileNo,
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const addTrans = (response, AddUserPackageData, TotalAmount, UserDetails, Indianpackagewithprice) => {
        let rendId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "_web";
        var body = {
            ExpertId: id,
            PackageId: AddUserPackageData.PackageId,
            TransactionId: rendId,
            //Amount:this.state.Indianpackagewithprice.Amount,
            Amount: TotalAmount,
            //Coupon: this.state.CouponCode,
            isMarkAutoRenew: false,
            TransactionType: "Credit Card",
            PaymentGateway_OrderId: "",
            Billing_Address_Master: {
                TransactionId: rendId,
                Name: UserDetails.Name,
                Email: UserDetails.Emailid,
                Mobile: UserDetails.MobileNo,
                //Address:this.state.UserDetails.Address.City+", "+this.state.UserDetails.Address.Country+", "+this.state.UserDetails.Address.Pin+" "
            },
            DiscountAmount: (countryCode == "IN" ? (Indianpackagewithprice.Amount - TotalAmount) : 0),
            PaymentGateway_OrderId: response?.razorpay_payment_id
        }

        AddTransactionService(body).then((item) => {
            var tbody = { TransactionId: rendId, Status: "Completed" }
            UpdateTransactionService(tbody).then(trp => {
                // try{
                //     var subscribe_odoo_body = {
                //         "user_id": user_id,
                //         "plan_id": parseInt(id),
                //         "duration_id": optionData?.id,
                //         "payment_method": "card",
                //         "payment_id": response?.razorpay_payment_id,
                //         "order_id": rendId,
                //         "signature": ""
                //     }
                //     subscription_subscribe_odoo(subscribe_odoo_body).then(trp => console.log("subscription_subscribe_odoo resp", trp));
                // }catch{}
                localStorage.removeItem("dispr");
                setLoading(false);
                // var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId
                isFirstTimeAppointment().then(resp => {
                    if (resp?.data?.IsFirstTimeFreeDone !== true) {
                        alert("Appointment Successfully Booked!");
                        navigate("/stella?message=backendstart_Screener_Intro");
                    }
                    else {
                        alert("Appointment Successfully Booked!");
                        navigate("/user-dashboard/my-appointments");
                    }
                })
            });
        });
    }

    const getAppDataByAppId = async () => {
        if (!currentUser) {
            // navigate(`/login?retrunurl=/payment-order-preview/${id}?appid=${appid}`);
            setShowLloginPopup(true);
        }
        var outData = {};

        var t1 = await IndianpackagewithpriceService(id).then((resp) => outData["data2"] = resp.data);
        var t3 = await Get_Appointment_Detailsbyid(appid).then((resp) => {
            // console.log("Get_Appointment_Detailsbyid resp", resp)
            outData["selectedDate"] = resp.data.AppointmentDate;
            outData["selectSlot"] = { Time_Duration: resp.data.App_StartTime }
            outData["callTime"] = resp.data.Minutes;
        });
        // return Promise.all([t1,t2,t3]).then(_=>{
        //     return outData;
        // });
        return outData;
    }

    const onClickNavigate = () => {
        navigate(`/book-an-appointment/${id}`)
    }

    return (
        !expertDetailsData || !data && <div>Loading...</div> ||

        <div className='d-block' style={{ backgroundSize: "100% 300px", backgroundRepeat: "no-repeat", backgroundImage: `url("/assets/img/Rectangle 2187.png")` }}>
            <div className='col-md-7 mx-auto px-2 px-md-5 rounded-3' >
                <h5 className='font-w600 text-center fs-2 py-3'>Review Session Details</h5>
                <div className='bg-white rounded-2'>
                    <div className='row '>
                        <div className="col-md-6 py-3 py-md-5" style={{ border: "1px solid #E2E2E1", backgroundColor: '#fff' }}>
                            <div className="row align-items-center" >
                                <img src={expertDetailsData?.ProfileImage} className='col-5 col-md-6 rounded-5 p-4' alt='' />
                                <div className='col-6'>
                                    <p className='font-w600 fs-6 font-inter lh-base text-black'>{expertDetailsData?.Name}</p>
                                    <p className='font-inter fs-6 lh-base'>{expertDetailsData?.RegisterYourselfAs}</p>
                                    <p className='font-inter fs-6 lh-base'>Experience : {expertDetailsData?.Experience}</p>
                                    {/* <p className='font-inter fs-6 lh-base '>{expertDetailsData?.Qualifications}</p> */}
                                    <p className='font-inter fs-6 lh-base'><img src='/assets/img/usa-20.png' alt='' /> United States</p>
                                </div>
                            </div>
                            <div className='px-3 d-none d-md-block'>

                                {
                                    readMoreExperties &&
                                    <>
                                        <p className='font-inter fs-5 font-w500 my-4 text-black d-flex align-items-center flex-wrap lh-lg'>Area of Interest</p>
                                        <div className='row px-2'>
                                            {
                                                ExpertisesArrayAll && ExpertisesArrayAll.map((Expertise) => (
                                                    <span className='font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-1' style={{ border: "1px solid #E2E2E1", width: "auto", color: "#494747" }}>{Expertise}</span>
                                                ))
                                            }
                                        </div>
                                    </>
                                    ||
                                    <>
                                        <p className='font-inter fs-5 font-w500 my-2 text-black d-flex align-items-center flex-wrap'>Area of Interest</p>
                                        <div className='row px-2'>
                                            {
                                                ExpertisesArray && ExpertisesArray.map((expertiseAll, index) => (
                                                    <span className='font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-1' style={{ border: "1px solid #E2E2E1", width: "auto", color: "#494747" }}>
                                                        {expertiseAll}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                        <button
                                            className="rounded-1 px-2 py-0 my-3 font-w700 w-auto d-flex flex-row align-items-center justify-content-center gap-3"
                                            style={{ background: "#FFFFFF", border: "1px solid #E2E2E1" }}
                                            onClick={() => setReadMoreExperties(true)}> {ExpertisesArrayCount}+ <img src='/assets/img/Vector(17).png' alt='' />
                                        </button>
                                    </>
                                }

                            </div>
                        </div>
                        {/* <div class="vr p-0 m-0"></div> */}
                        <div className="col-md-6 py-3 py-md-5" style={{ border: "1px solid #E2E2E1", backgroundColor: '#fff' }}>
                            <div className='rounded-2 m-3 p-3 d-flex justify-content-between align-items-center' style={{ background: "#EEF9FF", border: "1px solid #C4E5F9" }}>
                                <div>
                                    <p className='font-inter text-black font-w600 fs-5'>Video Session</p>
                                    <p className='font-inter text-black font-w400 fs-6'>{moment(data.selectedDate).format("DD MMMM")} March 2023</p>
                                    <p className='font-inter text-black font-w400 fs-6'>{data.selectSlot?.Time_Duration} ({data?.selectSlot?.TimeZone})</p>
                                    <p className='font-inter text-black font-w400 fs-6'>{data.callTime} Min Individual Session</p>
                                </div>
                                <div>
                                    <button onClick={onClickNavigate} className='border-0 bg-transparent'>
                                        <img src='/assets/img/Mask group(7).png' alt='' />
                                    </button>
                                </div>
                            </div>
                            {/* <div className='m-4'>
                                <p className='font-inter font-w400 fs-6 d-flex justify-content-between'>Session Charges:
                                    <span className='font-inter font-w500 fs-6 text-black'>
                                        {CurrencyCode} {Math.round(mainAmountAfterCoupone ?? data.mainAmount)}
                                        Free
                                    </span>
                                </p>
                                {
                                    mainAmountAfterCoupone &&
                                    <p className='font-inter font-w400 fs-6 d-flex justify-content-between'>Session Discount : <p className='font-inter font-w500 fs-6 text-black'>
                                        {CurrencyCode} {Math.round(data.mainAmount - mainAmountAfterCoupone)}
                                        Free
                                    </p></p>
                                    ||
                                    null
                                }
                                <p className='font-inter font-w400 fs-6 d-flex justify-content-between'>Final Amount :
                                    <span className='font-inter font-w500 fs-6 text-black'>
                                        {CurrencyCode} {Math.round(mainAmountAfterCoupone ?? data.mainAmount)}
                                        Free
                                    </span>
                                </p>
                            </div> */}
                            {/* <div className=' d-flex justify-content-between justify-content-md-between mx-2 mx-md-4 rounded-2'
                                style={{ border: "1px solid #D9D9D9", borderRight: "none" }} > 
                                <input className='rounded-2 border-0'
                                    type='text' placeholder='Discount / Referral code'
                                    value={couponCode} onChange={(e) => setCouponCode(e.target.value)}
                                    style={{ height: "45px", paddingLeft: 7 }}
                                />
                                {
                                    couponloading &&
                                    <Button className='col-3 py-2'><img src="/assets/img/loading.gif" alt="Unitedwecare" style={{ height: 26, width: 26 }} /></Button>
                                    ||
                                    <Button className='font-inter fs-6 text-black border-0 px-md-4 py-2' style={{ background: "#C4E5F9" }} onClick={() => applyCoupon()}>Apply</Button>
                                }
                            </div> */}
                            {/* <p className='mx-md-4 font-inter' style={{ color: "red" }}>{couponCodeMessage}</p> */}

                            <div className='mx-2 mx-md-4 mt-4'>
                                <h5 className='font-inter d-flex justify-content-between' style={{ justifyContent: 'center' }}>Bill To
                                    <span style={{ height: 1, width: '75%', backgroundColor: '#E2E2E1', alignSelf: 'center' }} />
                                </h5>

                                <p className='font-inter font-w400 fs-6 d-flex mt-1 justify-content-between'>Name:
                                    <span className='font-inter font-w500 fs-6 text-black'>{currentUser?.Name}</span>
                                </p>
                                <p className='font-inter font-w400 fs-6 d-flex mt-1 justify-content-between'>Email:
                                    <span className='font-inter font-w500 fs-6 text-black' style={{ overflow: 'hidden' }}>{currentUser?.Emailid}</span>
                                </p>
                            </div>
                            <div className=' d-flex justify-content-between justify-content-md-between m-2 mt-2 mx-md-4 rounded-2'
                                style={{ border: "1px solid #D9D9D9" }} >

                                <input className='rounded-2 border-0'
                                    type='tel' placeholder={currentUser?.MobileNo ?? "Phone No."}
                                    value={number} onChange={(e) => setNumber(e.target.value)}
                                    style={{ height: "45px", width: '80%', paddingLeft: 7 }}
                                />
                                {/* {
                                    number?.length > 0 &&
                                    <Button className='font-inter fs-6 text-black border-0' style={{ background: "#fff", borderRightWidth: 1, borderRightColor: "#D9D9D9" }} onClick={() => onChangeNumber()} >Save</Button>
                                    || */}
                                <Button className='font-inter fs-6 text-black border-0' style={{ background: "#fff", borderRightWidth: 1, borderRightColor: "#D9D9D9" }}>Mobile</Button>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
                {dispr_val > 0 && (loading &&
                    <Button className="px-5 border-0 font-w600 font-inter fs-6 lh-base d-block mx-auto my-5"
                        style={{ background: "#9FE770", borderRadius: "40px", height: '48px' }}><img src="/assets/img/loading.gif" alt="Unitedwecare" style={{ height: 26, width: 26 }} />
                    </Button>
                    ||
                    <Button
                        id='professional_complete_payment'
                        className="col-11 col-md-4 px-3 border-0 font-w600 font-inter fs-6 lh-base d-block mx-auto my-5 text-black"
                        style={{ background: "#9FE770", borderRadius: "40px", height: '48px' }}
                        onClick={onCheckout}
                    >Confirm Session
                    </Button>)
                }
            </div>
        </div >
    )
}

export default ExpertBookingPreview