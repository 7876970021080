import React from 'react'
import '.././Plans.css'

const ChoiceArea = () => {
    return (
        <div>
            {/* <!------ choice-area ------> */}
            <div className="choice-area mobile-display-none desktop-display">
                <div className="container">
                    <h2 className="heading1">What makes us your best choice</h2>
                    <div className="choice-inner">
                        <div className="row">
                            <div className="col=lg-4 col-md-4">
                                <div className="circle-big">
                                    <div className="text">
                                        86%
                                    </div>
                                    <svg>
                                        <circle className="bg" cx="57" cy="57" r="52"></circle>
                                        <circle className="progress" cx="57" cy="57" r="52"></circle>
                                    </svg>
                                </div>
                                <p className="c-para">86% reported reduction in
                                    sleep issues with a sleep
                                    wellness course</p>
                            </div>
                            <div className="col=lg-4 col-md-4">
                                <div className="circle-big">
                                    <div className="text">
                                        95%
                                    </div>
                                    <svg>
                                        <circle className="bg" cx="57" cy="57" r="52"></circle>
                                        <circle className="progress" cx="57" cy="57" r="52"></circle>
                                    </svg>
                                </div>
                                <p className="c-para">95% reported low stress
                                    with our burnout courses</p>
                            </div>
                            <div className="col=lg-4 col-md-4">
                                <div className="circle-big">
                                    <div className="text">
                                        72%
                                    </div>
                                    <svg>
                                        <circle className="bg" cx="57" cy="57" r="52"></circle>
                                        <circle className="progress" cx="57" cy="57" r="52"></circle>
                                    </svg>
                                </div>
                                <p className="c-para">72 % reported
                                    improvement in the
                                    quality of relationships</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoiceArea