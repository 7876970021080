import React from 'react'
import '.././Plans.css'

const ReasonArea = () => {
    return (
        <div>
            {/* <!------ reason-area (desktop-display) ------> */}
            <div className="reason-area mobile-display-none desktop-display">
                <div className="container">
                    <div className="reason-inner">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <h4>There are myraid reasons why<br />
                                    you should talk to us</h4>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="Subscribe-btn">
                                    <button type="button" className="btn reason-btn">
                                        <a href="https://www.unitedwecare.com/us/contact-us/">
                                            <img src="assets/img/whatsapp.png" alt='' />Connect with Us
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <img src="assets/img/Vector.jpg" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!------ reason-area  (mobile-display)------> */}
            <div className="reason-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="reason-inner">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <h4>There are myraid reasons why<br />
                                    you should talk to us</h4>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="Subscribe-btn">
                                    <button type="button" className="btn reason-btn"><a href="https://www.unitedwecare.com/us/contact-us/">Connect with Us</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReasonArea