import { FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { usOnBoarding_Save_payment, usOnBoarding_registrationStatus } from '../core/_request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const RegisterAsProfessionalPaymentTab = (props) => {
    const [data, setData] = useState({});
    const [status, setStatus] = useState("Personal");
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
    const NUMERIC_DASH_REGEX = /^[0-9]+$/;
    const ALPHANUMERIC_DASH_REGEX = /^[a-zA-Z .&-]+$/;

    var sData = JSON.parse(localStorage.getItem("User_Registered_Info"))?.payment;
    useEffect(() => {
        setData({
            type: sData?.type,
            payee_first_name: sData?.payee_first_name,
            payee_last_name: sData?.payee_last_name,
            ssn: sData?.ssn,
            confirmSsn: sData?.ssn,
            payee_company_name: sData?.payee_company_name,
            fein: sData?.fein,
            confirmRoutingNumber: sData?.routing_number,
            confirmAccountNumber: sData?.account_number,
            confirmfeinNumber: sData?.fein,
            routing_number: sData?.routing_number,
            account_number: sData?.account_number,
            ssn_message: null,
            Routing_number_message: null,
            Account_number_message: null,
            fein_message: null,
            confirmCompanyRoutingNumber: sData?.routing_number,
            confirmCompanyAccountNumber: sData?.account_number,
            company_routing_number: sData?.routing_number,
            company_account_number: sData?.account_number,
        })
    }, []);

    const radioHandler = (status) => {
        setStatus(status);
        setData({
            ...data,
            type: status,
            payee_first_name: null,
            payee_last_name: null,
            ssn: null,
            payee_company_name: null,
            fein: null,
            routing_number: null,
            account_number: null,
            ssn_message: null,
            Routing_number_message: null,
            Account_number_message: null,
            fein_message: null
        })
    };

    const handelCompareSSN = (event) => {
        // setData({ ...data, confirmSsn: event.target.value });
        var string = event.target.value;
        string = string.replace(/-/g, "");
        var regex = /^([^\s]{3})([^\s]{2})([^\s]{4})$/g;
        var match = regex.exec(string);
        if (match) {
            match.shift();
            string = match.join("-")
        }
        setData({ ...data, confirmSsn: string })
        if (data.ssn === string) {
            setData({ ...data, ssn_message: 'SSN match', ssn_color: 'green', confirmSsn: string });
        } else {
            setData({ ...data, ssn_message: 'SSN did not match', ssn_color: 'red', confirmSsn: string });
        }
    }



    const onSSNChange = (event) => {
        var string = event.target.value;
        string = string.replace(/-/g, "");
        var regex = /^([^\s]{3})([^\s]{2})([^\s]{4})$/g;
        var match = regex.exec(string);
        if (match) {
            match.shift();
            string = match.join("-")
        }
        setData({ ...data, ssn: string })
    }

    const handelCompareRouting_number = (event) => {
        if (data?.routing_number === event.target.value) {
            setData({ ...data, Routing_number_message: 'Routing number match', Routing_number_color: 'green', confirmRoutingNumber: event.target.value });
        } else {
            setData({ ...data, Routing_number_message: 'Routing number did not match', Routing_number_color: 'red', confirmRoutingNumber: event.target.value });
        }
    }

    const handelAccount_number = (event) => {
        if (data?.account_number === event.target.value) {
            setData({ ...data, Account_number_message: 'Routing number match', Account_number_color: 'green', confirmAccountNumber: event.target.value });
        } else {
            setData({ ...data, Account_number_message: 'Routing number did not match', Account_number_color: 'red', confirmAccountNumber: event.target.value });
        }
    }

    const handelCompanyCompareRouting_number = (event) => {
        if (data?.company_routing_number === event.target.value) {
            setData({ ...data, cRouting_number_message: 'Routing number match', cRouting_number_color: 'green', confirmCompanyRoutingNumber: event.target.value });
        } else {
            setData({ ...data, cRouting_number_message: 'Routing number did not match', cRouting_number_color: 'red', confirmCompanyRoutingNumber: event.target.value });
        }
    }

    const handelCompanyAccount_number = (event) => {
        if (data?.company_account_number === event.target.value) {
            setData({ ...data, cAccount_number_message: 'Routing number match', cAccount_number_color: 'green', confirmCompanyAccountNumber: event.target.value });
        } else {
            setData({ ...data, cAccount_number_message: 'Routing number did not match', cAccount_number_color: 'red', confirmCompanyAccountNumber: event.target.value });
        }
    }

    const handelFein = (event) => {
        if (data?.fein === event.target.value) {
            setData({ ...data, fein_message: 'Fein match', fein_color: 'green', confirmfeinNumber: event.target.value });
        } else {
            setData({ ...data, fein_message: 'Fein did not match', fein_color: 'red', confirmfeinNumber: event.target.value });
        }
    }

    const handelNextSave = () => {
        var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
        var body = { "user_id": user_id }
        usOnBoarding_registrationStatus(body).then((resp) => {
            debugger
            if (resp.data.profile_image) {
                if (status === "Personal") {
                    if (
                        data.payee_first_name === undefined ||
                        data.payee_first_name === null ||
                        data.payee_first_name === ""
                    ) {
                        alert("Enter First Name");
                        return false;
                    } else if (
                        data.payee_last_name === undefined ||
                        data.payee_last_name === null ||
                        data.payee_last_name === ""
                    ) {
                        alert("Enter Last Name");
                        return false;
                    } else if (
                        data.ssn === undefined ||
                        data.ssn === null ||
                        data.ssn === ""
                    ) {
                        alert("Enter SSN and Confirm");
                        return false;
                    } else if (
                        data.ssn.length !== 11
                    ) {
                        alert("Enter valid SSN");
                        return false;
                    } else if (
                        data.ssn !== data.confirmSsn
                    ) {
                        alert("Confirm SSN");
                        return false;
                    } else if (
                        data.routing_number === undefined ||
                        data.routing_number === null ||
                        data.routing_number === ""
                    ) {
                        alert("Enter Routing Number Confirm");
                        return false;
                    } else if (
                        data.routing_number !== data.confirmRoutingNumber
                    ) {
                        alert("Confirm Routing Number ");
                        return false;
                    } else if (
                        data.account_number === undefined ||
                        data.account_number === null ||
                        data.account_number === ""
                    ) {
                        alert("Enter Account Number Confirm");
                        return false;
                    } else if (
                        data.account_number !== data.confirmAccountNumber
                    ) {
                        alert("Confirm Account Number ");
                        return false;
                    }
                    else {
                        var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
                        var body = {
                            "user_id": user_id,
                            "type": "Personal",
                            "payee_first_name": data.payee_first_name,
                            "payee_last_name": data.payee_last_name,
                            "ssn": data.ssn,
                            "routing_number": data.routing_number,
                            "account_number": data.account_number,
                        }
                        usOnBoarding_Save_payment(body).then((resp) => {
                            // console.log("resp", resp)
                            if (resp?.code === 200) {
                                alert('Payment Details Saved Successfully');
                                localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                                props.onNext();
                            }
                            else {
                                alert(resp?.data?.error ?? resp?.message)
                            }
                        })
                    }
                }
                else if (status === "Company") {
                    if (
                        data.payee_company_name === undefined ||
                        data.payee_company_name === null ||
                        data.payee_company_name === ""
                    ) {
                        alert("Company Name");
                        return false;
                    } else if (
                        data.fein === undefined ||
                        data.fein === null ||
                        data.fein === ""
                    ) {
                        alert("Enter Fein Number");
                        return false;
                    } else if (
                        data.fein !== data.confirmfeinNumber
                    ) {
                        alert("Confirm FEIN number and confirm");
                        return false;
                    } else if (
                        data.company_routing_number === undefined ||
                        data.company_routing_number === null ||
                        data.company_routing_number === ""
                    ) {
                        alert("Enter Routing Number");
                        return false;
                    } else if (
                        data.company_routing_number !== data.confirmCompanyRoutingNumber
                    ) {
                        alert("Confirm Routing number");
                        return false;
                    } else if (
                        data.company_account_number === undefined ||
                        data.company_account_number === null ||
                        data.company_account_number === ""
                    ) {
                        alert("Enter Account Number");
                        return false;
                    } else if (
                        data.company_account_number !== data.confirmCompanyAccountNumber
                    ) {
                        alert("Confirm account number");
                        return false;
                    }

                    else {
                        var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
                        var body = {
                            "user_id": user_id,
                            "type": "Company",
                            "payee_company_name": data.payee_company_name,
                            "fein": data.fein,
                            "routing_number": data.company_routing_number,
                            "account_number": data.company_account_number,
                        }
                        usOnBoarding_Save_payment(body).then((resp) => {
                            // console.log("resp", resp)
                            if (resp?.code === 200) {
                                alert('Payment Details Saved Successfully');
                                localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                                props.onNext();
                            }
                            else {
                                alert(resp?.data?.error ?? resp?.message)
                            }
                        })
                    }
                }
                else {
                    toast("Not match your type.")
                }
            }
            else {
                toast("Please upload profile picture", {
                    toastId: "User Profile Picture"
                })
            }
        })
    }

    const handelSkip = () => {
        props.onNext();
    }

    return (
        <div>
            <div className='d-flex align-items-center gap-3 py-3 rounded-top px-3' style={{ background: "#C4E5F9" }}>
                <img src='/assets/img/Vector(27).png' alt='' />
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>Payment</p>
            </div>
            <div className='mx-3 my-2'>
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>
                    Payee Information
                </p>
                <p className='font-inter' style={{ color: "#6A6C6A", fontSize: "16px" }}>
                    In the case, we are unable to verify the payment information, we will need additional information for verification
                </p>
            </div>
            <div className='mx-3 my-2'>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="Personal"
                >
                    <FormControlLabel onClick={(e) => radioHandler("Personal")} value="Personal" control={<Radio />} label="Personal" />
                    <FormControlLabel onClick={(e) => radioHandler("Company")} value="Company" control={<Radio />} label="Company" />
                </RadioGroup>

                {status === "Personal" &&
                    <>
                        <div className='mx-3'>
                            <Row className="my-3 gap-3">
                                {/* <Form.Group as={Col} controlId="formGridFirstName" className='col-4'>
                                    <Form.Control type="text" placeholder="First Name" onChange={(e) => setData({ ...data, payee_first_name: e.target.value })} />
                                </Form.Group> */}
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="First Name"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.payee_first_name}
                                    onChange={(e) => setData({ ...data, payee_first_name: e.target.value })}
                                    className='col-4'
                                    inputProps={{ maxLength: 20 }}
                                    onKeyDown={(event) => {
                                        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />

                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Last Name"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.payee_last_name}
                                    onChange={(e) => setData({ ...data, payee_last_name: e.target.value })}
                                    className='col-4'
                                    inputProps={{ maxLength: 20 }}
                                    onKeyDown={(event) => {
                                        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Row>
                            <Row className="my-3 gap-3">
                                <TextField
                                    inputProps={{ minLength: 9, maxLength: 9 }}
                                    required
                                    id="outlined-password-input"
                                    label="SSN"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.ssn}
                                    onChange={
                                        // (e) => setData({ ...data, ssn: e.target.value })
                                        onSSNChange
                                    }
                                    className='col-4'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />

                                <TextField
                                    inputProps={{ minLength: 9, maxLength: 9 }}
                                    required
                                    error={data?.ssn_color == 'red'}
                                    id="filled-error-helper-text"
                                    label="Confirm SSN"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    onChange={handelCompareSSN}
                                    value={data?.confirmSsn}
                                    className='col-4'
                                    // onKeyPress={(event) => {
                                    //     if (!/[0-9]/.test(event.key)) {
                                    //         event.preventDefault();
                                    //     }
                                    // }}
                                    // sx={{border: data?.ssn_color}}
                                    helperText={<span style={{ color: data?.ssn_color, fontWeight: 'bold', fontSize: '14px' }}>{data?.ssn_message}</span>}
                                    InputProps={data?.ssn_color && data?.ssn_color == 'green' && {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <CheckIcon sx={{ color: "#2F5711" }} />
                                            </InputAdornment>
                                        )
                                    }
                                        ||
                                        data?.ssn_color == 'red' &&
                                        {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ErrorIcon sx={{ color: "#A8200D" }} />
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                />
                            </Row>
                            <hr />
                            <p className='font-inter font-w500 fs-4 lh-lg' style={{ color: "#21231E", }}>
                                Direct Deposit Information
                            </p>
                            <p className='font-inter fs-6 lh-lg' style={{ color: "#6A6C6A" }}>
                                Please use a checking account. We cannot make payments to savings accounts.
                            </p>
                            <Row className="my-3 gap-3">
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Routing Number"
                                    type="text"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.routing_number}
                                    onChange={(e) => setData({ ...data, routing_number: e.target.value })}
                                    className='col-5'
                                />
                                <TextField
                                    required
                                    error={data?.Routing_number_color == 'red'}
                                    id="filled-error-helper-text"
                                    label="Confirm Routing Number"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    onChange={handelCompareRouting_number}
                                    value={data?.confirmRoutingNumber}
                                    className='col-5'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    helperText={<span style={{ color: data?.Routing_number_color, fontWeight: 'bold', fontSize: '14px' }}>{data?.Routing_number_message}</span>}
                                    InputProps={data?.Routing_number_color == 'green' && {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <CheckIcon sx={{ color: "#2F5711" }} />
                                            </InputAdornment>
                                        )
                                    }
                                        ||
                                        data?.Routing_number_color == 'red' &&
                                        {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ErrorIcon sx={{ color: "#A8200D" }} />
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                />
                            </Row>
                            <Row className="my-3 gap-3">
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Checking Account Number"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.account_number}
                                    onChange={(e) => setData({ ...data, account_number: e.target.value })}
                                    className='col-5'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />

                                <TextField
                                    required
                                    error={data?.Account_number_color == 'red'}
                                    id="filled-error-helper-text"
                                    label="Confirm Checking Account Number"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    onChange={handelAccount_number}
                                    value={data?.confirmAccountNumber}
                                    className='col-5'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    helperText={<span style={{ color: data?.Account_number_color, fontWeight: 'bold', fontSize: '14px' }}>{data?.Account_number_message}</span>}
                                    InputProps={data?.Account_number_color == 'green' && {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <CheckIcon sx={{ color: "#2F5711" }} />
                                            </InputAdornment>
                                        )
                                    }
                                        ||
                                        data?.Account_number_color == 'red' &&
                                        {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ErrorIcon sx={{ color: "#A8200D" }} />
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                />
                            </Row>
                        </div>
                        <div className='d-flex align-items-center justify-content-end my-5'>
                            <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                                <Button onClick={handelSkip} className='rounded-3 text-black bg-white' style={{ border: "1px solid #21231E" }}>
                                    Skip
                                </Button>
                                <Button onClick={handelNextSave} className='rounded-3 border-0 text-black' style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                                    Next
                                </Button>
                            </ButtonGroup>
                        </div>
                    </>
                }
                {status === "Company" &&
                    <>
                        <div className='mx-3'>
                            <Row className="my-3">
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Company Name"
                                    type="text"
                                    onKeyDown={(event) => {
                                        if (!ALPHANUMERIC_DASH_REGEX.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.payee_company_name}
                                    onChange={(e) => setData({ ...data, payee_company_name: e.target.value })}
                                    className='col-12'
                                    helperText={<label className='font-inter' style={{ color: "#6A6C6A", fontSize: "12px" }}>Please enter business name exactly as it shows on your legal documents.</label>}
                                />
                            </Row>
                            <Row className="my-3 gap-3">
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="FEIN (Tax ID/EIN)"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={data?.fein}
                                    onChange={(e) => setData({ ...data, fein: e.target.value })}
                                    className='col-4'
                                />

                                <TextField
                                    required
                                    error={data?.fein_color == 'red'}
                                    id="filled-error-helper-text"
                                    label="Confirm FEIN (Tax ID/EIN)"
                                    type="text"
                                    InputLabelProps={data && { shrink: true }}
                                    onChange={handelFein}
                                    className='col-5'
                                    value={data?.confirmfeinNumber}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    helperText={<span style={{ color: data?.fein_color, fontWeight: 'bold', fontSize: '14px' }}>{data?.fein_message}</span>}
                                    InputProps={data?.fein_color == 'green' && {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <CheckIcon sx={{ color: "#2F5711" }} />
                                            </InputAdornment>
                                        )
                                    }
                                        || data?.fein_color == 'red' &&
                                        {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ErrorIcon sx={{ color: "#A8200D" }} />
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                />
                            </Row>
                            <hr />
                            <p className='font-inter font-w500 fs-4 lh-lg' style={{ color: "#21231E", }}>
                                Direct Deposit Information
                            </p>
                            <p className='font-inter fs-6 lh-lg' style={{ color: "#6A6C6A" }}>
                                Please use a checking account. We cannot make payments to savings accounts.
                            </p>
                            <Row className="my-3 gap-3">
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Routing Number"
                                    type="text"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.company_routing_number}
                                    onChange={(e) => setData({ ...data, company_routing_number: e.target.value })}
                                    className='col-5'
                                />
                                <TextField
                                    required
                                    error={data?.cRouting_number_color == 'red'}
                                    id="filled-error-helper-text"
                                    label="Confirm Routing Number"
                                    type="text"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={data?.confirmCompanyRoutingNumber}
                                    onChange={handelCompanyCompareRouting_number}
                                    InputLabelProps={data && { shrink: true }}
                                    className='col-5'
                                    helperText={<span style={{ color: data?.cRouting_number_color, fontWeight: 'bold', fontSize: '14px' }}>{data?.cRouting_number_message}</span>}
                                    InputProps={data?.cRouting_number_color == 'green' && {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <CheckIcon sx={{ color: "#2F5711" }} />
                                            </InputAdornment>
                                        )
                                    }
                                        || data?.cRouting_number_color == 'red' &&
                                        {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ErrorIcon sx={{ color: "#A8200D" }} />
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                />
                            </Row>
                            <Row className="my-3 gap-3">
                                <TextField
                                    required
                                    id="outlined-password-input"
                                    label="Checking Account Number"
                                    type="text"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    InputLabelProps={data && { shrink: true }}
                                    value={data?.company_account_number}
                                    onChange={(e) => setData({ ...data, company_account_number: e.target.value })}
                                    className='col-5'
                                />

                                <TextField
                                    required
                                    error={data?.cAccount_number_message == 'red'}
                                    id="filled-error-helper-text"
                                    label="Confirm Checking Account Number"
                                    type="text"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={data?.confirmCompanyAccountNumber}
                                    onChange={handelCompanyAccount_number}
                                    InputLabelProps={data && { shrink: true }}
                                    className='col-5'
                                    helperText={<span style={{ color: data?.cAccount_number_color, fontWeight: 'bold', fontSize: '14px' }}>{data?.cAccount_number_message}</span>}
                                    InputProps={data?.cAccount_number_color == 'green' && {
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <CheckIcon sx={{ color: "#2F5711" }} />
                                            </InputAdornment>
                                        )
                                    }
                                        || data?.cAccount_number_color == 'red' &&
                                        {
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ErrorIcon sx={{ color: "#A8200D" }} />
                                                </InputAdornment>
                                            )
                                        }
                                    }
                                />
                            </Row>
                        </div>
                        <div className='d-flex align-items-center justify-content-end my-5'>
                            <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                                <Button onClick={handelSkip} className='rounded-3 text-black bg-white' style={{ border: "1px solid #21231E" }}>
                                    Skip
                                </Button>
                                <Button onClick={handelNextSave} className='rounded-3 border-0 text-black' style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                                    Next
                                </Button>
                            </ButtonGroup>
                        </div>
                    </>
                }
            </div>
            <ToastContainer />
        </div>
    )
}

export default RegisterAsProfessionalPaymentTab