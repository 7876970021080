import { useState, useEffect, useRef, forwardRef } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from "@mui/material/FormControl";
import Input from '@mui/material/Input';

import { useAuth } from "../../../auth";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



//TextField
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

//Icons
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from '@mui/material/CircularProgress';



const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const TicketDetailsTableRow = ({
  date,
  detail,
  serialNO,
  topic,
  status,
  ticketID,
  selectedTab,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const formattedDate = moment(date).format("D MMM YYYY");
  const [openReply, setOpenReply] = useState(false);

  const { currentUser, userType, logout } = useAuth();
  const [chatHistory, setChatHistory] = useState([])

  const [chatData, setChatData] = useState({
    chatID: 0,
    clientID: 0,
    clientName: "",
    clientProfilePicture: "",
    clientAppointmentId: 0,
    chatHistory: [],
  });


  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [sendChatValue, setSendChatValue] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");



  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };
  const chatHistoryRef = useRef(null);


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };


  //Get Ticket History


  const postTicketsHistoryData = async () => {
    const payload = {
      ticket_id: ticketID,
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/ticket-reply-list`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const { replies } = await postResponse?.data;

      setChatHistory(replies)
      console.log("data", replies);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendTicketReply = async (ticketID, replyText) => {
    const payload = {
      ticket_id: ticketID,
      reply_text: replyText,
      reply_from: 'expert'
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/ticket-reply`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      if (postResponse?.code === 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }

    } catch (error) {
      console.error("Error:", error);
    }
  }

  const scrollChatToBottom = () => {
    //console.log("chatHistoryRef", chatHistoryRef)
    if (chatHistoryRef.current) {
      const chatContainer = chatHistoryRef.current;
      const lastChatMessage = chatContainer.lastElementChild;

      if (lastChatMessage) {
        lastChatMessage.scrollIntoView({ behavior: "smooth" });
      }
    }

  };

  useEffect(() => {
    scrollChatToBottom();
  }, [chatHistory]);

  const handleCloseReply = () => {
    setOpenReply(false);
  };

  const handleReplyToChat = () => {
    setOpenReply(true);
    postTicketsHistoryData()
    setTimeout(() => {
      scrollChatToBottom()
    }, 100)

  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setShowAttachmentPreview(true);
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
    setShowAttachmentPreview(false);
  };


  // const handleSendChat = () => {
  //   const chatObj = {
  //     Appointment_Id: chatData?.clientAppointmentId,
  //     from_id: currentUser?.UserId,
  //     message: sendChatValue,
  //     timestamp: moment().format("DD/MMM/YYYY HH:mm"),
  //     to_id: chatData?.clientID,
  //   };

  //   //console.log("chatObj", chatObj)
  //   setChatData((prevData) => ({
  //     ...prevData,
  //     chatHistory: [...prevData.chatHistory, chatObj],
  //   }));

  //   setSendChatValue("");
  //   postChat(
  //     chatObj.from_id,
  //     chatObj.to_id,
  //     chatObj.message,
  //     chatObj.Appointment_Id
  //   );
  // };

  const handleTextChange = (e) => {
    setSendChatValue(e.target.value);
  };

  const handleReply = () => {
    //console.log(sendChatValue)
    let lastTicketID, lastSequence, currentDateTime, ticketID;
    if (chatHistory?.length > 0) {

      lastTicketID = chatHistory[chatHistory?.length - 1].id
      ticketID = chatHistory[chatHistory?.length - 1]?.ticket_id
      lastSequence = chatHistory[chatHistory?.length - 1].sequence
      currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

      setChatHistory((prevData) => ([
        ...prevData,
        {
          id: +lastTicketID + 1,
          ticket_id: +ticketID,
          reply_text: sendChatValue,
          reply_from: "expert",
          sequence: lastSequence,
          reply_time: currentDateTime
        }
      ]))

    } else {
      lastTicketID = 1;
      lastSequence = 1;
      ticketID = 1;
      currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

      setChatHistory([
        {
          id: +lastTicketID + 1,
          ticket_id: +ticketID,
          reply_text: sendChatValue,
          reply_from: "expert",
          sequence: lastSequence,
          reply_time: currentDateTime
        }
      ])
    }


    setSendChatValue("")

    sendTicketReply((+ticketID), sendChatValue)
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && sendChatValue !== "") {
      // Call a function when Enter key is pressed
      handleReply()
    }
  };

  // const tempChatObj = [
  //   {
  //     "id": 1,
  //     "reply_from": "uwc",
  //     "reply_text": "Please be patient it took 7 days",
  //     "reply_time": "2023-08-18 12:36:08",
  //     "sequence": 1,
  //     "expert_reply": false,
  //   },
  //   {
  //     "id": 2,
  //     "reply_from": "expert",
  //     "reply_text": "payment not recieved",
  //     "reply_time": "2023-08-18 12:36:28",
  //     "sequence": 2,
  //     "expert_reply": true,
  //   },
  //   {
  //     "id": 16,
  //     "reply_from": "chandu",
  //     "reply_text": "you will recieve your payment today",
  //     "reply_time": "2023-08-25 16:59:25",
  //     "sequence": 3,
  //     "expert_reply": false,
  //   },
  //   {
  //     "id": 17,
  //     "reply_from": "arti khanijo",
  //     "reply_text": "still payment not recieved",
  //     "reply_time": "2023-08-25 17:00:26",
  //     "sequence": 4,
  //     "expert_reply": true,
  //   },
  //   {
  //     "id": 18,
  //     "reply_from": "arti khanijo",
  //     "reply_text": "Test",
  //     "reply_time": "2023-08-26 17:00:26",
  //     "sequence": 5,
  //     "expert_reply": true,
  //   }

  // ]
  // let testChatHistory = tempChatObj?.sort((a, b) => a.sequence - b.sequence)
  //   ?.reduce((acc, chat) => {
  //     const isCurrentUser = chat.expert_reply == true;

  //     // Check if there are any chat groups created so far
  //     if (acc.length === 0) {
  //       // If this is the first chat, create a new group
  //       acc.push({
  //         expertReply: chat.expert_reply,
  //         messages: [chat],
  //       });
  //     } else {
  //       // Get the last chat group
  //       const prevChatGroup = acc[acc.length - 1];

  //       // Check if the expert_reply property has changed
  //       if (prevChatGroup.expertReply !== chat.expert_reply) {
  //         // If it has changed, create a new group
  //         acc.push({
  //           expertReply: chat.expert_reply,
  //           messages: [chat],
  //         });
  //       } else {
  //         // If it hasn't changed, add the message to the current group
  //         prevChatGroup.messages.push(chat);
  //       }
  //     }

  //     return acc;
  //   }, [])


  console.log("ChatHistory", chatHistory)


  return (
    <>
      <TableRow
        className={`TicketDetails--TableRow ${status == "Resolved" ? "" : "SelectedTableRow"
          }`}
        sx={{
          backgroundColor: "#FFF",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell className="Table--SRNO">
          <p>{serialNO}</p>
        </TableCell>
        <TableCell className="Table--Topic">
          <div>
            <p>{topic}</p>
            <span>{formattedDate}</span>
          </div>
        </TableCell>

        <TableCell className="Table--Detail">
          <p>{detail}</p>
        </TableCell>

        {/* <TableCell className="Table--Status">
          {status == "Resolved" ? (
            <p>Resolved</p>
          ) : (
            <p>Ongoing</p>
          )}
        </TableCell> */}

        <TableCell className="Table--Actions">
          {status == "Resolved" ? (
            <div sx={{ color: "#000", textTransform: 'capitalize' }} className="Ticket--Resolved">Resolved</div>
          ) : (
            <div
              sx={{ color: "#000", textTransform: 'capitalize' }} style={{ cursor: 'pointer' }} className="Ticket--Reply"
              onClick={() => handleReplyToChat()}
            >Reply</div>
          )}
        </TableCell>
      </TableRow>


      {/* Reply to ticket */}
      <Dialog
        className="ReplyTicket--Dialog"
        open={openReply}
        onClose={handleCloseReply}
      >

        <DialogContent>
          <div className="d-flex align-items-center justify-content-between">
            <div className="HelpChat--Header">
              <p>{topic}</p>
              <span>{formattedDate}</span>
            </div>
            <Button onClick={handleCloseReply}>
              <HighlightOffOutlinedIcon />
            </Button>
          </div>

          <div className="HelpChat--Chats" ref={chatHistoryRef}>

            {chatHistory?.sort((a, b) => a.sequence - b.sequence)
              ?.reduce((acc, chat) => {
                const isCurrentUser = chat.expert_reply == true;

                // Check if there are any chat groups created so far
                if (acc.length === 0) {
                  // If this is the first chat, create a new group
                  acc.push({
                    expertReply: chat.expert_reply,
                    messages: [chat],
                  });
                } else {
                  // Get the last chat group
                  const prevChatGroup = acc[acc.length - 1];

                  // Check if the expert_reply property has changed
                  if (prevChatGroup.expertReply !== chat.expert_reply) {
                    // If it has changed, create a new group
                    acc.push({
                      expertReply: chat.expert_reply,
                      messages: [chat],
                    });
                  } else {
                    // If it hasn't changed, add the message to the current group
                    prevChatGroup.messages.push(chat);
                  }
                }

                return acc;
              }, [])
              .map((chat, index) => {

                //console.log("chat", chat);
                return (
                  <div
                    className={`${chat.expertReply ? "sentChat" : "receivedChat"
                      } d-flex align-items-start gap-3`}
                    key={index}
                  >

                    <div className="messagesArea">
                      <img src={chat.expertReply ? currentUser?.ProfileImage : "/assets/img/uwc-logo.png"} width={46} height={46} style={{ objectFit: 'cover', borderRadius: '50%' }} />

                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          {
                            chat.expertReply ? (
                              <b style={{ textTransform: 'capitalize' }}>{currentUser?.Name}</b>
                            ) : (
                              <b>Support team</b>
                            )
                          }
                          <span>{moment(chat.reply_time).format('DD MMM YYYY hh:mm A')}</span>
                        </div>
                        <div>
                          {chat.messages.map((message, messageIndex) => (
                            <div key={messageIndex}>{message.reply_text}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              )}

          </div>
          <div className="HelpChat--Send">
            <FormControl
              sx={{ width: "100%" }}
              className="rounded-3 TextBox--Container"
              variant="outlined"
            >
              {selectedFile && (
                <div className="SelectedFile">
                  <AttachFileIcon
                    sx={{ fill: "#1B8BCF", transform: "rotate(45deg)" }}
                  />
                  <div>
                    <p>{selectedFile.name}</p>
                    <span>{`${(selectedFile.size / 1024).toFixed(
                      2
                    )} KB`}</span>
                  </div>

                  <IconButton
                    onClick={handleRemoveAttachment}
                    aria-label="remove"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}

              <div className="Input--Area">
                <Input
                  className="TextBox"
                  id="outlined-adornment-weight"
                  sx={{ background: "#FFF", borderRadius: "10px" }}
                  value={sendChatValue}
                  onChange={handleTextChange}
                  onKeyDown={handleKeyPress}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Type you message here..."
                />
                <Button onClick={handleReply}>
                  Send
                </Button>
              </div>

            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>


      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>
  );
};

export default TicketDetailsTableRow;
