import { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../auth';
import { AppContext } from '../../context';
import { useNavigate } from 'react-router-dom';

const PlanDetailsLanding = ({ type }) => {

    const [pageValue, setPageValue] = useState({})
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (type == "Platinum") {
            setPageValue({
                type,
                price: "$134.99 / Month",
                sessionTime: 'UNLIMITED',
                componentCSS: "LTR"
            })
        } else {
            setPageValue({
                type,
                price: "$14.99/ Month",
                sessionTime: '3 Hour',
                componentCSS: "RTL"
            })
        }
    }, [])

    const onClickSubscribe = (pageType) => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            if (pageType === "Platinum") {
                sessionStorage.setItem("subscriptionID", 2);
                navigate(`/subscription-order-summary`)
            }
            else {
                sessionStorage.setItem("subscriptionID", 11);
                navigate(`/subscription-order-summary`)
            }
        }
    }

    return (
        <div className={`d-flex gap-5 PlanDetails--Header ${pageValue.componentCSS}`}>

            <div className='Header--Left'>
                <h2><span>{type}</span> Care</h2>
                <h6>{pageValue.price}</h6>

                <div className='PackOffers'>
                    <div className='Offers--Item'>
                        <div className='Item--Number ItemSelected'>1</div>
                        <div className='Item--Desc'>
                            <p>Personalized 1-on-1 Sessions - <span>{pageValue.sessionTime}</span></p>
                            <span>You get unlimited one-on-one sessions with our top, highly qualified professionals.</span>
                        </div>
                    </div>
                    <div className='Offers--Item'>
                        <div className='Item--Number'>2</div>
                        <div className='Item--Desc'>
                            <p>Chat with Stella - anytime, anywhere</p>
                        </div>
                    </div>
                    <div className='Offers--Item'>
                        <div className='Item--Number'>3</div>
                        <div className='Item--Desc'>
                            <p>Premium Courses</p>
                        </div>
                    </div>
                    <div className='Offers--Item'>
                        <div className='Item--Number'>4</div>
                        <div className='Item--Desc'>
                            <p>Enriching Content</p>
                        </div>
                    </div>
                </div>

                <button onClick={() => onClickSubscribe(pageValue.type)}>Subscribe</button>
            </div>
            {
                pageValue.type == "Platinum" ? <img src='/assets/img/planDetails/PlatinumCareLanding.png' /> : <img src='/assets/img/planDetails/PremiumCareLanding.png' />
            }


        </div>
    )
}

export default PlanDetailsLanding