import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardCourse from './DashboardCourse';
import DashboardMyWorksheetTab from '../../../components/course/DashboardMyWorksheetTab'
import DashboardMyCoursesTab from '../../../components/course/DashboardMyCoursesTab'
import DashboardAppointmentHistoryTab from '../../../components/course/DashboardAppointmentHistoryTab';
import CourseQuestions from '../../../components/course/CourseQuestions';
import CoursePreview from '../../../components/course/CoursePreview';
import CourseBuffer from '../../../components/course/CourseBuffer';

const DashRouting = () => {
    return (
        <Routes>
            <Route element={<DashboardCourse />}>
                <Route path='/appointments-history' element={<DashboardAppointmentHistoryTab />} />
                <Route path='/my-worksheets' element={<DashboardMyWorksheetTab />} />
                <Route path='/my-courses' element={<DashboardMyCoursesTab />} />
                <Route path='/' element={<Navigate replace to="my-courses" />} />
            </Route>
            <Route path='course-preview/:id' element={<CoursePreview />} />
            <Route path='course-questions/:id' element={<CourseQuestions />} />
            <Route path='course-loading/:id' element={<CourseBuffer />} />
        </Routes>
    )
}

export default DashRouting;