import React, { useEffect, useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { Outlet, Link } from 'react-router-dom'
import { getCourseDashboardDetails_WebService } from '../../../components/core/_request'
import DashboardStats from '../../../components/course/DashboardStats'

const DashboardCourse = () => {

  return (
    <Row className=''>
      <Col sm={3} className='pe-1 ' style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)" }}>
        <Nav className="flex-column">
          <div className='d-block d-md-none'>
            <DashboardStats />
          </div>
          <Nav.Item className=''>
            <Nav.Link eventKey="My Courses" style={{ boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)" }} className='bg-white text-grey'>
              <Link to="my-courses" className='text-decoration-none d-flex align-items-center gap-2 py-3 px-4 nav-link '>
                <img src="/assets/img/vector(10).png" alt='' /><h1 className='font-inter fs-5 m-0' style={{ color: "#667085" }}>My Courses</h1>
              </Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className=''>
            <Nav.Link eventKey="Appointments History" style={{ boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)" }} className='bg-white text-grey'>
              <Link to="appointments-history" className='text-decoration-none d-flex align-items-center gap-2 py-3 px-4'>
                <img src="/assets/img/vector(9).png" alt='' /><h2 className='font-inter fs-5 m-0' style={{ color: "#667085" }}> Appointments History</h2>
              </Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className=''>
            <Nav.Link eventKey="My Worksheets" style={{ boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)" }} className='bg-white text-grey '>
              <Link to="my-worksheets" className='text-decoration-none d-flex align-items-center gap-2 py-3 px-4'>
                <img src="/assets/img/vector(6).png" alt='' /><h2 className='font-inter fs-5 m-0' style={{ color: "#667085" }}> My Worksheets</h2>
              </Link>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col sm={9} className='ps-1'>
        <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)" }}>
          <div className='d-none d-md-block'>
            <DashboardStats />
          </div>
          <Outlet />
        </div>
      </Col>
    </Row>
  )
}

export default DashboardCourse