import React, { useEffect, useState } from "react";
import { Button, Modal, ModalFooter } from "react-bootstrap";
import { useAuth } from "../auth";
import { Link, useNavigate } from "react-router-dom";
import { LoginByOTPService, SendRequestOTPService } from "./core/_request";
import SignupComponent from "./SignupComponent";
import SignUpDrawer from "./SignUpDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SignInDrawer = () => {
  const [show, setShow] = useState(false);
  const { login, setCurrentUser, userType } = useAuth();
  const [step, setStep] = useState(1);
  const [validationMessage, setValidationMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMailRegistered, setIsMailRegistered] = useState(false);
  const [signeInSigneUp, setSignInSignUp] = useState("signIn");
  const [data, setData] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [counter, setCounter] = useState(0);
  const count_time = process.env.REACT_APP_OTP_TIME;
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();
  //const{retrunurl}=useParams();
  const retrunurl = null;
  const params = new URLSearchParams(window ? window.location.search : {});
  // useEffect(()=>{
  //console.log("params", params.get("retrunurl"))
  // },[]);

  const resendOTP = () => {
    setOtpSent(true);
    setCounter(count_time);
    // debugger
    var req = { Email: data.Email };
    SendRequestOTPService(req).then((resp) => {
      setLoading(false);
      //console.log("SendRequestOTPService resp", resp);
      if (resp != null && resp.ResponseCode == 200) {
        setOtpSent(true);
        setCounter(count_time);
        setStep(2);
      } else if (resp != null && resp.ResponseCode == 401) {
        //console.log("ResponseCode 401");
      }
    });
  };

  const onLogin = () => {
    setValidationMessage([]);
    setLoading(true);
    switch (step) {
      case 1: {
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (!pattern.test(data.Email)) {
          let newValidationMessage = Object.assign(
            [],
            [
              ...validationMessage.filter((o) => o.key != "Email"),
              { key: "Email", message: "not a valid e-mail address" },
            ]
          );
          setValidationMessage(newValidationMessage);
          setLoading(false);
          break;
        }

        var req = { Email: data.Email };
        SendRequestOTPService(req).then((resp) => {
          setLoading(false);
          //console.log("SendRequestOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            setIsMailRegistered(resp.data.IsMailRegistered);
            if (resp.data.IsMailRegistered) {
              setStep(2);
              setOtpSent(true);
              setCounter(count_time);
            } else {
              toast("email address does not exist");
              setTimeout(() => {
                setShowLloginPopup(false);
                navigate("/create-account", { step: 3 });
              }, 2000);
            }
          } else if (resp != null && resp.ResponseCode == 401) {
            //console.log("ResponseCode 401");
          }
        });

        break;
      }
      case 2: {
        var req = {
          Email: data.Email,
          OTP: data?.OTP1 + data?.OTP2 + data?.OTP3 + data?.OTP4,
        };
        console.log("re1 " + req);
        LoginByOTPService(req).then((resp) => {
          //console.log("LoginByOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            if (resp.data.IsLogin) {
              let UserType = resp.data.UserType;
              let profile = resp.data.userdetails;
              profile.UserType = UserType;
              login(resp.data);
              setCurrentUser(profile);
              //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
              //localStorage.setItem("profile", JSON.stringify(profile));

              var retrunurl_local = localStorage.getItem("retrunurl");
              const retrunurl = params.get("retrunurl") || retrunurl_local;
              setShowLloginPopup(false);
              if (retrunurl) {
                //window.location.href=retrunurl;
                localStorage.removeItem("retrunurl");
                navigate(retrunurl);
              } else {
                if (profile.UserType == userType.ENDUSER) {
                  const queryString = window.location.search;
                  const urlParams = new URLSearchParams(queryString);
                  let stellaURL = urlParams.get("stella");

                  //console.log("params", params.get("stella"))
                  let isStellaPage = false;
                  const locationParts = window.location.href
                    .split("?")[0]
                    .split("/");
                  locationParts.map((item) => {
                    if (item === "stella") {
                      isStellaPage = true;
                    }
                  });

                  if (isStellaPage) {
                    window.location.href = window.location.href;
                  } else {
                    navigate("/onboarding-stella");
                  }
                } else if (profile.UserType == userType.EXPERT) {
                  navigate("/expert/dashboard");
                } else {
                  navigate("/");
                }
              }
            } else {
              setLoading(false);
              //setStep(3);
              setShowLloginPopup(false);
              navigate("/create-account", { step: 3 });
            }
          } else {
            let newValidationMessage = Object.assign(
              [],
              [
                ...validationMessage.filter((o) => o.key != "OTP"),
                { key: "OTP", message: resp.ResponseMessage },
              ]
            );
            setValidationMessage(newValidationMessage);
            setLoading(false);
          }
        });

        break;
      }
    }
  };

  const onClickSignUp = () => {
    navigate(
      `/create-account${
        params.get("retrunurl") ? `?retrunurl=${params.get("retrunurl")}` : ""
      }`
    );
    setShowLloginPopup(false);
  };

  const onSwithView = (viewName) => setSignInSignUp(viewName);

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target?.form?.elements[next]?.focus();
      }
    }
  };

  return (
    <>
      <div className="m-0 signInDrawerContainer" style={{ height: "100vh" }}>
        <Modal.Header className="justify-content-end my-5 my-md-0" closeButton>
          <Modal.Title
            className="font-inter fs-5 text-end ms-auto"
            id="example-custom-modal-styling-title"
          >
            Close
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex align-content-center justify-content-center w-100"
          style={{ height: "70vh" }}
        >
          {(signeInSigneUp == "signUp" && (
            <SignUpDrawer step={step} onSwithView={onSwithView} data={data} />
          )) ||
            (signeInSigneUp == "signIn" && (
              <div className="col-md-9 col-12 m-auto">
                {step == 1 && (
                  <>
                    <p className="fs-4 text-center text-black font-inter lh-lg font-w600 py-3">
                      Sign in
                    </p>
                    <div className="">
                      <div className="mb-3 py-1">
                        {/* <label className="form-label" htmlFor="email">Email</label> */}
                        <input
                          disabled={step == 2}
                          placeholder="Enter your email address"
                          className="form-control"
                          style={{ height: "50px" }}
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) =>
                            setData({ ...data, Email: e.target.value })
                          }
                        />
                        <span
                          className="error-message"
                          style={{ color: "#dc3545" }}
                        >
                          {
                            validationMessage.filter((o) => o.key == "Email")[0]
                              ?.message
                          }
                        </span>
                      </div>
                      <Button
                        disabled={loading || (step == 1 && !data?.Email)}
                        id="btn_Email_Submit"
                        className="w-100 my-3 py-2 text-black font-inter px-3 border-0 mb-5 rounded-5"
                        style={{ background: "#C4E5F9", height: "50px" }}
                        onClick={onLogin}
                      >
                        {loading ? "Loading..." : "Request OTP"}
                      </Button>
                      <div className="py-4">
                        <p className="fs-6 py-1 text-center font-inter">
                          Don't have an account?
                        </p>
                        <p className="fs-6 py-1 text-center font-inter d-flex gap-5 align-items-center justify-content-center">
                          {/* <Link
                                                    to={`/create-account${params.get("retrunurl") ? `?retrunurl=${params.get("retrunurl")}` : ""}`}
                                                    onClick={() => onSwithView("signUp")}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#153300"
                                                    }}
                                                    className='d-flex align-items-center gap-2'
                                                >Register here <img src='/assets/img/Vector(19).png' alt='' /></Link> */}
                          <Link
                            onClick={() => setShowLloginPopup(false)}
                            to={`/create-account${
                              params.get("retrunurl")
                                ? `?retrunurl=${params.get("retrunurl")}`
                                : ""
                            }`}
                            style={{
                              textDecoration: "none",
                              color: "#153300",
                            }}
                            className="d-flex align-items-center gap-2"
                          >
                            Register here!{" "}
                            <img src="/assets/img/Vector(19).png" alt="" />
                          </Link>
                        </p>
                      </div>
                    </div>
                    {/* <Link onClick={() => setShowLloginPopup(false)} to="/register-as-professional"
                                        style={{
                                            textDecoration: "none",
                                            color: "#153300"
                                        }}
                                        className='d-flex align-items-center gap-2 text-center w-100'
                                    >Register As a Professional</Link> */}
                  </>
                )}
                {step == 2 && (
                  <div className="mb-3 d-flex flex-column align-items-center justify-content-center gap-3">
                    <img src="/assets/img/Ellipse 3.png" alt="" />
                    <p className="font-inter text-black fs-5 font-w600 lh-1">
                      {data?.Email}
                    </p>
                    <label
                      className="form-label font-inter fs-6 px-5 text-center lh-sm"
                      style={{ color: "#494747" }}
                      htmlFor="OTP"
                    >
                      Enter 4 digit code we sent to your email
                    </label>
                    <form className="d-flex align-items-center justify-content-evenly gap-4 col-12">
                      <input
                        disabled={loading}
                        type="text"
                        id="OTP"
                        className="form-control text-center"
                        style={{ height: "50px", width: "50px" }}
                        name="otp"
                        maxLength={1}
                        onChange={(e) => {
                          setData({ ...data, OTP1: e.target.value });
                        }}
                        onKeyDown={(event) => {
                          // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                          if (
                            event.key != "Backspace" &&
                            !/[0-9]/.test(event.key)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        tabIndex="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        disabled={loading}
                        type="text"
                        id="OTP"
                        className="form-control text-center"
                        style={{ height: "50px", width: "50px" }}
                        name="otp"
                        maxLength={1}
                        onChange={(e) => {
                          setData({ ...data, OTP2: e.target.value });
                        }}
                        onKeyDown={(event) => {
                          // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                          if (
                            event.key != "Backspace" &&
                            !/[0-9]/.test(event.key)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        tabIndex="2"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        disabled={loading}
                        type="text"
                        id="OTP"
                        className="form-control text-center"
                        style={{ height: "50px", width: "50px" }}
                        name="otp"
                        maxLength={1}
                        onChange={(e) => {
                          setData({ ...data, OTP3: e.target.value });
                        }}
                        onKeyDown={(event) => {
                          // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                          if (
                            event.key != "Backspace" &&
                            !/[0-9]/.test(event.key)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        tabIndex="3"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        disabled={loading}
                        type="text"
                        id="OTP"
                        className="form-control text-center"
                        style={{ height: "50px", width: "50px" }}
                        name="otp"
                        maxLength={1}
                        onChange={(e) => {
                          setData({ ...data, OTP4: e.target.value });
                        }}
                        onKeyDown={(event) => {
                          // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                          if (
                            event.key != "Backspace" &&
                            !/[0-9]/.test(event.key)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        tabIndex="4"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                    </form>
                    <span
                      className="error-message"
                      style={{ color: "#dc3545" }}
                    >
                      {
                        validationMessage.filter((o) => o.key == "OTP")[0]
                          ?.message
                      }
                    </span>
                    <Button
                      id="btn_Final_Submit"
                      disabled={
                        loading ||
                        (step == 2 &&
                          !data?.OTP1 &&
                          !data?.OTP2 &&
                          !data?.OTP3 &&
                          !data?.OTP4)
                      }
                      className="w-100 py-2 text-black font-inter px-3 border-0 rounded-5 mb-4"
                      style={{ background: "#C4E5F9", height: "50px" }}
                      onClick={onLogin}
                    >
                      {loading ? "Loading..." : "Login"}
                    </Button>
                    <p className="font-inter fs-6 pt-5">Didn’t recieve code?</p>
                    {/* <Link><p className='font-inter fs-6 text-black'>Resend Code</p></Link> */}
                    {(counter === 0 && otpSent && (
                      <div onClick={resendOTP} style={{ cursor: "pointer" }}>
                        <p className="font-inter fs-6 text-black text-center">
                          Resend Code
                        </p>
                      </div>
                    )) || (
                      <div
                        className="font-inter fs-6 text-black text-center border-0 bg-white"
                        style={{ color: "red !important" }}
                      >
                        Resend OTP {counter}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )) || <></>}
        </Modal.Body>
        {/* <ToastContainer /> */}
        {/* <Modal.Footer>

                </Modal.Footer> */}
      </div>
    </>
  );
};
