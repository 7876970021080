import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { CancelSubscription, CancelSubscriptionReason } from './core/request';
import { useState } from 'react';

const CancelSubscriptions = () => {

    const [reasonData, setReasonData] = useState({});
    const [reasonSelected, setReasonSelected] = useState([]);
    const [cancelReasonText, setCancelReasonText] = useState([]);
    const navigate = useNavigate();

    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    var body = { "user_id": user_id, "reason_id": reasonSelected.map((item) => item.id), "reason": cancelReasonText }
    const onClickNext = () => {
        CancelSubscription(body).then(resp => {
            console.log("ResponseMessage")
            alert(resp.data.message)
        })
        navigate('/cancel-subscription-note')
    }

    const reasonSelection = event => {
        const { checked, value } = event.currentTarget;
        var Json_val = JSON.parse(value)
        setReasonSelected(
            prev => checked
                ? [...prev, Json_val]
                : prev.filter(val => val?.id !== Json_val?.id)
        );
    };


    useEffect(() => {
        CancelSubscriptionReason().then(resp => {
            if (resp.ResponseCode === 200) {
                setReasonData(resp.data)
            }
        })
    }, []);

    const textAreaChange = event => {
        setCancelReasonText(event.target.value);
    }

    return (
        <Container>
            <h6 className='font-inter fs-2 lh-lg my-3'>Cancel Subscription</h6>
            <div className='rounded-3 px-2 px-md-0' style={{ border: "1px solid #1C1B1F1F", boxShadow: "0px 0px 16px 0px #00000012;" }}>
                <div className='row align-items-center justify-content-center my-3'>
                    <div className='col-md-2 d-flex'>
                        <img src='/assets/img/rafiki.png' alt='' className='ms-md-auto mx-auto' />
                    </div>
                    <div className='col-md-8'>
                        <h6 className='font-inter font-w800 fs-1 text-center text-md-start'>Ooops.....We’re sorry to see you going.</h6>
                    </div>
                </div>
                <hr />
                <div className='d-flex flex-column align-items-center justify-content-center my-5'>
                    <div>
                        <h6 className='font-inter font-w700 fs-4'>Why do you want to cancel the subscription ?</h6>
                        <div>
                            <FormGroup>
                                {reasonData && reasonData?.reasons?.map((item, index) =>
                                    <FormControlLabel
                                        checked={reasonSelected?.some(val => val?.id === item?.id)}
                                        onChange={reasonSelection}
                                        className='font-inter font-w500 fs-5'
                                        value={JSON.stringify(item)}
                                        control={<Checkbox />}
                                        label={item?.name} />
                                )}
                                {/* <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Not Value for Money" />
                                <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Quality of content not good enough" />
                                <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Quality of experts not good enough" />
                                <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Not enough content on platform" />
                                <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Not enough experts on platform" />
                                <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Found better deals at competitors platform" />
                                <FormControlLabel className='font-inter font-w500 fs-5' control={<Checkbox />} label="Others" /> */}
                            </FormGroup>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            label="Please write your experience in detail (Optional)"
                            multiline
                            rows={3}
                            className='col-12'
                            onChange={textAreaChange}
                        />
                        {cancelReasonText}
                    </div>
                    <Button className='rounded-5 font-inter border-0 text-black py-3 px-4 my-3' onClick={onClickNext} style={{ background: "#9FE770" }}>Confirm & Cancel</Button>
                </div>
            </div>
        </Container>
    )
}

export default CancelSubscriptions