import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getEmailID } from './core/_request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Footer() {
  const [email, setEmail] = useState('');
  const [subscribeMessage, setSubscribeMessage] = useState('')
  const onClickSubmit = () => {
    var body = {
      "email": email
    }
    getEmailID(body).then(resp => {
      // console.log("resp", resp)
      toast(resp?.ResponseMessage);
      if (resp.ResponseCode == 200) {
        setEmail('')
      }
    });
  }

  const onSubscribeClick = () => {
    fetch(`https://odoo.unitedwecare.ca/new-uwc/community/newsletter/subscribe/${email}`)
      .then(response => response)
      .then(data => setSubscribeMessage(data))
  }

  return (
    <div className='pt-1 pt-md-2 ' style={{ background: "#c6b1ff", marginTop: 30 }}>
      <footer className='container py-1'>
        <div className='row justify-content-between pb-3 py-2'>
          <Col className='my-2' md={3}>
            <Row className='col-12 d-block mx-auto'>
              <img src='/assets/img/Frame 1000002451(1).png' alt='' className='col-12 mb-3' />
            </Row>
            <Row>
              <div className='col-6'>
                <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-1'>Download</h6>
                <div className='d-flex align-items-center gap-1 justify-content-start'>
                  <a href='https://play.google.com/store/apps/details?id=com.UnitedFor.Her&hl=en_IN' target='_blank' rel='noreferrer' className=''>
                    <img src='/assets/img/appleStore.png' alt='' />
                  </a>
                  <a href='https://apps.apple.com/in/app/united-for-her/id1501325167' target='_blank' rel='noreferrer' className=''>
                    <img src='/assets/img/playStore.png' alt='' className='h-100' />
                  </a>
                </div>
              </div>
              <div className='col-6'>
                <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-1'>Follow us on</h6>
                <div className='d-flex gap-2'>
                  <a href='https://instagram.com/unitedwecare_global' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/instagram.png' alt='' />
                  </a>
                  <a href='https://www.facebook.com/unitedwecareglobal' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/facebook.png' alt='' />
                  </a>
                  <a href='https://twitter.com/unitedwecare_in' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/twitter.png' alt='' />
                  </a>
                  <a href='https://youtube.com/@unitedwecare6397' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/yt.png' alt='' />
                  </a>
                  <a href='https://in.linkedin.com/company/unitedwecare' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/Social icon.png' alt='' />
                  </a>
                </div>
              </div>
            </Row>
          </Col>
          <Col className='my-2 px-md-3' md={6}>
            <Row>
              <div className='col-6 col-md-4'>
                <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>About UWC</h6>
                <a href='https://www.unitedwecare.com/about-us/' className='text-decoration-none lh-lg' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >About Us</p>
                </a>
                <a href='https://www.unitedwecare.com/career' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Careers</p>
                </a>
                <a href='https://www.unitedwecare.com/us/contact-us/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Contact Us</p>
                </a>
                <a href='https://www.unitedwecare.com/faq' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >FAQ</p>
                </a>
                <Link to="/term-and-condition" target='_blank' className='text-decoration-none' href='#!'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Terms & Conditions</p>
                </Link>
                <a href='https://www.unitedwecare.com/us/privacy-policy/' target='_blank' className='text-decoration-none' >
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Privacy Policy</p>
                </a>
                <Col className='d-block d-md-none my-3'>
                  <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>For Enterprises</h6>
                  <a href='https://www.unitedwecare.com/in/b2b/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >India</p>
                  </a>
                  <a href='https://www.unitedwecare.com/us/b2b/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >USA</p>
                  </a>
                  <a href='https://www.unitedwecare.com/in/b2b/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Global</p>
                  </a>
                </Col>
              </div>
              <div className='col-6 col-md-4'>
                <Col className='d-none d-md-block'>
                  <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>For Enterprises</h6>
                  <a href='https://www.unitedwecare.com/in/b2b/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >India</p>
                  </a>
                  <a href='https://www.unitedwecare.com/us/b2b/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >USA</p>
                  </a>
                  <a href='https://www.unitedwecare.com/in/b2b/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Global</p>
                  </a>
                </Col>
                <Col className='my-3'>
                  <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 lh-lg mb-0'>Care</h6>
                  <a href='/' className='text-decoration-none' target='' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Professionals</p>
                  </a>
                  <a href='/course' className='text-decoration-none' target='' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Programs</p>
                  </a>
                </Col>
                <div className='col-md-4 d-block d-md-none'>
                  <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Resource Center</h6>
                  <a href='https://www.unitedwecare.com/blog/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Blogs</p>
                  </a>
                  <a href='https://www.unitedwecare.com/explore' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Wellness Library</p>
                  </a>
                  <Col className='my-3'>
                    <a href='/plans' className='text-decoration-none' target='' rel='noreferrer'>
                      <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Plans and Pricing</h6>
                    </a>
                  </Col>
                  <Col className='my-3'>
                    <a href='/register-as-professional' className='text-decoration-none' target='' rel='noreferrer'>
                      <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Get onboarded as expert</h6>
                    </a>
                  </Col>
                </div>
              </div>
              <div className='col-md-4 d-none d-md-block'>
                <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Resource Center</h6>
                <a href='https://www.unitedwecare.com/blog/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Blogs</p>
                </a>
                <a href='https://www.unitedwecare.com/explore' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Wellness Library</p>
                </a>
                <Col className='my-3'>
                  <a href='/plans' className='text-decoration-none' target='' rel='noreferrer'>
                    <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Plans and Pricing</h6>
                  </a>
                </Col>
                <Col className='my-3'>
                  <a href='/register-as-professional' className='text-decoration-none' target='' rel='noreferrer'>
                    <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Get onboarded as expert</h6>
                  </a>
                </Col>
              </div>
            </Row>
          </Col>
          <Col className='my-2' md={3}>
            <h6 style={{ fontSize: "16px" }} className='font-inter fs-6 font-w600 py-1'>Subscribe to get the latest in mental Health!</h6>
            <InputGroup className='mb-3 lh-1 rounded-5 d-flex'>
              <Form.Control onChange={e => setEmail(e.target.value)} className="custom-input border-end-0" style={{ height: "50px" }} type="text" placeholder="Your email address" />
              <Button className='font-inter fs-6 text-white' style={{ background: "#541CD5", height: "50px" }} onClick={onSubscribeClick}>Subscribe</Button>
            </InputGroup>
            {subscribeMessage && <p className='font-inter fs-5 font-w600 text-success lh-lg'>Thank You for Subscribing!</p>}

            <h6 style={{ fontSize: "16px" }} className='font-inter fs-6 font-w600 py-1'>We are certified!</h6>
            <div className='d-flex gap-3 align-items-center'>
              <img src='/assets/img/iso.webp.png' alt='' className='col-3' />
              <img src='/assets/img/hippa.webp.png' alt='' className='col-3' />
              <img src='/assets/img/eu.webp.png' alt='' className='col-3' />
            </div>
          </Col>
        </div>
      </footer>
      <div className='text-center p-3 ' style={{ backgroundColor: '#FFFFFF' }}>
        <a className='text-decoration-none footer-rights-text font-w500' href='#!' style={{ color: "#541CF5" }}>
          Copyright © United We Care 2023. All Rights Reserved
        </a>
      </div>
      {/* <ToastContainer /> */}
    </div>
  )
}
// function footer_bak() {
//   return (
//     <div className='mt-5 pt-3 pt-md-5 ' style={{ background: "#F7F8FA" }}>
//       <footer className='container py-3'>
//         <div className='row justify-content-between'>
//           <Col md={6}>
//             <img src='/assets/img/united-logo.png' alt='' className='pb-4' />
//             <div className='d-flex align-items-center gap-5'>
//               <img src='/assets/img/apple-store.png' alt='' className='col-3' />
//               <img src='/assets/img/play-store.png' alt='' className='col-3' />
//             </div>
//           </Col>
//           <Col md={3}>
//             <h3 className='font-w600 fs-4 pb-4'>Content</h3>
//             <p>About Us</p>
//             <p>Get Professional help</p>
//             <p>Blog</p>
//             <p>Faq</p>
//             <p>Terms & Conditions</p>
//           </Col>
//           <Col md={3}>
//             <h3 className='font-w600 fs-4 pb-4'>Cooperation</h3>
//             <p>UWC in Media</p>
//             <p>Careers</p>
//             <p>Become a Counselor</p>
//             <p>Partnership</p>
//             <div className=''>
//  style={{fontSize:"16px"}}              <h6 className='font-w600 py-2'>Social media</h6>
//               <div className='d-flex gap-3'>
//                 <img src='/assets/img/instagram.png' alt='' />
//                 <img src='/assets/img/facebook.png' alt='' />
//                 <img src='/assets/img/twitter.png' alt='' />
//                 <img src='/assets/img/linkedin.png' alt='' />
//               </div>
//             </div>
//           </Col>
//         </div>
//       </footer>
//       <div className='text-center p-3 ' style={{ backgroundColor: '#142432' }}>
//         <div className='container row gap-5 justify-content-evenly'>
//           <a className='text-white text-decoration-none' href='#!'>
//             Terms & Conditions
//           </a>
//           <a className='text-white text-decoration-none' href='#!'>
//             Privacy Policy
//           </a>
//           <a className='text-white text-decoration-none' href='#!'>
//             Copyright © United We Care. 2023.  All Rights Reserved
//           </a>
//         </div>
//       </div>
//     </div>
//   )
// }
export default Footer
