import React from 'react'
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../context';
import { useAuth } from '../../auth';

const ThreeImgBackground = () => {

    const { planType } = useParams();
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    const onClickSubscribe = () => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            if (planType === "Platinum") {
                sessionStorage.setItem("subscriptionID", 2);
                navigate(`/subscription-order-summary`)
            }
            else {
                sessionStorage.setItem("subscriptionID", 11);
                navigate(`/subscription-order-summary`)
            }
        }
    }

    return (
        <div className='ThreeImg--Background'>
            <img src="/assets/img/planDetails/bg1.png" />
            <img src="/assets/img/planDetails/bg2.png" />
            <img src="/assets/img/planDetails/bg3.png" />

            <div className='Content--Area'>
                <h6>Affordable Healing, Unmatched Support: Your Mental Wellness Journey Begins</h6>
                <button onClick={() => onClickSubscribe()}>Start your Journey</button>
            </div>
        </div>
    )
}

export default ThreeImgBackground