import axios from 'axios'
import {Config} from '../../../core/_request';
import jwtDecode from 'jwt-decode'; 

export const MySubscriptionService = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/transaction/getMySubscription`,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}

export const GetAppointment_Documents_Files_Sent_Expert = (AppointmentId,Id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/appointment/getAppointment_Documents_Files_Expert?AppointmentId=${AppointmentId}&ToUserId=${Id}`,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}

export const GetAppointment_Documents_Files_Sent_User = (AppointmentId,Id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/appointment/GetAppointment_Documents_Files_Sent_User?AppointmentId=${AppointmentId}&ToUserId=${Id}`,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}

export const saveAppointment_Documents_Files = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/appointment/saveAppointment_Documents_Files`, body,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}

export const CancelAppointmentService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/appointment/CancelAppointment`, body,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}

export const RescheduledService = (body) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/appointment/Rescheduled`, body,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}

export const CheckFeedbackStatus = (id) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/rateyourexpert/isfeedbackdoneforappointment?AppointmentId=${id}`, Config()).then(resp => resp.data)
        .then(resp => {
            if (resp.data)
                resp.data = jwtDecode(resp.data);
            return resp;
        });
}
