import React from 'react'
import Plans from '../components/subscriptions/Plans'

const PlansAndPricing = () => {
  return (
    <div>
        <Plans />
    </div>
  )
}

export default PlansAndPricing