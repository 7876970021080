import axios from 'axios'
import {Config} from '../../../core/_request';
import jwtDecode from 'jwt-decode'; 

export const UserSubscriptionForExpertService = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/transaction/getUserSubscriptionForExpert`,Config()).then(resp=>resp.data)
    .then(resp=>{
        if(resp.data)
            resp.data=jwtDecode(resp.data);
        return resp;
    });
}