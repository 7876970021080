import React, { Component } from 'react';
import { Outlet, useLocation } from 'react-router-dom'
// import Header from '../components/Header'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'

export default class Layout extends Component {
  render() {
    return (
      <div>
        <Header2 />
        <div> <Outlet /> </div>
        <Footer />
      </div>
    );
  }
}
