import {UserDetailsService} from '../components/core/_request';

export const useAuth = () => ({
    currentUser : localStorage.getItem("profile")?JSON.parse(localStorage.getItem("profile")):null,
    isLogedIn:async()=>{
        const Authorization=localStorage.getItem("Authorization");
        if(Authorization){
            var UserDetails = await UserDetailsService().then((resp) => resp.data);
            //console.log("UserDetails", UserDetails);
            return UserDetails?.ResponseCode==200 ?? false;
        }
        return false;
    },
    userType:{
        EXPERT:3,
        ENDUSER:4
    },
    setCurrentUser: (profile)=> localStorage.setItem("profile", JSON.stringify(profile)),
    login:(data)=>{
        localStorage.setItem("Authorization", data.token_type + " " + data.access_token);
    },
    logout:()=> {
        localStorage.removeItem("Authorization");
        localStorage.removeItem("profile");
    }
});

 