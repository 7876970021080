import React, { useEffect, useState } from 'react'
import AppointmentCard from '../AppointmentCard'
import { MySubscriptionService } from './core/_request';
import { useAuth } from '../../auth';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../context';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const MyAppointments = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    useEffect(() => {
        MySubscriptionService().then(resp => {
            //console.log("MySubscriptionService resp", resp); 
            if (resp.ResponseCode == 401) {
                logout();
                // navigate("/login");
                setShowLloginPopup(true);
            }
            else
                setData(resp.data);

        });
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
            MySubscriptionService().then(resp => {
                //console.log("MySubscriptionService resp", resp); 
                if (resp.ResponseCode == 401) {
                    logout();
                    // navigate("/login");
                    setShowLloginPopup(true);
                }
                else
                    setData(resp.data);

            })
                .catch(function (error) {
                    console.log(error)
                    setData(null)
                })
        }, 6 * 1000)
        return () => clearInterval(intervalId); //This is important
    }, [data]);

    const onBookAnAppointment = () => {
        navigate('/')
    }

    return (
        <>
            <Helmet>
                <title>My Appointments Dashboard - United We Care</title>
            </Helmet>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container pt-5' >
                    <div>
                        <h1 className='fs-3 text-white mb-5' style={{ color: '#fff' }}>Appointments</h1>
                    </div>
                    <div className='d-flex flex-column gap-3'>
                        {data?.length > 0 && <>{data?.map((item, index) => <AppointmentCard key={index} item={item} />
                        )}<p className='text-center py-5'>--------- No more Appointments ---------</p></> ||
                            <div className='d-flex flex-column align-items-center gap-5' style={{ height: "40vh" }}>
                                <p className='font-inter fs-5'>No Appointment Found</p>
                                <Button className='border-0 col-3 font-inter fs-5' onClick={onBookAnAppointment} style={{ background: "#fff", color: "#000080" }}>
                                    Book Your First Session
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default MyAppointments