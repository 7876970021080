import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";

import ClientDetailsTableRow from "./tables/ClientDetailsTableRow";
import AddAppointmentModal from "./modals/AddAppointmentModal";
import ClientDetailsView from "./component/clients/ClientDetailsView";
import ScreenerDetailsView from "./component/clients/ScreenerDetailsView";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Date Picker

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//Text Field
import TextField from "@mui/material/TextField";

import moment from "moment";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

//Switch
import Switch from "@mui/material/Switch";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import jwtDecode from "jwt-decode";

//import { useHistory } from "react-router";

//Icons
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../auth";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebaseConfig";

//Loader spinner
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

dayjs.extend(localizedFormat);

const ExpertClientDB = () => {
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [selectedScreener, setSelectedScreener] = useState(null);
  const [client, setClient] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientList, setClientList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All Clients");
  const [clientDetails, setClientDetails] = useState({});

  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [clientData, setClientData] = useState({
    slotID: 0,
    clientID: 0,
    clientName: "",
    clientImage: "",
    clientType: "",
    appointmentID: 0,
    appointmentDate: "",
    appointmentTime: "",
  });

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [prescriptionName, setPrescriptionName] = useState("");
  const [prescriptionNote, setPrescriptionNote] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");

  const [openCancelConfirmationModal, setOpenCancelConfirmationModal] =
    useState(false);
  const [bearerToken, setBearerToken] = useState(sessionStorage.getItem("token"));

  //Loaders States
  const [isFileLoading, setIsFileLoading] = useState(null)
  const [isCancelled, setIsCancelled] = useState(null)
  const [isPrescriptionAdded, setIsPrescriptionAdded] = useState(null)
  const [isRescheduled, setIsRescheduled] = useState(null)
  const [isNoteAdded, setIsNoteAdded] = useState(null)

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userType, logout } = useAuth();


  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  let userID;

  const getToken = async () => {
    const payload = {
      OTP: "7777",
      UserName: currentUser?.Emailid,
    };

    const otpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1231243123hjvjVJHVvjv",
      DeviceId: "us_web",
      DeviceType: "web",
      Lat: "us_lat",
      Long: "us_long",
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/Auth/LoginByOTP`,
        {
          method: "POST",
          headers: otpHeaders,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();

      const token = postResponse?.data;

      const value = jwtDecode(token);

      setBearerToken(value.access_token);
      sessionStorage.setItem("token", value.access_token);
      window.location.reload()
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Check if user is logged in
  useEffect(() => {
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate("/login");
      window.location.href = "https://www.unitedwecare.com/";
    } else {
      userID = JSON.parse(localStorage.getItem("profile")).UserId;
    }
  }, [location, navigate]);



  //Use effect to open Client Details
  useEffect(() => {
    if (
      window.location.href.split("?")[1] !== undefined &&
      window.location.href.split("?")[1].includes("id=")
    ) {
      const searchParams = new URLSearchParams(location.search);
      const clientName = searchParams.get("client");
      let formattedClientName = clientName
        ? decodeURIComponent(clientName).replace(/%20/g, " ")
        : "";

      window.location.href.split("?")[1].includes("id=");
      const clientIDFromURL = searchParams.get("id");

      setClientID(clientIDFromURL);
      if (formattedClientName == "" || formattedClientName == null || formattedClientName == undefined) {
        let filteredClientDetails = clientList.filter(item => +item.ID == +clientIDFromURL)
        //console.log("filteredClientDetails",filteredClientDetails[0].Name)
        if (filteredClientDetails.length > 0) {
          //console.log("HERW")
          formattedClientName = filteredClientDetails[0].Name;
        }
      }
      setClient(formattedClientName);

      setShowClientDetails(true);
      let [clientItem] = clientList?.filter(
        (item) => item.ID === clientIDFromURL
      );



      setClientDetails({
        id: clientItem?.ID,
        name: clientItem?.Name,
        image: clientItem?.ProfileImage,
        country: clientItem?.country,
      })

      //console.log("clientItem", clientItem);
    } else {
      setShowClientDetails(false);
      setSelectedScreener(null)
    }
  }, [window.location.search, clientList]);



  const postSlotsData = async () => {
    const payload = {
      user_id: userID,
      date: moment(clientData?.appointmentDate, "DD/MM/YYYY").format(
        "DD-MM-YYYY"
      ),
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      const { slots } = await postResponse.data;

      setSlotsList(slots);
    } catch (error) {
      console.error("Error:", error);
    }
  };



  //Fetch Clients
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
    };

    const postClientsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/clients`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { clients } = await postResponse.data;
        //const token = await postResponse.data;
        //const decodedToken = jwtDecode(token);

        setClientList(clients);
        // console.log("clients", clients)
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientsData();
  }, []);

  const handleRowClick = (
    name,
    id,
    image,
    country,
    notes,
    screener,
    sessions,
    type,
    worksheets
  ) => {
    const queryParam = `client=${name}&id=${id}`;
    const currentPath = window.location.pathname;
    const updatedPath = `${currentPath}?${queryParam}`;

    setClientDetails({
      name,
      id,
      image,
      country,
      notes,
      screener,
      sessions,
      type,
      worksheets,
    });

    setClient(name);
    setClientID(id);

    //console.log("updatedPath", updatedPath)
    navigate(updatedPath);

    setShowClientDetails(true);
  };

  const handleViewScreener = (title, id, type) => {
    //console.log("TOP", id)
    setSelectedScreener({ title, id, type });
  };

  const handletabSelect = (event) => {
    setSelectedTab(event.target.innerText);
  };

  //console.log("clientList", clientList);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  ///Reschedule Region

  //Reschedule
  const handleReschedule = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    clientID
  ) => {
    // Perform actions to open the reschedule dialog
    // You can access the appointmentID, appointmentDate, and slotID here

    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      clientID,
    }));

    //console.log("appointmentDate", appointmentDate)
    //postSlotsData();

    handleRescheduleOpen();
    //handleRescheduleOpen();
  };

  const handleRescheduleOpen = () => {
    setOpenRescheduleModal(true);
  };

  const handleRescheduleClose = () => {
    setOpenRescheduleModal(false);
  };

  const onToDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setClientData((prevClientData) => ({
      ...prevClientData,
      appointmentDate: formattedDate,
    }));
  };

  const handleRescheduleAppointment = async () => {
    const payload = {
      AppointmentId: clientData?.appointmentID,
      ExpertId: currentUser?.UserId,
      AppointmentDates: clientData?.appointmentDate,
      App_StartTime: clientData?.appointmentTime,
      App_EndTime: dayjs(clientData?.appointmentTime, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A"),
      CallMode: clientData?.callStatus,
      Status: clientData?.callingMode,
    };

    const rescheduleHeaders = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "ios",
      Lat: 0,
      Long: 0,
      Authorization: `Bearer ${bearerToken}`,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/reschedule`,
        {
          method: "POST",
          headers: rescheduleHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse.ResponseCode;

      if (reponseCode == 401) {
        await getToken();
        handleRescheduleAppointment();
      }
      if (reponseCode == 200) {
        setSnackbarMessage("Resheduled successfully!");
        setOpenSnackbar(true);
        handleRescheduleClose();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Slots Section
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSlotChange = (slot) => {
    //console.log("SLOT", slot);
    setClientData((prevClientData) => ({
      ...prevClientData,
      slotID: slot.id,
    }));
  };

  ///Reschedule Region End

  ///Notes Region

  const handleNotes = (
    clientName,
    clientImage,
    clientType,
    clientID
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      clientID,
    }));
    handleNotesOpen();
  };

  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const handlePostNote = async () => {
    setIsNoteAdded(false)
    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: clientData?.clientID,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  ///Notes Region End

  ///Prescription Region

  const handlePrescription = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handlePrescriptionOpen();
  };

  const handlePrescriptionOpen = () => {
    setOpenPrescriptionModal(true);
  };

  const handlePrescriptionClose = () => {
    setOpenPrescriptionModal(false);
  };

  const handlePrescriptionName = (e) => {
    setPrescriptionName(e.target.value);
  };

  const handlePrescriptionNote = (e) => {
    setPrescriptionNote(e.target.value);
  };

  const handlePostPrescription = async () => {
    setIsPrescriptionAdded(false)
    const payload = {
      AppointmentId: +clientData?.appointmentID,
      Title: prescriptionName,
      Note: prescriptionNote,
      DocsFile: selectedFiles,
    };

    const prescriptionHeaders = {
      "Content-Type": "application/json",
      deviceid: "123",
      devicetype: "web",
      lat: "1",
      long: "1",
      Authorization: `Bearer ${bearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/saveAppointment_Documents_Files`,
        {
          method: "POST",
          headers: prescriptionHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (postResponse?.ResponseCode == 401) {
        getToken();
        handlePostPrescription();
      }

      if (message == 200) {
        setPrescriptionName("");
        setPrescriptionNote("");
        setSelectedFiles("");
        handlePrescriptionClose();
        setSnackbarMessage("Prescription sent successfully!");
        setOpenSnackbar(true);
        setIsPrescriptionAdded(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    //console.log("file", file);
    setIsFileLoading(true)
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg"
    ) {
      let dirName = moment().format("MMMM_YYYY");
      //console.log("dirName", dirName);
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          // setPercent(percent);
        },
        (err) => alert(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // console.log("file path url ", url);
            //setLoading(false);
            setSelectedFiles(url);
            setIsFileLoading(false)
          });
        }
      );
    } else {
      console.error("Incorrect  file format.");
    }

    //setSelectedFiles(files);
  };

  ///Prescription Region End

  ///Cancel Region

  const handleCancelConfirmationOpen = () => {
    setOpenCancelConfirmationModal(true);
  };

  const handleCancelConfirmationClose = () => {
    setOpenCancelConfirmationModal(false);
  };

  const handleCancelAppointment = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handleCancelConfirmationOpen();
  };

  const handleCancel = async () => {
    setIsCancelled(false)
    const payload = {
      AppointmentId: clientData?.appointmentID,
    };

    const cancelHeaders = {
      //"X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "ios",
      Lat: 0,
      Long: 0,
      Authorization: `Bearer ${bearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/CancelAppointment`,
        {
          method: "POST",
          headers: cancelHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 401) {
        getToken();
        handleCancel();
      }

      if (message == 200) {
        handleCancelConfirmationClose();
        setSnackbarMessage("Cancelled successfully!");
        setOpenSnackbar(true);
        setIsCancelled(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  ///Cancel Region End

  ///Add Appointment Region

  ///Add Appointment Region End

  const filteredClients = clientList
    .filter((item) => {
      if (selectedTab === "All Clients") {
        return true;
      } else if (selectedTab === "New Clients") {
        return item.client_type == "New Client";
      } else if (selectedTab === "Follow-up") {
        return item.client_type == "Follow-up";
      }
    }
    )

  return (
    <>
      <div className="my-3 ClientDashboard">
        <div className="ClientDashboard--Header d-flex">
          <div className="d-flex Header--Left">
            {selectedScreener == null ? (
              showClientDetails ? (
                <h2>
                  Clients {">"} {client}
                </h2>
              ) : (
                <h2>Clients</h2>
              )
            ) : showClientDetails ? (
              <h2>
                Clients {">"} {client} {">"} Screener {">"} {selectedScreener.title}
              </h2>
            ) : (
              <h2>Clients</h2>
            )}

            {/* Enable Later */}
            {!showClientDetails && (
              <div style={{ maxWidth: "200px" }}>
                <AddAppointmentModal />
              </div>
            )}
          </div>
          {!showClientDetails && (
            <div className="d-flex Header--Right mt-3">
              <div className="d-flex gap-3 Options">
                <div
                  className={`Option--Item ${selectedTab == "All Clients" ? "SelectedItem" : ""
                    }`}
                  onClick={handletabSelect}
                >
                  All Clients
                </div>
                <div
                  className={`Option--Item ${selectedTab == "New Clients" ? "SelectedItem" : ""
                    }`}
                  onClick={handletabSelect}
                >
                  New Clients
                </div>
                <div
                  className={`Option--Item ${selectedTab == "Follow-up" ? "SelectedItem" : ""
                    }`}
                  onClick={handletabSelect}
                >
                  Follow-up
                </div>
                {/* <div className="Option--Item">Clients by filters</div> */}
              </div>
            </div>
          )}
        </div>

        {selectedScreener == null ? (
          showClientDetails ? (
            <ClientDetailsView
              clientDetails={clientDetails}
              clientID={clientID}
              clientName={client}
              onScreenerView={handleViewScreener}
            />
          ) : (
            <TableContainer sx={{ marginTop: "20px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Sessions</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Worksheets</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClients.length == 0 ? (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell><p>No Data Available</p></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) :
                    filteredClients.map((item) => {
                      //console.log("item", item)
                      return (
                        <ClientDetailsTableRow
                          key={item.ID}
                          id={item.ID}
                          image={item.ProfileImage}
                          name={item.Name}
                          notes={item.notes}
                          screener={item.screner}
                          sessions={item.appointments_cnt}
                          type={item.client_type}
                          worksheets={item.worksheets}
                          country={item.Country}
                          onClickCallback={handleRowClick}
                          onAddNote={() =>
                            handleNotes(
                              item.Name,
                              item.ProfileImage,
                              item.client_type,
                              item.ID,
                              item.AppDate,
                              item.AppTime,
                              item.UserId,
                              item.Status
                            )
                          }
                          onPrescription={() =>
                            handlePrescription(
                              item.Name,
                              item.ProfileImage,
                              item.client_type,
                              item.ID,
                              item.AppDate,
                              item.AppTime
                            )
                          }
                          onCancel={() =>
                            handleCancelAppointment(
                              item.Name,
                              item.ProfileImage,
                              item.client_type,
                              item.ID,
                              item.AppDate,
                              item.AppTime
                            )
                          }
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <ScreenerDetailsView
            clientDetails={clientDetails}
            clientID={clientID}
            clientName={client}
            type={selectedScreener.type}
            title={selectedScreener.title}
            screenerID={selectedScreener.id} />
        )}
      </div>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "98%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {
          (isNoteAdded !== null && !isNoteAdded) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        }
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {/* {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "} */}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostNote}
            disabled={note == "" ? true : false}
          >
            Add Note
          </Button>
        </DialogContent>
      </Dialog>

      {/* Reschedule Dialog */}
      {/* <Dialog
        className="Dialog Reschedule--Dialog"
        open={openRescheduleModal}
        onClose={handleRescheduleClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle>Reschedule Appointment </DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <div>
            <h4>Reschedule to</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To date"
                format="DD/MM/YYYY"
                value={dayjs(clientData?.appointmentDate, "DD/MM/YYYY")}
                onChange={onToDateChange}
                sx={{
                  width: "100%",
                  marginBottom: "8px",
                  backgroundColor: "#FFF",
                }}
              />
            </LocalizationProvider>
          </div>

          <div>
            <div className="d-flex gap-1 SlotTiming">
              <p className={`${!checked ? "SelectedSlot" : ""}`}>
                Morning Slots
              </p>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p className={`${checked ? "SelectedSlot" : ""}`}>
                Evening Slots
              </p>
            </div>

            <Grid
              container
              gap={2}
              sx={{ width: "100%", margin: "0" }}
              className="Timings"
            >
              {slotsList
                ?.filter((item) => {
                  if (!checked) {
                    // Render morning slots (AM)
                    return item.slot.split(" - ")[0].includes("AM");
                  } else {
                    // Render evening slots (PM)
                    return item.slot.split(" - ")[0].includes("PM");
                  }
                })
                .map((item) =>
                  item.availability ? (
                    <Grid
                      item
                      xs={2}
                      key={item.slot}
                      className={`AvailableSlot ${
                        item.id == clientData?.slotID ? "SelectedSlot" : ""
                      }`}
                      onClick={() => handleSlotChange(item)}
                    >
                      <p>{item.slot.split(" - ")[0]}</p>
                    </Grid>
                  ) : (
                    <Grid item xs={2} key={item.slot}>
                      <p>{item.slot.split(" - ")[0]}</p>
                    </Grid>
                  )
                )}
            </Grid>
          </div>

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "62px auto 20px auto" }}
            onClick={handleRescheduleAppointment}
            disabled={clientData.slotID === 0 ? true : false}
          >
            Confirm and Reschedule
          </Button>
        </DialogContent>
      </Dialog> */}

      {/* Precriptions Dialog */}
      <Dialog
        className="Dialog Prescription--Dialog"
        open={openPrescriptionModal}
        onClose={handlePrescriptionClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >

        {
          (isPrescriptionAdded !== null && !isPrescriptionAdded) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        }
        <DialogTitle>Add Prescription</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {/* {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "} */}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Prescription Name"
            value={prescriptionName}
            onChange={handlePrescriptionName}
            fullWidth
            sx={{ marginTop: "20px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Prescription notes"
            value={prescriptionNote}
            onChange={handlePrescriptionNote}
            multiline
            rows={4}
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <div
            className="upload-button"
            style={{ marginTop: "0", height: "100%" }}
          >
            <label
              htmlFor="file-upload"
              className="upload-button-label"
              style={{ height: "100%", fontSize: "16px" }}
            >
              <FileUploadOutlinedIcon className="upload-button-icon" />
              Upload
            </label>
            {
              isFileLoading !== null && isFileLoading ? (
                <div className="d-flex align-items-center mx-auto">
                  <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} />
                </div>

              ) : (
                <>
                  <input
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="upload-button-input"
                  />
                  {selectedFiles && (
                    <div className="selected-files">
                      <div className="file-name">{selectedFiles}</div>
                    </div>
                  )}
                </>
              )
            }
          </div>

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostPrescription}
            disabled={(prescriptionName == "" || selectedFiles === "") ? true : false}
          >
            Add Prescription
          </Button>
        </DialogContent>
      </Dialog>


      {/* Add Appointment Dialog */}
      {/* <Dialog
        className="AddApointment--Dialog"
        open={open}
        onClose={handleClose}
        sx={{
          maxWidth: "610px",
          width: "100%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle>Add Appointment</DialogTitle>
        <DialogContent className="Dialog--Content mt-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Pick a date"
              format="DD/MM/YYYY"
              value={dayjs(clientData?.appointmentDate, "DD/MM/YYYY")}
              onChange={onToDateChange}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>

          <div className="d-flex gap-1 SlotTiming">
            <p className={`${!checked ? "SelectedSlot" : ""}`}>Morning Slots</p>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p className={`${checked ? "SelectedSlot" : ""}`}>Evening Slots</p>
          </div>

          <Grid
            container
            gap={2}
            sx={{ width: "100%", margin: "0" }}
            className="Timings"
          >
            {slotsList
              ?.filter((item) => {
                if (!checked) {
                  // Render morning slots (AM)
                  return item.slot.split(" - ")[0].includes("AM");
                } else {
                  // Render evening slots (PM)
                  return item.slot.split(" - ")[0].includes("PM");
                }
              })
              .map((item) =>
                item.availability ? (
                  <Grid
                    item
                    xs={2}
                    key={item.slot}
                    className={`AvailableSlot ${
                      item.id == clientData?.slotID ? "SelectedSlot" : ""
                    }`}
                    onClick={() => handleSlotChange(item)}
                  >
                    <p>{item.slot.split(" - ")[0]}</p>
                  </Grid>
                ) : (
                  <Grid item xs={2} key={item.slot}>
                    <p>{item.slot.split(" - ")[0]}</p>
                  </Grid>
                )
              )}
          </Grid>

          <div className="SessionDuration--Container">
            <h6>Session Duration</h6>
            <Grid
              container
              gap={2}
              sx={{ width: "100%", margin: "0" }}
              className="SessionTimings"
            >
              

              <Grid
                item
                xs={3}
                className="SelectedSTiming"
                //onClick={() => handleSlotTimingChange(30)}
              >
                <p>30 minutes</p>
              </Grid>
            </Grid>
          </div>

          <FormControl fullWidth className="mt-3 ClientSelect--Form">
            <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={client}
              onChange={handleClientSelect}
            >
              {clientList?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  <div
                    id={item.id}
                    className="d-flex gap-2 ClientSelect--MenuItem"
                  >
                    <img
                      src={item.image}
                      width={45}
                      height={45}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                    <div>
                      <h6>{item.name}</h6>
                      <p>{item.country}</p>
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="ConfirmBooking"
            sx={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={handleClose}
            variant="contained"
            disableElevation
          >
            Confirm and Book Appointment
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExpertClientDB;
