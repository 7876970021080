import React from "react";

const CourseTabItem = ({ img, title, status }) => {
  return (
    <div className="Course--Item">
      <img
        src={img}
        width={122}
        height={69}
        loading="lazy"
      />

      <div>
        <h6>{title}</h6>
        <span className={status.toLowerCase()}>{status}</span>

      </div>
    </div>
  );
};

export default CourseTabItem;
